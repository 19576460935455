.modal {
    $modal_close_size_xs: 40px;
    $modal_close_size_sm: 70px;

    will-change: transform;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 100vw;
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(black, 0.6);
    }

    &__inner {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        flex-grow: 0;
        max-height: 100%;
        margin: auto 0;
        overflow: hidden;

        .modal--aside & {
            max-width: 690px;
            margin-left: auto;
        }
    }

    &__content {
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: none;
        margin: auto 0;

        .modal--aside & {
            background: white;
        }
    }

    &--alert {
        .modal {
            &__inner {
                height: auto;
                width: calc(100% - 40px);
            }
        }
    }

    @media (max-width: $breakpoint_sm) {
        &__inner {
            max-width: none !important;
        }
    }

    @include breakpoint($breakpoint_desktop_nav) {
        &.modal--internal-scroller {
            .modal__content {
                height: 100%;
            }
        }

        .modal__close {
            position: absolute;
            width: $modal_close_size_sm;
            height: $modal_close_size_sm;
        }
    }
}

// modal slide in animation

[class^="modal-slide-in"],
[class*=" modal-slide-in"] {
    // underlay
    &:before {
        @extend .animated;
        animation-duration: 500ms;
        transform: translate3d(0, 0, 0);
    }

    // modal content
    .modal__inner {
        @extend .animated;
        animation-duration: 500ms;
        transform: translate3d(0, 0, 0);
    }
}

.modal-slide-in-enter-active {
    &:before {
        @extend .fadeIn;
    }

    .modal__inner {
        @extend .slideInRight;
    }
}

.modal-slide-in-exit-active {
    &:before {
        @extend .fadeOut;
    }

    .modal__inner {
        @extend .slideOutRight;
    }
}
