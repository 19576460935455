.react-form__time-picker {
    .react-form__field--select {
        flex: 1 1 auto;
    }

    &__select-row {
        display: flex;
        flex-wrap: nowrap;
        flex: 1 1 100%;

        & > * {
            margin: 0 0 0 10px !important;

            &:first-child {
                margin-left: 0 !important;
            }
        }
    }

    &__separator {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
    }
}
