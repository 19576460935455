.reveal {
    display: flex;
    flex-flow: column;

    &__collapsible-container {
        position: relative;
        overflow: hidden;
        -webkit-mask-image: linear-gradient(
            to bottom,
            black 75%,
            transparent 100%
        );
    }
    &__collapsible-content {
        position: relative;
        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__button {
        span:first-child {
            display: none;
        }
        span:last-child {
            display: inline;
        }
    }

    &.state-open & {
        &__collapsible-content {
            height: auto;
        }

        &__collapsible-container {
            -webkit-mask-image: linear-gradient(
                to bottom,
                black 100%,
                transparent 100%
            );
        }
        &__button {
            span:first-child {
                display: inline;
            }
            span:last-child {
                display: none;
            }
        }
    }

    &--disabled & {
        &__button {
            display: none;
        }
        &__collapsible-container {
            -webkit-mask-image: none;
        }
        &__collapsible-content {
            height: auto;
        }
    }
}
