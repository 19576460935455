.icon {
    display: inline-block;
}

.tenant-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 100%;
    border: 2px solid white;
    height: 1.1em;
    width: 1.1em;

    &:before {
        font-size: 0.5em;
        line-height: 1em;
    }

    &.icon-stay {
        background: var(--colors_tenant_stay);

        &:before {
            font-size: 0.4em;
        }
    }

    &.icon-eat-and-drink {
        background: var(--colors_tenant_eat_and_drink);
    }

    &.icon-shopping {
        &:before {
            transform: translateY(-1px);
        }
        background: var(--colors_tenant_shopping);
    }

    &.icon-market {
        background: var(--colors_tenant_market);
    }

    &.icon-things-to-do {
        background: var(--colors_tenant_things_to_do);
        &:before {
            position: relative;
            top: -0.1em;
            font-size: 0.45em;
        }
    }
}
