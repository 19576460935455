.hero-image {
    position: relative;
    @include aspect-ratio(1630, 877);
    img {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;
    }

    &__video {
        @include youtube();

        video {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
        }
    }
}
