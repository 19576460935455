$carousel_button_height: 60px;
$carousel_button_height_mobile: 50px;

.carousel {
    &__slide {
        @include aspect-ratio(3, 2);
        background-size: cover;
        background-position: center center;
        background-color: grey;
        cursor: pointer;
        transition: background-position 250ms linear;

        .lightbox & {
            max-height: calc(100vh - 170px);
            background-size: contain;
            background-position: center;
            background-color: transparent;
            background-repeat: no-repeat;
            &:after,
            .youtube-player__poster:after {
                background: none;
            }
            .youtube-player__poster {
                background-size: contain;
                background-position: center;
                background-color: transparent;
                background-repeat: no-repeat;
                box-shadow: none;
            }

            // &.youtube-player .youtube-player__iframe {
            //     padding-bottom: 60px;
            // }
        }

        .lightbox &.glide__slide {
            //opacity: 0;
            background-position: center;
        }

        .lightbox &.glide__slide--active {
            background-position: center;
            opacity: 1;
        }

        &:after,
        .youtube-player__poster:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(black, 0.5);
            transition: 300ms opacity;
        }
    }

    &__footer {
        display: flex;
        flex-direction: row;

        &__inner {
            position: relative;
            flex: 1 1 auto;
        }
    }

    &__gutter {
        // this elements width responsively sets the amount of "peek" or preview of the next slide
        flex: 0 0 $layout_page_padding;

        .lightbox & {
            display: none;
        }

        @include breakpoint(sm) {
            flex: 0 0 10%;
        }

        @include breakpoint(md) {
            flex: 0 0 15%;
        }

        @include breakpoint(lg) {
            flex: 0 0 25%;
        }
    }

    &__controls {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: flex-end;
        margin-top: -$carousel_button_height_mobile / 2;
        @include breakpoint(md) {
            margin-top: -$carousel_button_height;
        }
    }
    &__expand.icon {
        position: absolute;
        bottom: -48px;
        right: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        width: 38px;
        border-radius: 100%;
        font-size: rem(13px);
        border: none;
        background: rgba(black, 0.65);
        color: var(--colors_highlight);
        z-index: 1;
        transition: all 100ms;
        cursor: cursor;

        @include theme(dh) {
            color: white;
        }

        @include theme(br) {
            color: white;
        }

        @include theme(wb) {
            color: white;
        }

        &:before {
            transition: all 100ms;
        }
        &:hover:before {
            transform: scale(1.1);
        }
        .lightbox & {
            display: none;
        }
        @include breakpoint(md) {
            height: 50px;
            width: 50px;
            bottom: -70px;
            right: 20px;
            font-size: rem(18px);
        }
    }
    &--single &__button,
    &--single &__info__pagination {
        opacity: 0;
        pointer-events: none;
    }

    &__button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: $carousel_button_height_mobile;
        width: $carousel_button_height_mobile;
        font-size: rem(18px);
        border: none;
        background: black;
        color: var(--colors_highlight);
        border-left: 1px solid rgba(white, 0.3);

        @include theme(dh) {
            color: white;
            background: var(--colors_highlight);
        }

        @include theme(wb) {
            color: white;
        }

        @include breakpoint(md) {
            font-size: rem(24px);
            height: $carousel_button_height;
            width: $carousel_button_height;
        }

        .lightbox & {
            transform: translateY(calc(100% + 7px));
            background: rgba(black, 0.65);

            @include theme(br) {
                color: white;
                background: var(--colors_highlight);
            }

            @include theme(dh) {
                color: white;
                background: var(--colors_highlight);
            }
        }

        &:first-child {
            border-left: 0;
        }

        &:before {
            transition: all 100ms;
        }

        &:first-child:hover:before,
        &[data-scroller-prev]:hover:before {
            margin-left: -5px;
        }

        &:last-child:hover:before,
        &[data-scroller-next]:hover:before {
            margin-right: -5px;
        }
    }

    &__info {
        display: flex;
        margin: 0 0 10px 0;
        flex-direction: column;

        @include breakpoint(sm) {
            margin: 10px 0;
            flex-direction: row;
        }

        .lightbox & {
            color: white;
            padding-right: $carousel_button_height * 2 + $layout_page_padding;
        }

        @include theme(dh) {
            .content-spotlight & {
                color: white;
            }
        }

        @include theme(br) {
            letter-spacing: -0.02em;
        }

        &__pagination {
            flex: 0 0 auto;
            min-width: 100px;
            padding: 0.1em $layout_page_padding 0 0;
            font-size: rem(12px);
            letter-spacing: 0.1416em;
            text-transform: uppercase;

            @include theme(dh) {
                text-transform: uppercase;
                font-family: var(--font_family_heading_medium);
                letter-spacing: 0.16666666667em;
                color: var(--colors_heading_black);
                opacity: 0.6;
            }

            @include theme(br) {
                text-transform: initial;
                letter-spacing: -0.02em;
                font-family: var(--font_family_heading_semibold);
            }

            .lightbox &,
            .content-spotlight & {
                @include theme(dh) {
                    color: white;
                }
            }
        }

        &__captions {
            position: relative;
            flex: 1 1 auto;

            @include theme(dh) {
                font-size: rem(14px);
                line-height: rem(20px);
                color: var(--colors_heading_black);
                padding-top: 0.25em;
            }

            .lightbox &,
            .content-spotlight & {
                @include theme(dh) {
                    color: white;
                }
            }

            @include breakpoint(xs, only) {
                font-size: rem(14px);
                line-height: rem(22px);
            }
        }
    }

    &__caption {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        animation-fill-mode: both;
        animation-duration: 500ms;
        animation-name: fadeOut;
        order: 2;

        &.is-active {
            position: relative;
            animation-delay: 600ms;
            animation-name: fadeIn;
            order: 1;
        }
    }

    .lightbox &__caption {
        max-height: 300px;
        overflow: auto;
    }

    // GlideJS overrides

    .glide__slides {
        overflow: visible;
        .lightbox & {
            transition: none !important;
        }
    }

    &__slide.glide__slide--active {
        &:after,
        & .youtube-player__poster:after {
            opacity: 0;
        }
    }
}
