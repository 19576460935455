*,
*:after,
*:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
    box-sizing: border-box;
    //outline: none !important;
}

*:focus {
    // @extend .outline;
}

body,
html {
    margin: 0;
    padding: 0;
}

body {
    -webkit-overflow-scrolling: touch;
}

body.prevent-scrolling {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    overflow: hidden;
}

a,
img {
    border: none;
}

[data-button-link] {
    display: inline;
    cursor: pointer;
    text-decoration: underline;
}

[data-animate-height] {
    //overflow-y: hidden;
    //overflow-x: visible;
    transition: height 1000ms;
}

input,
select,
select:focus,
textarea,
button {
    border-radius: 0;
    appearance: none;
}

button {
    appearance: none;
    padding: 0;
    border: 0;
    background: none;
}

.outline {
    outline-color: rgb(77, 144, 254); // #4D90FE
    outline-offset: -2px;
    outline-style: auto;
    outline-width: 5px;
}

.underline {
    display: inline;
    // padding-top: 0.15em;
    padding-bottom: 0.25em;
    box-shadow: inset 0 -0.2em 0 var(--colors_highlight);
    transition: all 0.3s;
    overflow: hidden;

    @include theme(wb) {
        box-shadow: inset 0 -0.2em 0 var(--colors_body_black);
    }

    a:hover &,
    .tile__poster[href]:hover + .tile__content &,
    &:hover {
        box-shadow: inset 0 -2.5em 0 var(--colors_highlight);
        color: $colors_grey_dark;

        @include theme(dh) {
            color: white;
        }

        @include theme(br) {
            box-shadow: inset 0 -7px 0 var(--colors_highlight);
        }

        @include theme(wb) {
            box-shadow: inset 0 -7px 0 var(--colors_rust);
        }
    }
}

.visually-hidden {
    appearance: none;
    position: absolute !important;
    display: block;
    visibility: visible;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
    white-space: nowrap;
    pointer-events: none;
}

.scroll-lock {
    overflow: hidden !important;
    height: 100% !important;
}

.hero {
    min-height: 100vh;

    @include breakpoint($breakpoint_desktop_nav) {
        margin-bottom: $layout_page_padding;
        padding: $main_hero_inset $main_hero_inset 0;
    }

    &.main-hero {
        .container-fluid {
            @include breakpoint($breakpoint_desktop_nav) {
                padding-left: $layout_page_padding +
                    $layout_nav_desktop_width_compact - $main_hero_inset;
            }
        }
    }

    .container-fluid {
        @include breakpoint($breakpoint_desktop_nav) {
            padding-right: $main_hero_inset;
            padding-left: $layout_page_padding + $layout_nav_desktop_width -
                $main_hero_inset;
        }
    }

    &__container {
        display: flex;
        flex-direction: row;
        min-height: 100%;
    }

    &__inner {
        flex: 1 1 auto;
    }

    &__main {
        position: relative;

        @include breakpoint($breakpoint_desktop_nav) {
            display: flex;
            flex-direction: row;
            min-height: calc(100vh - #{$main_hero_inset * 2});
        }

        & > * {
            flex: 1 1 auto;
        }

        &__image {
            position: relative;
            z-index: -1;
            @include aspect-ratio(16, 9);
            background-size: cover;
            background-position: center center;

            &__img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include breakpoint($breakpoint_desktop_nav) {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            &--overlay {
                // @include insetOverlay(0.6, black);

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    @include insetOverlay(0.6, black);
                }
            }
        }
    }
}

.react-form__field__input-container {
    display: flex;
    flex: 0 0 2em;
    margin-right: 1em;
}

[data-inline-youtube-play-toggle],
[data-inline-video-play-toggle] {
    @extend .icon;
    @extend .icon-pause;
    position: absolute;
    z-index: 5;
    top: $main_hero_inset;
    right: $main_hero_inset;
    width: 50px;
    height: 50px;
    color: white;
    border: 2px solid white;
    border-radius: 100%;
    background: rgba(black, 0.5);
    cursor: pointer;

    [data-inline-youtube]:not(.has-started) &,
    [data-inline-video]:not(.has-started) & {
        @extend .icon-play;
        &:before {
            margin-left: 3px;
        }
    }

    .has-started & {
        opacity: 1;
    }

    .is-paused & {
        @extend .icon-play;
        &:before {
            margin-left: 3px;
        }
    }
}
