.image-row {
    margin: 50px 0;

    @include breakpoint($breakpoint_desktop_nav) {
        margin: 80px 0;
    }

    &:not(.image-row--is-list) .tile__content {
        padding: 25px 0;
    }

    &--is-list .image-row__tile-container {
        .tile {
            margin-bottom: 60px;

            .tile__title {
                font-size: rem(20px);
                line-height: rem(30px);

                @include theme(dh) {
                    font-size: rem(20px);
                    line-height: rem(26px);
                }
            }
            .tile__description {
                font-size: rem(16px);
                line-height: rem(26px);

                @include theme(dh) {
                    font-size: rem(16px);
                    line-height: rem(24px);
                }
            }
            .tile__readmore {
                font-size: rem(14px);
                line-height: rem(17px);
            }

            @include breakpoint(sm) {
                margin-bottom: 70px;
                .tile__title {
                    font-size: rem(28px);
                    line-height: rem(38px);

                    @include theme(dh) {
                        font-size: rem(24px);
                        line-height: rem(30px);
                    }
                }
                .tile__description {
                    font-size: rem(18px);
                    line-height: rem(30px);

                    @include theme(dh) {
                        font-size: rem(16px);
                        line-height: rem(24px);
                    }
                }
                .tile__readmore {
                    font-size: rem(14px);
                    line-height: rem(17px);
                }
            }
        }

        @include breakpoint(sm) {
            > div {
                position: relative;
                &:after {
                    position: absolute;
                    content: "";
                    background-color: #d6d6d6;
                    width: 100%;
                    height: 1px;
                    bottom: -35px;
                    left: 0;
                }
                &:first-child:before {
                    position: absolute;
                    content: "";
                    background-color: #d6d6d6;
                    width: 100%;
                    height: 1px;
                    top: -35px;
                    left: 0;
                }
            }
        }
    }
    @include breakpoint(sm) {
        &--is-list {
            .tile {
                .tile__poster {
                    width: 40%;
                }
                .tile__content {
                    padding-left: 80px;
                    width: 60%;
                }
            }
        }
    }
}
