@mixin facet($tone, $opacity: 0.4, $width: 488px, $height: auto) {
    &:after {
        content: "";
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("/assets/media/facet_pattern_#{$tone}.svg");
        background-size: $width $height;
        opacity: $opacity;
        pointer-events: none;
    }

    & > * {
        position: relative;
        z-index: 1;
    }
}
