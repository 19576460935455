.partner-logos {
    margin: 50px 0;
    &__title {
        margin: 0 0 25px;
    }

    &__description {
        margin: 0 0 25px;
    }

    &__divider {
        margin: 0 30px 60px 0;
        border: none;
        border-bottom: 1px solid #d6d6d6;
    }

    &__partners {
        display: flex;
        flex-wrap: wrap;
        margin: -25px 0;
    }

    &__partner {
        position: relative;
        margin-right: 25px;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: calc(50% - 25px);

        &__link {
            display: block;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            color: transparent;
            font-size: 0;
            line-height: 0;
            width: 100%;
            max-width: 200px;
            max-height: 90px;
            &:after {
                content: "";
                display: block;
                padding-bottom: 90 / 200 * 100%;
            }
        }
    }

    @include breakpoint($breakpoint_desktop_nav) {
        margin: 80px 0 40px;
        + .partner-logos {
            margin-top: 40px;
        }
        &__partners {
            margin: -40px 0;
            &--3 {
                .partner-logos__partner:nth-child(3n) {
                    margin-right: 25%;
                }
            }
            &--2 {
                .partner-logos__partner:nth-child(2n) {
                    margin-right: 50%;
                }
            }
        }
        &__partner {
            padding: 20px;
            margin-right: 40px;
            margin-bottom: 40px;
            flex-basis: calc(25% - 40px);
        }
    }
}
