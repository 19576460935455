$content_spotlight_teal: #0097b6;

.content-spotlight {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: $colors_grey_dark;
    color: white;

    @include theme(dh) {
        background: #671980;
        @include facet("dark", 0.7, 100%);

        .tile__title {
            font-family: var(--font_family_heading_extrabold);
            font-size: rem(14px);
            line-height: rem(20px);
            letter-spacing: -0.01666666667em;
            text-transform: uppercase;

            @include breakpoint(md) {
                font-size: rem(30px);
                line-height: rem(34px);
            }
        }

        .tile__time-description {
            color: rgba(white, 0.7);
        }

        a:hover .underline {
            color: white;
        }
    }

    @include theme(br) {
        background: var(--colors_rust);

        .tile__title {
            color: black;
        }

        @include breakpoint(sm, down) {
            .tile__title {
                font-size: rem(14px);
                line-height: 20px;
                letter-spacing: -0.02em;
            }
        }
    }

    @include theme(wb) {
        a:hover .underline {
            color: white;
        }
    }

    .glide__track {
        flex-grow: 1;
    }

    .carousel__controls {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .carousel__footer {
        position: relative;
        z-index: 5;
        margin-top: -50px;
        padding-left: 140px;
    }

    .carousel__button {
        font-weight: bold;
        border: none;
        background: $colors_black_med;
        margin-left: 1px;

        &:first-child {
            margin-left: 0;
        }

        @include theme(br) {
            background-color: var(--colors_highlight);
            &:before {
                color: white;
            }
        }

    }

    .carousel__info__pagination {
        padding-left: 0;

        @include theme(br) {
            color: black;
        }
    }

    .tile__content,
    .tile--big .tile__content {
        padding-left: 0;
        padding-right: 0;
        background: none;
        color: white;

        .rich-text {
            color: inherit;
        }
    }

    .tile__date,
    .tile--big .tile__date {
        margin-left: 0;
    }

    .date-tile {
        background: $content_spotlight_teal;
    }

    .tile__time-description {
        color: $content_spotlight_teal;
    }

    .tile__logo {
        margin-right: 10px;
    }

    .tile__title {
        color: white;
    }

    .tile__author__link {
        color: white;
    }

    &__slide {
        padding: $layout_page_padding;
        color: white;
        text-decoration: none;
        white-space: normal;
    }

    @include breakpoint(sm, down) {
        .tile__description,
        .tile__time-description,
        .tile__readmore,
        .tile__logo {
            display: none;
        }

        .tile {
            display: flex;
            align-items: flex-start;
        }

        .tile__poster {
            flex: 0 0 100px;
            @include aspect-ratio(1, 1);
        }

        .tile__content,
        .tile--big .tile__content {
            flex: 1 1 auto;
            padding: 0 0 30px $layout_page_padding;
            @include rem(font-size, 14px);
        }

        .tile__author {
            margin-bottom: 0;
        }

        .tile__date {
            margin: 0 0 5px 0;
            letter-spacing: 0.2em;

            .date-tile {
                display: block;
                text-align: left;
                height: auto;
                min-width: 0;
                background: none;
                color: $content_spotlight_teal;

                @include theme(dh) {
                    color: white;
                }

                @include theme(br) {
                    color: black;
                }

                * {
                    display: inline-block;
                }
            }

            .dash {
                position: relative;
                top: 0.2em;
                margin: 0;
                border-width: 1px;
                padding-top: 0;
                margin-right: 5px;
            }

            .date-tile__date,
            .date-tile__all-year,
            .date-tile__month,
            .date-tile__day {
                width: auto;
                margin: 0;
                font-weight: bold;
                @include rem(font-size, 10px);

                br {
                    display: none;
                }
            }
        }

        .carousel__button {
            width: 35px;
            height: 35px;
            @include rem(font-size, 16px);
        }
    }

    @include breakpoint($breakpoint_desktop_nav) {
        .content-spotlight__slide {
            padding: 40px;
        }

        .tile__content {
            padding-left: 0;
            padding-right: 0;
        }

        .carousel__footer {
            padding-left: 40px;
        }

        .carousel__controls {
            margin-top: 0;
        }

        .carousel__info__pagination {
            display: flex;
            align-items: center;
        }
    }
}
