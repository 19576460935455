// Arrow shape mixin
@mixin arrow($direction: down, $size: 5px, $color: #555) {
    width: 0;
    height: 0;
    @if ($direction == left) {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
    } @else if ($direction == right) {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
    } @else if ($direction == down) {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
    } @else {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
    }
}
