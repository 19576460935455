.newsletter-form {
    position: relative;
    display: flex;
    flex-direction: column;

    &__heading {
        color: white;
        margin: 0;

        @include theme(dh) {
            text-transform: uppercase;
            font-weight: bold;
            font-size: rem(23px);
            letter-spacing: 0;
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
        }

        @include theme(wb) {
            font-size: rem(20px);
            letter-spacing: -0.385px;
        }
    }

    .react-form__form {
        display: flex;
        flex-direction: column;
    }

    .react-form__success-message {
        font-family: var(--font_family_heading);
        display: flex;
        align-items: center;
        margin-top: -10px;
        min-height: 60px;
        font-size: rem(18px);
        line-height: rem(26px);

        @include theme(dh) {
            align-items: flex-start;
            margin-top: 20px;
        }

        @include theme(wb) {
            font-family: var(--font_family_main);

            .rich-text {
                color: #fff;
            }
        }

        .icon {
            margin-right: 15px;
            color: var(--colors_highlight);
            font-size: rem(28px);
        }
    }

    input {
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        color: white;
        font-size: rem(15px);
        line-height: rem(48px);
        height: 48px;
        outline: none;
        font-family: var(--font_family_heading);
        background-color: transparent;
        padding: 0;

        @include theme(br) {
            font-family: var(--font_family_heading_regular);
        }

        @include theme(wb) {
            font-family: var(--font_family_main);
        }
    }

    .react-form__field {
        margin: 0;
        padding-top: 12px;

        &.has-value {
            .react-form__field__label {
                top: -6px;
                font-size: rem(12px);
                opacity: 1;
            }
        }

        &.is-valid {
            label {
                border-color: transparent;
                color: white;
            }
        }

        &.hidden {
            flex-basis: unset;
        }

        .react-form__field__label {
            position: absolute;
            top: 11px;
            height: auto;
            font-family: var(--font_family_heading);
            font-size: rem(15px);
            transition: all 100ms linear;
            opacity: 0.6;

            @include theme(br) {
                font-family: var(--font_family_heading_regular);

                &.has-value {
                    opacity: 1;
                }
            }

            @include theme(wb) {
                font-family: var(--font_family_main);
            }
        }

        .react-form__field__errors {
            display: none;
        }
    }

    .react-form__button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $colors_grey_dark;
        background-color: $colors_grey_light;
        letter-spacing: 3px;
        text-decoration: none;
        text-transform: uppercase;
        padding: 0 30px;
        border-radius: 30px;
        font: 700 #{rem(12px)} var(--font_family_main);
        height: 60px;
        border: none;
        margin-right: 0;
        margin-top: 25px;

        @include theme(dh) {
            border-radius: 3px;
            background-color: white;
            font-family: var(--font_family_heading_bold);
            font-size: rem(14px);
            transition: background-color 0.3s ease, color 0.3s ease;
            cursor: pointer;

            &:hover {
                background-color: var(--colors_teal);
            }
        }

        @include theme(br) {
            border-radius: 3px;
            background-color: var(--colors_rust);
            font-size: rem(14px);
            text-transform: none;
            letter-spacing: -0.02rem;
        }

        @include theme(wb) {
            background-color: var(--colors_highlight);
            font-size: rem(14px);
            text-transform: none;
            letter-spacing: -0.14px;

            &:hover {
                @extend .cta--hover-green;
            }
        }
    }

    @include breakpoint(sm) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &:before {
            display: none;
        }

        &__heading {
            flex-basis: 25%;
            padding-top: 5px;
            padding-bottom: 0px;
            padding-right: 20px;
        }

        &__form-container {
            flex-basis: 75%;
            min-height: 60px;
            display: flex;
            align-items: center;
        }

        .react-form__form {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .react-form__form-wrapper {
            width: 100%;
        }

        input {
            font-size: rem(15px);
            line-height: rem(60px);
            height: 50px;
            margin-top: 10px;
        }

        .react-form__field {
            flex-basis: 33%;
            padding-right: 20px;
            padding-top: 0;

            &.has-value {
                .react-form__field__label {
                    top: -10px;
                }
            }

            .react-form__field__label {
                top: 12px;
            }
        }

        .react-form__button {
            flex-basis: 33%;
            margin: 0;
            margin-top: 5px;
        }
    }
    .simple-header & {
        &__heading {
            display: none;
        }
        .react-form__form {
            max-width: 380px;
            flex-direction: column;
            label {
                background-color: white;
            }
        }
        .react-form__field input {
            margin-top: 25px;
            padding-left: 15px;
            color: var(--colors_body_black);
        }
        .react-form__field__label {
            padding-left: 15px;
            color: var(--colors_body_black);
        }
        .react-form__field.has-value {
            top: 0px;
            .react-form__field__label {
                top: -5px;
            }
        }
        .react-form__button {
            background: $colors_black_med;
            border-color: $colors_black_med;
            color: white;
            margin-top: 30px;
            padding: 20px 30px;

            @include theme(br) {
                background-color: var(--colors_highlight);
                border-radius: 4px;
            }

            @include theme(wb) {
                width: 215px !important;
                background-color: var(--colors_highlight);
                border-color: var(--colors_highlight);
                color: black;

                &:hover {
                    @extend .cta--hover-green;
                }
            }

            @include breakpoint(sm) {
                margin-right: 20px;
            }
            &:hover {
                @extend .cta--hover-black;
            }
        }
    }
}
