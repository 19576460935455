.newsletter-toaster {
    display: none;
    position: fixed;
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: -120px;
    right: 0;
    height: 120px;
    width: 100%;
    background-color: var(--colors_highlight);
    padding: 30px;
    transition: all 250ms ease-in;
    z-index: 99;
    box-shadow: 0 -7px 40px 0 rgba(black, 0.13);

    &--show {
        bottom: 0px;
    }

    &__title {
        margin-bottom: 20px;
        text-align: center;
        @include rem(font-size, 16px);

        @include theme(br) {
            font-family: var(--font_family_heading_regular);
            font-weight: 600;
        }
    }

    &__ctas {
        display: flex;

        & > * + *,
        .cta--inline + .cta--inline {
            margin-left: 5px;
        }
        .cta {
            cursor: pointer;
        }
    }

    @include theme(dh) {
        background-color: #f6004e;
        box-shadow: 0 -7px 22px 0 rgba(black, 0.28);

        &__title {
            color: white;
        }

        .cta--secondary {
            color: white;
            border-color: white;

            &:hover {
                background: var(--colors_highlight) !important;
                background-color: var(--colors_highlight) !important;
                border-color: $colors_black_med !important;
                color: $colors_black_med;

                .icon {
                    color: white;
                }
            }
        }
    }

    @include theme(br) {
        background: var(--colors_pale_leaf);

        .cta--dark {
            background: var(--colors_highlight);
            border-color: var(--colors_highlight);
            color: white;

            &:hover {
                border-color: $colors_black_med;
            }
        }

        .cta--secondary {
            color: black;

            &:hover {
                color: white;
            }
        }

        @include breakpoint(xs, down) {
            &--show {
                height: auto;
            }
        }
    }

    @include theme(wb) {
        background: black;
        height: auto;

        &__title {
            color: white;
        }

        &__ctas {
            .cta--dark {
                background-color: var(--colors_highlight);
                border-color: var(--colors_highlight);
                color: black;

                &:hover {
                    @extend .cta--hover-green;
                }
            }

            .cta--secondary {
                background-color: transparent;
                border-color: white;
                color: white;

                &:hover {
                    @extend .cta--hover-white-outline;
                }
            }
        }
    }

    @include breakpoint(md) {
        @include theme(dh) {
            @include facet("dark", 0.4, 60%, auto);
        }
        padding: 35px 60px;
        bottom: -250px;
        right: 25px;
        width: 440px;
        height: 250px;
        &__title {
            margin-bottom: 20px;
            @include rem(font-size, 24px);
        }

        &--show {
            bottom: 25px;
        }
    }
}
