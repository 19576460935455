.gallery {
    margin: 50px 0;
    overflow: hidden;

    @include theme(br) {
        margin: 50px 0 100px;
    }

    &__heading {
        margin: 0 0 1em;
        font-size: rem(28px);
        line-height: rem(44px);

        @include theme(dh) {
            font-size: rem(28px);
            line-height: rem(34px);
            text-transform: uppercase;
            font-family: var(--font_family_heading_extrabold);
        }

        @include theme(br) {
            font-family: var(--font_family_heading_bold);
        }

        @include theme(wb) {
            text-transform: uppercase;
            letter-spacing: -0.38px;
            line-height: 42px;
        }

        @include breakpoint($breakpoint_desktop_nav) {
            font-size: rem(38px);
            line-height: rem(54px);
        }
    }

    &__slide {
        @include aspect-ratio(16, 9);
        background-size: cover;
    }

    @include breakpoint($breakpoint_desktop_nav) {
        margin: 80px 0;

        @include theme(br) {
            margin: 80px 0 150px;
        }
    }

    .carousel {
        &__button {
            background-color: black;
            color: var(--colors_highlight);

            @include theme(dh) {
                background-color: var(--colors_highlight);
                color: white;
            }

            @include theme(br) {
                background-color: var(--colors_highlight);
                color: white;
            }

            @include theme(wb) {
                background-color: black !important;
                color: white !important;

                &:nth-child(2) {
                    border-left: 1px solid rgba(#fff, 0.2) !important;
                }
            }
        }
    }
}
