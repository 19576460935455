.site-notification {
    background-color: var(--colors_highlight);
    overflow: hidden;
    position: relative;

    @include theme(dh) {
        background-color: var(--colors_green_light);
    }

    @include theme(br) {
        background-color: var(--colors_highlight);
    }

    @include theme(wb) {
        background-color: var(--colors_soft_yellow);
    }

    &__collapsible {
        transition: all 100ms ease;
        height: 0;
    }

    &--show &__collapsible {
        height: auto;
    }

    &__inner {
        padding: 13px 70px 13px 30px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        @include breakpoint(sm) {
            padding: 16px 80px 16px $layout_page_padding;
            align-items: center;
            flex-direction: row;
        }
    }

    &__heading {
        display: inline;
        font-family: var(--font_family_main);
        font-size: rem(14px);
        line-height: rem(19px);
        text-align: center;
        margin: 0;

        @include breakpoint(sm) {
            font-size: rem(16px);
            line-height: rem(28px);
        }

        @include theme(dh) {
            text-transform: none;
            letter-spacing: -0.035em;
        }

        @include theme(br) {
            color: white !important;
            letter-spacing: -0.02em;
            font-family: var(--font_family_heading_regular);
        }
    }

    &__close {
        position: absolute;
        right: $layout_page_padding;
        top: 15px;
        font-size: rem(17px);

        @include breakpoint(sm) {
            top: 18px;
            right: $layout_page_padding + 10px;
            font-size: rem(20px);
        }

        @include theme(br) {
            color: white !important;
        }
    }

    &__cta {
        display: inline;
        line-height: 1.4em;
        font-weight: bold;
        color: var(--colors_body_black);
        margin-left: 5px;
        text-decoration: none;
        box-shadow: inset 0 -0.15em 0 rgba(#2d2d2d, 0.32);
        transition: all 0.3s;

        &:hover {
            box-shadow: inset 0 -2.7em 0 rgba(#2d2d2d, 0.32);
        }

        @include theme(br) {
            color: white !important;
            box-shadow: inset 0 -0.15em 0 white;
            letter-spacing: -0.02em;
            display: inline-block;
            padding-bottom: 0.2em;
            &:hover {
                box-shadow: inset 0 -7px 0 white;
            }
        }
    }
}
