.main-hero {
    position: relative;

    .trending-content {
        order: 0;
    }

    .hero__inner {
        order: 1;
    }

    .hero__container {
        flex-direction: column;
    }

    &__feature {
        position: relative;
        z-index: 5;

        @include breakpoint($breakpoint_desktop_nav) {
            margin-top: -100px;
        }

        &__image {
            @include aspect-ratio(3, 2);
            background-size: cover;
            background-position: center center;
            margin-top: $layout_page_padding;

            @include breakpoint($breakpoint_desktop_nav) {
                margin-top: 170px;
            }
        }
    }

    &__video {
        @include youtube();

        video {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
        }
    }

    .hero__main__image {
        z-index: 0;
        overflow: hidden;

        &__img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .main-hero__main .youtube-player iframe {
        @include breakpoint($breakpoint_desktop_nav) {
            height: 56.5vw;
            width: 177.77777778vh;
        }
    }

    &__main__description,
    &__feature__description {
        font-size: rem(14px);
        line-height: rem(22px);

        @include theme(dh) {
            color: white;
        }

        @include theme(br) {
            letter-spacing: -0.02em;
            color: black;
        }

        @include theme(wb) {
            letter-spacing: -0.16px;
            color: black;
            font-size: rem(16px);
            line-height: 24px;
        }
    }

    &__tile {
        position: relative;
        z-index: 10;
        @include rem(font-size, 17px);
        @include rem(line-height, 28px);

        &__inner {
            position: relative;
            padding: 40px;
            background-color: var(--colors_highlight);

            @include theme(dh) {
                background-color: #eb054e;
                mask-image: none;
                @include facet("dark", 0.4, 100%);

                @include breakpoint($breakpoint_desktop_nav) {
                    @include facet("dark", 0.4, auto, 100%);
                }
            }

            @include theme(br) {
                position: relative;
                z-index: 2;
                background-color: var(--colors_rust);
                -webkit-mask-image: none;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    right: 0;
                    display: block;
                    width: 100%;
                    height: 85%;
                    background-image: url("../assets/media/barangaroo-lines-lilac.png");
                    background-repeat: no-repeat;
                    background-size: 80%;
                    background-position: right 97%;

                    @include breakpoint(sm, down) {
                        bottom: 0;
                        height: 100%;
                        width: 60%;
                        background-size: contain;
                    }

                    @include breakpoint(xs, down) {
                        width: 80%;
                    }
                }
            }

            @include theme(wb) {
                mask-image: none;
            }

            @include breakpoint($breakpoint_desktop_nav) {
                mask-image: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0.8) 0%,
                    rgba(0, 0, 0, 1) 70%
                );
            }
        }

        &__title {
            margin-top: 15px;
            margin-bottom: 8px;

            @include theme(dh) {
                color: white;
            }

            @include theme(br) {
                text-transform: none;
                color: #000;
                letter-spacing: -0.02em;
                opacity: 1;

                font-size: rem(34px);
                line-height: (38px);

                @include breakpoint(lg) {
                    font-size: rem(48px);
                    line-height: (52px);
                }
            }

            @include theme(wb) {
                color: #000;
                letter-spacing: -0.48px;
                opacity: 1;

                font-size: rem(34px);
                line-height: (36px);

                @include breakpoint(lg) {
                    font-size: rem(48px);
                    line-height: (52px);
                }
            }
        }

        .eyebrow {
            font-size: rem(10px);
            line-height: rem(12px);
            color: $colors_grey_dark;
            font-weight: bold;
            opacity: 0.65;

            @include theme(dh) {
                font-weight: normal;
                color: white;
                opacity: 1;
            }

            @include theme(br) {
                text-transform: none;
                color: #000;
                letter-spacing: -0.02em;
                opacity: 1;

                @include breakpoint(xs, down) {
                    font-size: rem(12px);
                    line-height: rem(14px);
                }
            }

            @include theme(wb) {
                color: #000;
                letter-spacing: 0.64px;
                opacity: 1;
                font-size: rem(16px);
                line-height: rem(20px);

                @include breakpoint(xs, down) {
                    font-size: rem(12px);
                    line-height: rem(14px);
                }
            }
        }

        .cta {
            margin-top: 30px;

            @include theme(br) {
                background: var(--colors_highlight) !important;
                color: white;
                border-color: var(--colors_highlight);
                border-radius: 4px;
                text-transform: none;

                &:hover {
                    border-color: $colors_black_med !important;
                    background: $colors_black_med !important;
                }
            }

            @include theme(wb) {
                &--secondary {
                    color: black;
                    border-color: var(--colors_body_black) !important;
                    background: transparent !important;

                    &:hover {
                        @extend .cta--hover-black-outline;
                    }
                }
            }
        }

        &--primary {
            @media (max-width: $breakpoint_md) {
                margin: 0 -#{$layout_page_padding};
            }
        }

        &--feature {
            margin-bottom: $main_hero_inset;

            @include breakpoint($breakpoint_desktop_nav) {
                margin-top: -80px;
            }

            .main-hero__tile__inner {
                background: var(--colors_green_light);

                @include theme(dh) {
                    background: #671980;
                    @include facet("dark", 0.7, 100%);

                    @include breakpoint($breakpoint_desktop_nav) {
                        @include facet("dark", 0.7, auto, 100%);
                    }
                }

                @include theme(br) {
                    position: relative;
                    z-index: 2;
                    background: var(--colors_sandstone);

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        z-index: -1;
                        display: block;
                        width: 100%;
                        height: 83%;
                        background-image: url("../assets/media/barangaroo-lines-rust.png");
                        background-repeat: no-repeat;
                        background-size: 80%;
                        background-position: left bottom;

                        @include breakpoint(sm, down) {
                            bottom: 0;
                            height: 100%;
                            width: 60%;
                            background-size: contain;
                        }

                        @include breakpoint(xs, down) {
                            width: 80%;
                        }
                    }
                }

                @include theme(wb) {
                    background: var(--colors_soft_yellow);
                }
            }
        }

        &__texture {
            display: block;
            content: "";
            position: absolute;
            bottom: -$layout_page_padding;
            right: -$layout_page_padding;
            z-index: -1;
            width: 200px;
            height: 200px;
            background: url('/assets/media/texture-home.png');

            @include theme(dh) {
                background: #cdf2ea;
                background-image: none !important;
                @include facet("dark", 0.2, 100%);
            }

            @include theme(br) {
                background-image: none !important;
            }

            @include theme(wb) {
                width: 300px;
                height: 300px;
                background: url('/assets/media/texture-home_wb.png');
                background-size: cover;
                left: -50px;
                bottom: -20px;

                @include breakpoint(sm, down) {
                    bottom: -50px;
                }

                @include breakpoint(xs, down) {
                    width: 200px;
                    height: 200px;
                    bottom: -30px;
                }
            }

            @include breakpoint($breakpoint_desktop_nav) {
                bottom: -$main_hero_inset + 20px;
                left: -$main_hero_inset;
            }
        }
    }

    @include breakpoint(md) {
        padding-bottom: 20px;

        .trending-content {
            order: 1;
        }

        .hero__inner {
            order: 0;
        }

        .hero__container {
            flex-direction: row;
        }

        &__feature {
            padding-bottom: 100px;
        }
        &__tile__title {
            font-size: rem(24px);
            line-height: rem(32px);
            margin-bottom: 20px;
        }
        &__tile__inner {
            padding: 60px;
        }
        .eyebrow {
            font-size: rem(14px);
            line-height: rem(17px);
        }
    }

    @include breakpoint(lg) {
        &__tile__title {
            font-size: rem(32px);
            line-height: rem(42px);
        }

        &__main__description,
        &__feature__description {
            font-size: rem(18px);
            line-height: rem(28px);

            @include theme(dh) {
                font-size: rem(16px);
                line-height: rem(24px);
            }
        }
    }

    @include breakpoint(xl) {
        &__tile__inner {
            padding: 80px 70px;
        }
        &__tile__title {
            font-size: rem(42px);
            line-height: rem(50px);

            @include theme(dh) {
                font-size: rem(48px);
                line-height: rem(52px);
            }
        }
    }
}
