.form-builder .react-form,
.tenant-landing .react-form,
.filming-application .react-form,
.precinct-map .react-form,
.precinct-map__modal__pane .react-form {
    /* Generic Form Styles */
    &__element {
        position: relative;
        flex: 0 0 calc(100% - 20px);
        margin: 10px;
        &--hidden {
            margin: 0px;
        }
        &--half {
            flex: 0 0 calc(50% - 20px);
        }

        &--half-collapse {
            flex: 0 0 calc(100% - 20px);
            @include breakpoint(sm) {
                flex: 0 0 calc(50% - 20px);
                + .react-form__element--half-collapse {
                    flex: 0 0 calc(50% - 35px);
                    margin-left: 25px;
                }
            }
        }
        &--has-subheading,
        &--has-subheading + .react-form__element--half {
            margin-top: 30px;
            &:before {
                content: attr(data-sub-heading);
                position: absolute;
                top: -42px;
                left: 0;
                font-size: rem(18px);
                line-height: rem(30px);
                font-weight: 600;
                color: $colors_grey_med;
            }
        }

        &--has-subheading + .react-form__element--half {
            &:before {
                content: "-";
                color: transparent;
            }
        }

        &--heading {
            margin-top: 0;
            &:before {
                content: "";
                position: absolute;
                top: -10px;
                left: 0;
                height: 1px;
                width: 100%;
                background-color: $colors_grey_light;
            }
            .heading__title {
                position: relative;
                font-family: var(--font_family_heading);
                font-size: rem(28px);
                line-height: rem(40px);
                margin-top: 22px;
                margin-bottom: 0;

                @include theme(wb) {
                    @extend .heading-5;
                }
            }
        }
    }
    &__element--recaptcha {
        margin-top: 20px;
        margin-bottom: 0px;
        margin-left: 0px;

        &.hidden {
            display: none;
        }
    }

    &__label-title,
    .react-form__field-group .react-form__field-group__label {
        font-size: rem(18px);
        line-height: rem(26px);
        font-weight: 600;
        color: $colors_grey_med;
        height: auto;
        a {
            color: inherit;
        }
    }

    &__label-description {
        font-size: rem(14px);
        line-height: rem(22px);
        margin-top: 8px;
        margin-bottom: 15px;
        a {
            color: inherit;
        }
    }

    &__section {
        width: 100%;
        padding: 0;
        margin-bottom: 22px;
        border: 0;
        border-bottom: 1px solid $colors_grey_light;
        &:last-of-type {
            border-bottom: none;
        }
    }

    &__section-label {
        font-family: var(--font_family_heading);
        font-size: rem(28px);
        line-height: rem(40px);
        margin-bottom: 20px;
        padding-top: 10px;

        @include theme(br) {
            font-family: var(--font_family_heading_bold);
        }
    }

    &__section-subheading {
        display: none;
        flex-basis: 100%;
        font-size: rem(18px);
        line-height: rem(30px);
    }

    &__section__container {
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 0;
        margin: -10px;
    }

    &__step {
        width: 100%;
        padding: 0;
        margin-bottom: 22px;
        border: 0;
        border-bottom: 1px solid $colors_grey_light;
        &:last-of-type {
            border-bottom: none;
        }
        display: none;
    }

    &__step.actived {
        display: block;
    }

    &__step-label {
        font-family: var(--font_family_heading);
        font-size: rem(38px);
        line-height: rem(40px);
        width: 100%;
        margin-bottom: 22px;
        padding-bottom: 26px;
        border-bottom: 1px solid $colors_grey_light;
        padding-top: 10px;
    }

    &__step-subheading {
        display: none;
        flex-basis: 100%;
        font-size: rem(18px);
        line-height: rem(30px);
    }

    &__step__container {
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 0;
    }

    &__field {
        input,
        textarea,
        select {
            background-color: $colors_bg_beige;

            @include theme(wb) {
                background-color: white;
            }
        }

        &.is-invalid {
            color: $colors_red_dark;
        }
        &.is-valid label {
            color: inherit;
        }

        &__input-container {
            margin-right: 0;
        }

        &__errors {
            font-size: rem(12px);
            line-height: rem(16px);
            margin-top: 8px;
        }

        &.is-price {
            .react-form__field__input-container {
                position: relative;

                &:before {
                    content: "$";
                    position: absolute;
                    left: 15px;
                    top: 24px !important;
                    font-family: var(--font_family_main) !important;
                    font-size: 1.125rem !important;
                }

                input {
                    padding-left: 30px;
                }
            }
        }

        &.is-price.is-incomplete.is-invalid {
            .react-form__field__input-container {
                position: relative;

                &:before {
                    top: 27px !important;
                }
            }
        }

        &__rte {
            width: 100%;
            min-height: 400px;
        }

        &--text,
        &--email,
        &--number,
        &--tel,
        &--select,
        &--time {
            margin-bottom: 30px;
            input,
            select {
                height: 100%;
                padding-left: 15px;
                outline: none;
                font-size: rem(18px);
                padding-top: 10px;
            }
            input {
                padding-right: 50px;
            }
            label {
                border-bottom: none;
                position: relative;
                font-size: rem(18px);
            }
            .react-form__field__label {
                position: absolute;
                z-index: 1;
                top: 8px;
                left: 15px;
                transition: all 100ms ease-in-out;
                color: $colors_grey_med;
            }
            .react-form__field__input-container,
            select {
                position: relative;
                display: flex !important;
                box-sizing: border-box;
                height: 64px;
                border-bottom: 1px solid $colors_grey_med;

                @include theme(br) {
                    border-color: var(--colors_highlight);
                }

                @include theme(wb) {
                    border-color: black;
                }
            }
            &.is-focused {
                input {
                    padding-top: 12px;
                }
                .react-form__field__input-container,
                select {
                    border-bottom: 2px solid var(--colors_maroon);

                    @include theme(dh) {
                        border-color: #36caac;
                    }

                    @include theme(br) {
                        border-color: var(--colors_highlight);
                    }

                    @include theme(wb) {
                        border-color: black;
                    }
                }
            }
            &.is-focused.is-invalid {
                .react-form__field__input-container,
                select {
                    border-bottom: 2px solid $colors_red_dark;
                }
            }

            &.has-value,
            &.has-placeholder {
                .react-form__field__label {
                    font-size: rem(12px);
                    color: var(--colors_maroon);
                    top: -5px;
                    font-weight: 600;

                    @include theme(dh) {
                        color: $colors_grey_dark;
                        font-weight: normal;
                    }

                    @include theme(br) {
                        color: var(--colors_highlight);
                    }

                    @include theme(wb) {
                        color: black;
                    }
                }
            }

            &.is-invalid {
                .react-form__field__input-container {
                    border-bottom: 1px solid $colors_red_dark;
                    @extend .icon;
                    @extend .icon-exclamation-mark;
                    &:before {
                        position: absolute;
                        top: 22px;
                        right: 15px;
                        font-size: 20px;
                    }
                }
                &.has-datepicker .react-form__field__input-container {
                    input {
                        padding-right: 80px;
                    }
                    &:before {
                        right: 50px;
                    }
                }

                .react-form__field__label {
                    color: $colors_red_dark;
                }
            }
            .react-form__field__info,
            .react-form__field__errors {
                padding-left: 15px;
                font-size: rem(12px);
                line-height: rem(16px);
                margin-top: 8px;
                a {
                    color: inherit;
                }
            }

            .react-form__field__errors + .react-form__field__info {
                margin-top: 0;
            }

            + .react-form__datepicker-icon {
                color: var(--colors_maroon);
                position: absolute;
                right: 20px;
                top: 21px;
                font-size: 20px;
                cursor: pointer;

                @include theme(dh) {
                    color: $colors_grey_med;
                }

                @include theme(br) {
                    color: var(--colors_highlight);
                }

                 @include theme(wb) {
                        color: black;
                    }
            }
        }

        &--number {
            @include theme(br) {
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type=number] {
                    -moz-appearance: textfield;
                }
            }
        }

        &--time {
            padding-top: 10px;
        }

        &--select {
            label {
                @extend .icon;
                @extend .icon-chevron-down;
                &:before {
                    position: absolute;
                    top: 26px;
                    right: 15px;
                    font-size: 12px;
                    z-index: 1;
                    color: $colors_grey_med;
                    pointer-events: none;
                }
            }

            .react-form__field__label {
                pointer-events: none;
            }
        }

        &--checkbox,
        &--radio {
            margin: 1em 0;

            &:last-child {
                margin-bottom: 0;
            }

            label {
                align-items: flex-start;
            }

            .react-form__field__checkbox-indicator,
            .react-form__field__radio-indicator {
                border: 2px solid rgba(150, 150, 150, 0.75);
                height: 20px;
                width: 20px;
                border-radius: 100%;
                flex: 0 0 20px;

                font-size: 6px;
                display: flex !important;
                justify-content: center;
                align-items: center;
                transition: all 200ms;

                &:before {
                    display: inline;
                    background: none;
                    height: auto;
                    width: auto;
                    opacity: 1;
                    color: rgba(150, 150, 150, 0.75);

                    @include theme(br) {
                        color: rgba(150, 150, 150, 0.75) !important;
                    }
                }
            }
            .react-form__field__label {
                font-size: rem(16px);
                line-height: rem(26px);
                color: $colors_grey_med;
                height: auto;
                min-height: 0;
                &__inner {
                    white-space: normal;
                    padding-right: 5px;
                }
            }
            .react-form__field__input-container {
                margin-right: 0;
                padding-top: 0.2em;
            }

            .react-form__field__error-icon {
                display: none;
            }

            &.is-checked,
            input:checked ~ {
                .react-form__field__checkbox-indicator,
                .react-form__field__radio-indicator {
                    border-color: var(--colors_highlight);
                    background-color: var(--colors_highlight);

                    @include theme(dh) {
                        border-color: #9be5d5;
                        background-color: #9be5d5;
                    }

                    @include theme(wb) {
                        border-color: black;
                        background-color: black;
                    }

                    &:before {
                        color: $colors_grey_dark;

                        @include theme(br) {
                            color: white !important;
                        }

                        @include theme(wb) {
                            color: white !important;
                        }
                    }
                }
            }
            &.is-focused {
                .react-form__field__checkbox-indicator {
                    border-color: $colors_grey_med_light;
                }
            }
            &.is-focused.is-checked {
                .react-form__field__radio-indicator {
                    border-color: $colors_grey_med_light;
                }
            }
            &.is-valid {
                .react-form__field__label {
                    color: $colors_grey_med;
                }
            }
        }

        &--checkbox {
            .react-form__field__checkbox-indicator {
                @extend .icon;
                @extend .icon-tick;
            }
        }

        &--radio {
            .react-form__field__radio-indicator {
                &:before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    background: $colors_grey_med_light;

                    @include theme(br) {
                        background: white !important;
                    }

                    @include theme(wb) {
                        background: white !important;
                    }
                }
            }

            &.is-checked,
            input:checked ~ {
                .react-form__field__radio-indicator {
                    &:before {
                        background: $colors_grey_dark;
                    }
                }
            }
        }

        &--textarea {
            margin-bottom: 60px;
            .react-form__field__input-container {
                margin-right: 0;
                margin-top: 5px;
                border: 2px solid $colors_bg_beige;
                box-sizing: border-box;
                position: relative;
            }
            textarea {
                outline: none;
                font-size: rem(16px);
                line-height: rem(26px);
                color: $colors_grey_med;
                padding: 40px;
                box-sizing: border-box;
                min-height: 200px;
                width: 100%;
            }
            label {
                border-bottom: none;
            }

            .react-form__field__label {
                &__inner {
                    white-space: normal;
                }
            }

            .react-form__field__counter {
                font-size: rem(12px);
                float: right;
            }

            &.is-focused .react-form__field__input-container {
                background-color: white;
                border: 2px solid var(--colors_maroon);
                outline: none;

                @include theme(dh) {
                    border-color: #36caac;
                }

                @include theme(br) {
                    border-color: var(--colors_highlight);
                }

                 @include theme(wb) {
                    border-color: black;
                }
            }
            &.is-invalid.is-focused .react-form__field__input-container {
                border: 2px solid $colors_red_dark;
            }
            &.is-invalid .react-form__label-description {
                color: $colors_grey_med;
            }
            &.is-invalid .react-form__label-title {
                color: $colors_red_dark;
            }
        }

        &--file {
            margin-bottom: 30px;
            label {
                display: flex;
                align-items: flex-end;
                border: none;
            }
            .react-form__field__label__inner {
                white-space: normal;
            }
            .react-form__field__input-container {
                height: 1px;
                width: 1px;
                overflow: hidden;
            }
            .react-form__field__browse-button {
                background-color: $colors_grey_light;
                border-color: $colors_grey_light;
                margin-top: 30px;
                margin-right: 20px;

                @include theme(dh) {
                    border-color: var(--colors_highlight);
                    color: var(--colors_highlight);
                    background: none;

                    &:hover {
                        background: var(--colors_highlight) !important;
                        color: white;
                    }
                }

                @include theme(br) {
                    border-color: var(--colors_highlight);
                    color: var(--colors_highlight);
                    background: none;

                    &:hover {
                        background: var(--colors_highlight) !important;
                        color: white;
                    }
                }

                @include theme(wb) {
                    @extend .cta--secondary;

                    &:hover {
                        @extend .cta--hover-black-outline;
                    }
                }
            }
            .react-form__field__filename {
                border-bottom: 1px solid #e2e2e2;
                padding-bottom: 10px;
                width: 50%;
                color: $colors_grey_med_light;
                font-size: rem(16px);
            }
            .react-form__field__filesize {
                margin-left: 5px;
                color: $colors_grey_med_light;
            }
            &.has-value {
                .react-form__field__filename {
                    color: $colors_grey_med;
                }
            }
        }
    }

    .radio-group,
    .checkbox-group {
        border: none;
        padding-left: 0;
        margin-bottom: 10px;
    }

    .react-form__field--radio + .react-form__field--radio {
        margin-top: 0;
    }
    .react-form__field--checkbox
        input:focus
        ~ .react-form__field__checkbox-indicator,
    .react-form__field--radio
        input:focus
        ~ .react-form__field__radio-indicator {
        outline: none !important;
    }

    /* Button Styles */
    &__button {
        margin-top: 40px;
        margin-bottom: 30px;
        cursor: pointer;

        &.is-disabled {
            background-color: $colors_grey_light;
            border-color: $colors_grey_light;
            pointer-events: none;
            cursor: initial;
        }

        @include theme(wb) {
            @extend .cta--dark;
        }
    }

    .react-form__field__suggestions-list {
        position: absolute;
        background-color: white;
        color: black;
        width: 100%;
        top: 64px;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;
        list-style-type: none;
        overflow-y: auto;
    }

    .sub-heading__title {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }

    .sub-heading__description {
        margin: 0;
        margin-top: 5px;
        font-size: 14px;
    }

    .step-indicators-container {
        display: flex;
        padding: 40px 127px;
        justify-content: space-between;
        border-bottom: 1px solid $colors_grey_light;
        margin-bottom: 70px;
        position: relative;
        &::before {
            content: " ";
            position: absolute;
            height: 2px;
            width: calc(100% - 2 * 127px);
            top: 58px;
            background-color: $colors_grey_light_dark;
            z-index: 0;
        }
    }

    .step-indicator {
        z-index: 1;
        width: 58px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        &.hightlight {
            .step-indicator-icon {
                border-color: var(--colors_highlight);
                background-color: var(--colors_highlight);

                @include theme(dh) {
                    border-color: #9be5d5;
                    background-color: #9be5d5;
                }

                @include theme(br) {
                    border-color: var(--colors_highlight);
                    background-color: var(--colors_highlight);
                }
            }
            .step-indicator-text {
                color: black;
            }
        }

        .step-indicator-icon {
            height: 38px;
            width: 38px;
            border-radius: 19px;
            border: 2px solid $colors_grey_light_dark;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: bold;
            i {
                font-size: 8px;
            }
        }

        .step-indicator-text {
            padding-top: 16px;
            overflow: visible;
            white-space: nowrap;
            text-align: center;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: $colors_grey_75;

            @include theme(br) {
                text-transform: none;
                letter-spacing: -0.02em;
            }
        }
    }

    // react form group styles
    .react-form__field-group {
        flex: 1 1 100%;
        border: none;
        padding: 0;
        margin: 10px;

        &.is-invalid {
            .react-form__field-group__label {
                color: $colors_red_dark;
            }
        }

        .react-form__field-group__label {
            margin-bottom: 10px;
        }

        .react-form__element,
        .react-form__field {
            margin: 0;
        }

        .react-form__field-group__errors {
            color: $colors_red_dark;
            padding-bottom: 20px;
        }
    }
}
