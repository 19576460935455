.stall-apply {
    position: relative;

    &__box {
        border: 2px solid #D6D6D6;
        padding: 20px 30px;
    }

    &__title {
        font-size: rem(20px);
        line-height: rem(30px);
        margin: 0 0 20px;
    }

    &__short-description {
        color: $colors_grey_med;
        font-size: rem(16px);
        line-height: rem(26px);
    }

    .cta {
        white-space: nowrap;
        &--inline{
            @include theme(wb) {
                background-color: black;
                color: white !important;
                border: 2px solid black;
            }
        }
    }

    @include breakpoint(sm, down) {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 50px;
        .cta {
            margin-top: 40px;
            font-size: rem(12px);
            padding: 16px 75px;
        }
    }

    @include breakpoint(md) {
        padding-bottom: 90px;
        &:before {
            display: none;
        }

        &__box {
            padding: 60px;
        }

        &__button {
            text-align: right;
        }

        &__title {
            font-size: rem(24px);
            line-height: rem(34px);
            margin: 0;
            margin-bottom: 12px;
        }

        &__short-description {
            font-size: rem(18px);
            line-height: rem(30px);
        }
    }
}
