.latest-tiles {
    margin: 70px 0;

    @include breakpoint($breakpoint_desktop_nav) {
        margin: 90px 0;
    }

    &__heading {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: rem(28px);
        line-height: rem(44px);

        @include breakpoint($breakpoint_desktop_nav) {
            margin-top: 25px;
            font-size: rem(42px);
            line-height: rem(66px);

            @include theme(dh) {
                font-size: rem(48px) !important;
                line-height: rem(52px) !important;
            }
        }

        @include breakpoint(xl) {
            font-size: rem(52px);
            line-height: rem(82px);
        }

        @include theme(br) {
            letter-spacing: -0.02em;
        }
    }

    &__scroller {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        margin-left: -$layout_page_padding;
        padding-top: 5px;

        & > *:first-child {
            margin-left: $layout_page_padding;
        }
    }

    &__tiles {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        &__item {
            flex: 0 1 33.333333%;
            min-width: 335px;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__link {
        margin-top: 15px;

        @include breakpoint($breakpoint_desktop_nav) {
            margin-top: 25px;
        }

        @include theme(wb) {
            .cta--inline {
                @extend .cta--hover-grey;
            }
        }
    }

    .tile {
        min-height: 0;
        margin-bottom: $layout_page_padding;
    }

    div.tile__date {
        margin-left: 0;

        @include theme(br) {
            @include breakpoint($breakpoint_desktop_nav, down) {
                margin-left: -25px;
                margin-top: -60px;
            }
        }
    }

    .tile__author--push {
        display: none;
    }

    @include breakpoint($breakpoint_desktop_nav) {
        .tile__author {
            &.tile__author--push {
                display: block;
                padding-bottom: 10px;
            }
        }
        &__tiles__item .tile .tile__content {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    @include breakpoint(xl) {
        &__tiles__item .tile .tile__content {
            padding-left: 40px;
            padding-right: 40px;
        }

        .tile__title {
            font-size: rem(28px);
            line-height: rem(32px);
            letter-spacing: -0.6px;

            @include theme(br) {
                letter-spacing: -0.02em;
            }
        }
    }

    @media (max-width: $breakpoint_md) {
        .latest-tiles__container {
            padding-left: 0;
        }

        .latest-tiles__heading,
        .latest-tiles__link {
            margin-left: $layout_page_padding;
            margin-right: $layout_page_padding;
        }
    }
}
