.form-builder {
    margin: 80px 0;

    @include theme(wb) {
        position: relative;
        margin: 0;
        padding: 80px 0;

        &:before {
            content: "";
            display: block;
            position: absolute;
            z-index: -1;
            background: #f8f8f8;
            top: -30px;
            left: 30px;
            right: 30px;
            bottom: 30px;
        }

    }

    @include theme(br) {
        @include breakpoint(sm) {
            margin: 80px 0 120px;
        }
    }

    a {
        color: $colors_grey_med;
        text-decoration: none;
        @extend .underline;
    }

    &__title {
        margin-bottom: 20px;

        @include theme(wb) {
            font-size: 38px;
            line-height: 42px;
            letter-spacing: -0.38px;

            @include breakpoint(sm, down) {
                font-size: 28px;
                line-height: 30px;
                letter-spacing: -0.28px;
            }
        }
    }

    &__description {
        margin-bottom: 30px;

        @include theme(wb) {
            font-size: 18px;
            line-height: 30px;
            letter-spacing: -0.36px;

            @include breakpoint(sm, down) {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.16px;
            }
        }
    }

    &__thankyou {
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__icon {
            @extend .icon;
            @extend .icon-non-rounded-tick;
            background-color: rgba($colors_body_black, 0.7);
            color: var(--colors_highlight);
            height: 96px;
            width: 96px;
            font-size: 24px;
            border-radius: 100%;
            display: flex !important;
            justify-content: center;
            align-items: center;
            margin-bottom: 25px;
        }
        &__title {
            font-family: var(--font_family_heading);
            font-size: rem(28px);
            line-height: rem(40px);

            @include theme(br) {
                font-family: var(--font_family_heading_bold);
            }
        }
        &__text {
            font-size: rem(18px);
            line-height: rem(30px);
            color: $colors_grey_med;
        }
    }
    &__datepicker-root {
        display: none;
    }

    &__datepicker-modal {
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: $layout_nav_zindex + 8;
        background: rgba(black, 0.4);
        &.is-active {
            display: flex;
        }
    }

    &__servererror {
        display: none;
        color: $colors_red_dark;
        background: rgba(#b00020, 0.1);
        padding: 15px;
        margin: 20px 0;

        &.is-active {
            display: block;
        }
    }
}

.venue-form {
    @include theme(wb) {
        background-color: #f8f8f8;
        margin: -30px auto 0;
        width: calc(100% - 60px);
    }
}
