.search-result-entry {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: $colors_black_med;
    padding: 25px 0 25px;

    &__copy {
        display: flex;
        margin-right: 35px;
        align-items: flex-start;
        flex-direction: column;
        flex: 1;
    }

    &__infos {
        font-size: rem(10px);
        line-height: rem(12px);
        letter-spacing: 4px;
        text-transform: uppercase;
        color: #757575;
        font-weight: 700;
        margin-bottom: 8px;

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
        }
    }

    &__heading {
        margin: 0;
        font-size: rem(18px);
        line-height: rem(26px);
        letter-spacing: -1px;
        margin-bottom: 8px;

        @include theme(dh) {
            text-transform: none;
        }
    }

    &__date {
        color: var(--colors_maroon);
        &:before {
            display: inline-block;
            content: "|";
            padding-right: 10px;
            color: #757575;
        }

        @include theme(dh) {
            color: var(--colors_highlight);
        }

        @include theme(br) {
            color: var(--colors_highlight);
        }

        @include theme(wb) {
            color: var(--colors_highlight);
        }
    }

    &__short-description {
        font-size: rem(14px);
        line-height: rem(22px);
        color: $colors_grey_med;
        margin-bottom: 20px;
    }

    &__image {
        line-height: 0;
        width: 40%;
        margin-bottom: 0;
        img {
            width: 100%;
        }
    }

    @include breakpoint(sm) {
        flex-direction: row;
        justify-content: space-between;
        padding: 30px 0 40px;

        &__infos {
            font-size: rem(12px);
            line-height: rem(20px);
            letter-spacing: 5px;
            margin-bottom: 6px;
        }

        &__heading {
            font-size: rem(28px);
            line-height: rem(38px);
            letter-spacing: -1px;
            margin-bottom: 14px;
        }

        &__short-description {
            font-size: rem(16px);
            line-height: rem(26px);
        }

        &__image {
            flex-basis: 200px;
            margin-top: 0px;
        }
    }
}
