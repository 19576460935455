.loader {
    position: relative;
    margin: 0 auto;
    width: 1em;

    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    svg {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    circle {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke: var(--colors_highlight);
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

.loader-cover {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    @include insetOverlay(0.7, white);
    @extend .animated;
    @extend .fadeIn;

    &__loader {
        font-size: 100px;
    }

    &__content {
        margin: 10px 0 0;
        padding: 0 $layout_page_padding;
        font-size: $font_size_base;
        line-height: $font_lineheight_base;
        font-weight: 600;

        p {
            margin: 5px 0;
        }

        .loader-cover--dark & {
            color: white;
        }
    }

    &.site-loader {
        z-index: 99999;
        opacity: 0;
        transition: opacity 1s;
        transition-delay: 500ms;

        &.is-active {
            opacity: 1;
        }
    }

    &--transparent {
        box-shadow: none;
        background: rgba(white, 0.7);
    }

    &--fixed {
        position: fixed;
        z-index: 99999;
    }

    &--absolute {
        position: absolute;
    }

    &--solid {
        box-shadow: none;
        background: white;
    }

    &--dark {
        box-shadow: none;
        background: rgba(black, 0.8);
    }

    &--site-loader {
        z-index: 9999;
    }
}
