.building-history {
    position: relative;
    background-color: $colors_bg_beige;

    &__background {
        position: absolute;
        top: calc(100vw - 80px);
        height: calc(100% - 100vw + 80px);
        left: 0;
        width: 100%;
        overflow: hidden;

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: var(--colors_blue_light);

            @include theme(dh) {
                background: white;
            }

            @include theme(wb) {
                background-color: var(--colors_pale_blue);
            }
        }
    }

    &__background-image {
        position: absolute;
        opacity: 0.1;
        height: 100%;
        width: 100%;
        object-fit: cover;

        @include theme(wb) {
            display: none;
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-wrap: wrap-reverse;
        color: $colors_grey_dark;

        .heading-3 {
            font-family: var(--font_family_heading);
            font-size: rem(48px);
            line-height: 35px;
            letter-spacing: -0.5px;
            margin-top: 0;

            @include theme(dh) {
                font-size: rem(24px);
                line-height: rem(32px);
                letter-spacing: var(--font_letterspace_heading);
            }
        }

        .heading-4 {
            font-weight: normal;
            font-size: rem(20px);
            line-height: 29px;
            letter-spacing: -0.6px;

            @include theme(dh) {
                font-family: var(--font_family_heading_regular);
                font-size: rem(20px);
                line-height: rem(30px);
                letter-spacing: var(--font_letterspace_heading);
            }

            @include theme(wb) {
                font-family: var(--font_family_heading_main);
                font-size: rem(20px);
                line-height: rem(30px);
                letter-spacing: -0.4px;
            }
        }

        p {
            font-size: rem(16px);
            line-height: 26px;
            letter-spacing: -0.6px;

            @include theme(dh) {
                letter-spacing: 0;
            }

            @include theme(wb) {
                font-size: rem(16px);
                line-height: rem(24px);
                letter-spacing: -0.16px;
            }
        }
    }

    &__link {
        font-family: var(--font_family_main);
        font-size: rem(12px);
        letter-spacing: 3.5px;
        text-transform: uppercase;
        font-weight: 700;
        color: black;
        text-decoration: none;
        position: relative;
        padding-bottom: 0.4em;
        box-shadow: inset 0 -0.15em 0 $colors_body_black;

        transition: all 0.3s;

        &:hover {
            box-shadow: inset 0 -2em 0 $colors_body_black;
            color: white;
        }

        i {
            position: absolute;
            right: -30px;
            top: 0.1em;
            box-shadow: none;
            color: black;
            font-size: rem(14px);
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
        }
    }

    &__copyblock {
        margin-top: 35px;
        margin-bottom: 60px;

        @include theme(br) {
            margin-bottom: 100px;

            @include breakpoint (sm) {
                margin-bottom: 180px;
            }
        }
    }

    &__image {
        line-height: 0;
        width: 100%;

        img {
            width: 100%;
        }
    }

    @include breakpoint(sm) {
        &__background {
            top: 60px;
            height: calc(100% - 60px);

            @include theme(dh) {
                top: 0;
                height: 100%;
            }
        }
        &__content {
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        &__copyblock {
            margin-top: 100px;
            margin-bottom: 100px;
            width: 50%;
        }

        h3 {
            font-size: rem(38px);
            line-height: 54px;
        }

        h4 {
            font-size: rem(26px);
            line-height: 34px;
        }

        .heading-3 {
            @include theme(dh) {
                font-size: rem(48px);
                line-height: rem(52px);
            }
        }

        .heading-4 {
            @include theme(dh) {
                font-size: rem(22px);
                line-height: rem(36px);
            }
        }

        p {
            font-size: rem(18px);
            line-height: 34px;
            letter-spacing: -0.7px;

            @include theme(wb) {
                font-size: rem(18px);
                line-height: rem(30px);
                letter-spacing: -0.36px;
            }
        }

        &__link {
            font-size: rem(12px);
            letter-spacing: 3.5px;

            @include theme(wb) {
                font-size: 14px;
                text-transform: none;
                letter-spacing: -0.14px;
                line-height: 18px;

                 i {
                    &:before {
                        font-weight: 700 !important;
                    }
                 }
            }
        }

        &__image {
            width: 40%;
        }
    }
}
