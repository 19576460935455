.small-image-list {
    margin: 50px 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;

    &__title {
        margin-bottom: 20px;
        font-size: rem(32px);
        line-height: rem(44px);

        @include theme(dh) {
            font-size: rem(28px);
            line-height: rem(34px);
        }

        @include breakpoint(sm) {
            font-size: rem(42px);
            line-height: rem(50px);

            @include theme(dh) {
                font-size: rem(48px);
                line-height: rem(52px);
            }
        }
    }

    &__button-col {
        text-align: right;
    }

    &__description {
        margin-bottom: 30px;

        @include breakpoint(sm) {
            margin-bottom: 50px;
        }
    }

    &__item {
        display: flex;
        margin-bottom: 20px;

        @include breakpoint(sm) {
            margin-bottom: 30px;
        }

        &__image {
            flex: 0 0 90px;
            margin-right: 20px;

            &__inner {
                @include aspect-ratio(1, 1);
                background-size: cover;
                border-radius: 100%;
            }

            @include breakpoint(sm) {
                flex: 0 0 100px;
            }
        }

        &__title {
            margin-bottom: 5px;
            font-size: rem(16px);
            line-height: rem(22px);

            @include theme(dh) {
                font-family: var(--font_family_heading_bold);
                font-size: rem(20px);
                line-height: rem(26px);
            }

            @include breakpoint($breakpoint_desktop_nav) {
                font-size: rem(24px);
                line-height: rem(34px);
                letter-spacing: -0.6px;

                @include theme(dh) {
                    font-size: rem(24px);
                    line-height: rem(30px);
                    letter-spacing: -1px;
                }
            }
        }

        &__copy {
            .rich-text {
                font-size: rem(16px);
                line-height: rem(24px);
            }
        }
    }

    @include breakpoint($breakpoint_desktop_nav) {
        margin: 80px 0;
    }
}
