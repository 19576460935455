.image-text {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px + 40px;

    .cta {
        margin-top: 20px;
    }

    @include theme(dh) {
        .cta {
            background: var(--colors_highlight) !important;
            color: white;
            border-color: var(--colors_highlight);

            &:hover {
                border-color: $colors_black_med !important;
            }
        }

        &--image-right .cta {
            background: none !important;
            color: var(--colors_highlight);
        }
    }

    @include theme(br) {
        .image-text__description {
            p,
            a,
            li {
                color: black;
            }
        }

        .cta {
            background: var(--colors_highlight) !important;
            color: white;
            border-color: var(--colors_highlight);

            &:hover {
                border-color: $colors_black_med !important;
            }
        }

        &--image-right {
            .image-text__container__bg {
                display: block;
                left: unset;
                right: auto;
                background-color: transparent;
            }

            .image-text__content {
                padding-left: 70px;
            }

            @include breakpoint(xs, down) {
                .image-text__container__bg {
                    left: -20px;
                }

                .image-text__image {
                    margin-left: -20px;
                    margin-right: 40px;
                }

                .image-text__content {
                    padding: 25px 80px 0 10px;
                }
            }
        }
    }

    @include theme(wb) {
        .image-text__description {
            &.rich-text,
            p,
            a,
            li,
            sub,
            sup,
            small,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: white;
            }
        }

        .cta {
            background: var(--colors_highlight) !important;
            color: black;
            border-color: var(--colors_highlight);
        }

        &--image-left {
            .cta {
                &:hover {
                    @extend .cta--hover-green;
                }
            }
        }

        &--image-right {
            .image-text__description {
                &.rich-text,
                p,
                a,
                li,
                sub,
                sup,
                small,
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: black;
                }
            }

            .cta {
                background: var(--colors_body_black) !important;
                color: white;
                border-color: var(--colors_body_black);

                &:hover {
                    @extend .cta--hover-black;
                }
            }
        }
    }

    @include breakpoint(sm) {
        margin-top: 80px;
        margin-bottom: 80px + 60px;
    }

    &__container {
        position: relative;

        &__bg {
            position: absolute;
            top: 40px;
            right: 0;
            left: -20px;
            background-color: var(--colors_green_light);
            height: calc(100%);
            width: calc(100% + 20px);
            z-index: -1;

            @include theme(dh) {
                @include facet("light", 0.2, 100%);

                @include breakpoint($breakpoint_desktop_nav) {
                    @include facet("light", 0.2, auto, 100%);
                }
            }

            @include theme(br) {
                background-color: var(--colors_rust);
            }

            @include theme(wb) {
                @include breakpoint(xl) {
                    height: calc(100% + 120px);
                }

                background-color: var(--colors_body_black);
            }

            @include breakpoint(sm) {
                top: 0;
                left: auto;
                height: calc(100% + 60px);
                width: calc(100% - 60px);
            }
        }
    }

    &__image {
        margin-left: -20px;
        margin-right: 40px;

        @include breakpoint(sm) {
            margin-left: 0;
            margin-right: 0;
        }

        img {
            width: 100%;
        }

        @include theme(br) {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                z-index: 1;
                left: 15%;
                bottom: 0;
                display: block;
                width: 90%;
                height: 90px;
                background-image: url("../assets/media/barangaroo-lines-lilac-full.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: left top;
                transform: translateY(45%);
            }

            @include breakpoint(sm, down) {
                &:before {
                    left: unset;
                    right: 0;
                    transform: translate(0%, 45%);
                    background-position: right top;
                }
            }
        }

        @include theme(wb) {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                z-index: 1;
                left: 60px;
                bottom: -52px;
                display: block;
                width: calc(100% - 60px);
                height: 60px;
                background-image: url("../assets/media/texture-image-text.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }

            @include breakpoint(xs, down) {
                &:before {
                    background-image: url("../assets/media/texture-image-text-mobile.png");
                    left: 0;
                    bottom: -33px;
                    height: 40px;
                    width: 100%;
                    background-size: contain;
                    background-repeat: repeat-x;
                }
            }

            @media screen and (max-width: 520px) {
                &:before {
                    bottom: -30px;
                }
            }

            img {
                z-index: 2;
                position: relative;

                @include breakpoint(sm, down) {
                }
            }
        }
    }

    &__content {
        padding: 25px 80px 0 10px;

        @include breakpoint(sm) {
            padding: 90px 80px 0 75px;
        }

        @include theme(wb) {
            padding: 100px 80px 0 0;

            @include breakpoint(sm) {
                padding: 90px 80px 0 75px;
            }
        }

    }

    &__description {
        font-size: rem(16px);
        line-height: rem(25px);

        @include breakpoint(sm) {
            font-size: rem(18px);
            line-height: rem(30px);
        }
    }
    &__title {
        margin: 0;
        margin-bottom: 10px;
        font-size: rem(32px);
        line-height: rem(44px);

        @include theme(dh) {
            font-size: rem(28px);
            line-height: rem(34px);
        }

        @include breakpoint(sm) {
            font-size: rem(38px);
            line-height: rem(54px);
            margin-bottom: 20px;

            @include theme(dh) {
                font-size: rem(38px);
                line-height: rem(48px);
            }
        }

        @include theme(br) {
            color: black;
        }

        @include theme(wb) {
            color: white;
        }
    }

    &--image-right {
        @include theme(br) {
            .image-text__image {
                &:before {
                    left: unset;
                    right: 3%;
                    background-position: right top;
                }
            }
        }

        @include theme(wb) {
            .image-text__title {
                color: black;
            }
        }
    }

    &--image-right & {
        &__content {
            padding-left: 10px;
            @include breakpoint(sm) {
                padding-top: 70px;
                padding-left: 0;
            }
        }
        &__container {
            &__bg {
                display: none;
            }
        }
        &__image {
            margin-left: 10px;
            margin-right: 0;
        }
    }
}
