.search-footer {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: -1;
        background-color: #f7f7f7;
    }
    &__inner {
        margin: 0 10px;
        padding-top: 60px;
        padding-bottom: 105px;
        @include theme(wb) {
            .cta{
                background-color: black;
                color: white;
                border: 2px solid var(--colors_body_black);
            }
        }
    }

    &__pagination-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        list-style: none;
        margin: 25px 0;
        padding: 0;
    }

    &__button--prev + &__button--next {
        padding-top: 20px;
    }

    &__pagination-bullet {
        background-color: transparent;
        height: 28px;
        width: 28px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $colors_grey_med;
        text-decoration: none;
        font-size: rem(13px);
        line-height: rem(15px);
        font-weight: 700;
        margin: 0 9px;

        &--active {
            background-color: $colors_grey_med;
            color: white;
        }
    }

    @include breakpoint(sm) {
        &:before {
            display: none;
        }
        &__inner {
            margin: 0 40px;
            padding-top: 100px;
            padding-bottom: 140px;
        }

        &__button--prev + &__button--next {
            padding-top: 0;
        }
    }

    @include breakpoint(md) {
        &__inner {
            margin-left: 150px;
        }
    }
}
