.subscribe-form {
    position: relative;
    z-index: 2;
    margin: 50px 0;
    overflow: hidden;

    @include theme(wb) {
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;

            display: block;
            height: 100%;
            width: 100%;

            background-image: url('/assets/media/texture-subscribe.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right;

            @media screen and (max-width: 1440px) {
                display: none;
            }
        }
    }

    .react-form {
        &__form {
            display: flex;
            flex-direction: column;
        }

        &__success-message {
            font-family: var(--font_family_heading);
            display: flex;
            align-items: center;
            margin-top: -10px;
            min-height: 60px;
            font-size: rem(18px);
            line-height: rem(26px);

            .icon {
                margin-right: 15px;
                color: $colors_black_med;
                font-size: rem(28px);
            }

            @include theme(br) {
                @include breakpoint(xs, down) {
                    margin-top: 20px;
                }
            }
        }

        input {
            border: none;
            border-bottom: 1px solid $colors_black_med;
            font-size: rem(15px);
            line-height: rem(48px);
            height: 48px;
            outline: none;
            font-family: var(--font_family_heading);
            color: $colors_black_med;
            background-color: transparent;
            padding: 0;

            @include theme(br) {
                color: black;
                border-bottom: 1px solid black;
            }

            @include theme(wb) {
                font-family: var(--font_family_main);
                color: #fff;
                border-bottom: 1px solid rgba(#fff, 0.7);
            }
        }

        &__field {
            margin: 0;
            padding-top: 12px;

            &.has-value {
                .react-form__field__label {
                    top: -6px;
                    font-size: rem(12px);
                    opacity: 1;
                }
            }

            &.is-valid {
                label {
                    border-color: transparent;
                    color: $colors_black_med;
                }
            }

            &__label {
                position: absolute;
                top: 11px;
                height: auto;
                font-family: var(--font_family_heading);
                font-size: rem(15px);
                transition: all 100ms linear;
                opacity: 0.6;

                @include theme(br) {
                    opacity: 1;
                    color: black;
                }

                @include theme(wb) {
                    font-family: var(--font_family_main);
                    opacity: 0.7;
                    color: white;
                }
            }

            &__errors {
                display: none;
            }
        }

        &__button {
            @extend .cta;
            @extend .cta--dark;
            margin-top: 25px;
            height: 60px;
            align-self: center;

            @include theme(dh) {
                background: var(--colors_highlight) !important;
                color: white !important;
                border-color: var(--colors_highlight) !important;

                &:hover {
                    border-color: $colors_black_med !important;
                }
            }

            @include theme(br) {
                background: var(--colors_highlight);
                color: white;
                border-color: var(--colors_highlight);

                &:hover {
                    color: black !important;
                    border-color: white !important;
                    background-color: white !important;
                }
            }

            @include theme(wb) {
                background: var(--colors_highlight);
                color: black;
                border-color: var(--colors_highlight);

                &:hover {
                    background: #64816B !important;
                    border-color: #64816B !important;
                    color: black !important;
                }
            }
        }

        @include breakpoint(sm) {
            &__form {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            &__form-wrapper {
                width: 100%;
            }

            input {
                font-size: rem(15px);
                line-height: rem(60px);
                height: 50px;
                margin-top: 10px;
            }

            &__field {
                flex: 1 1 auto;
                padding-right: 20px;
                padding-top: 0;

                &.has-value {
                    .react-form__field__label {
                        top: -10px;
                    }
                }

                &__label {
                    top: 12px;
                }
            }

            &__button {
                flex: 0 0 220px;
                margin: 0;
            }
        }
    }

    .rich-text {
        *:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        @include theme(br) {
            letter-spacing: -0.02em;
            color: black;
        }

        @include theme(wb) {
            font-family: var(--font_family_main);
        }
    }

    .subscribe-form__row {
        position: relative;

        @include theme(dh) {
            &:before {
                z-index: -2;
            }

            &:after {
                // facet
                z-index: -1;
                width: 200vw;
            }
        }

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            height: 100%;
            width: 200vw;
        }
    }

    .cta-banner__heading {
        margin-bottom: 0;
    }

    &--cta-banner {
        .cta {
            @include breakpoint(sm) {
                margin-top: 0;
            }

            @include theme(wb) {
                @extend .cta;

                &:hover {
                    @extend .cta--hover-green;
                }
            }
        }

        @include theme(wb) {
            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;

                display: block;
                height: 100%;
                width: 100%;

                background-image: url('/assets/media/texture-cta.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right;

                @media screen and (max-width: 1440px) {
                    display: none;
                }
            }
        }
    }

    &--highlighted {
        .subscribe-form {
            &__row {
                @include theme(dh) {
                    @include facet("light", 0.2, auto, 100%);
                }

                @include theme(wb) {
                    .react-form__button  {
                        background-color: var(--colors_highlight);
                        border-color: var(--colors_highlight);
                        color: black;

                        &:hover {
                            background: #64816B !important;
                            border-color: #64816B !important;
                            color: black !important;
                        }
                    }

                    .rich-text {
                        color: white !important;
                    }

                    .icon-checkmark-circle {
                        color: white !important;
                    }
                }

                &:before {
                    background-color: var(--colors_highlight);

                    @include theme(dh) {
                        background-color: #cdf2ea;
                    }

                    @include theme(br) {
                        background-color: var(--colors_rust);
                    }

                    @include theme(wb) {
                        background-color: var(--colors_body_black);
                    }
                }
            }
        }
    }

    // dark mode sub styles
    &--teal,
    &--dark,
    &--pink,
    &--purple,
    &--sandstone,
    &--paleleaf {
        &,
        .subscribe-form__heading,
        .subscribe-form__description,
        .react-form input {
            color: white;

            @include theme(br) {
                color: black;
                border-color: black;
            }

            @include theme(wb) {
                color: #fff;
                border-color: #fff;
            }
        }

        &.subscribe-form .react-form input,
        &.subscribe-form .react-form__field.is-valid label {
            color: white;
            border-color: white;

            @include theme(br) {
                color: black;
                border-color: black;
            }

            @include theme(wb) {
                color: #fff;
                border-color: #fff;
            }
        }

        &.subscribe-form .react-form__success-message > * {
            color: white;

            @include theme(br) {
                color: black !important;
            }

            @include theme(wb) {
                color: black !important;
            }
        }

        &.subscribe-form .react-form__button {
            @extend .cta--white;

            @include theme(dh) {
                background: white !important;
                color: #2d2d2d !important;
                border-color: white !important;

                &:hover {
                    background: #2d2d2d !important;
                    border-color: #2d2d2d !important;
                    color: white !important;
                }
            }

            @include theme(br) {
                background: var(--colors_highlight) !important;
                color: white !important;
                border-color: var(--colors_highlight);

                &:hover {
                    background: #2d2d2d !important;
                    border-color: #2d2d2d !important;
                    color: white !important;
                }
            }
        }
    }

    &--grey {
        .subscribe-form {
            &__row:before {
                background-color: #eef2e8;
            }
        }

        &.subscribe-form .react-form__button {
            @extend .cta--secondary;
        }
    }

    &--teal {
        .subscribe-form {
            &__row:before {
                background-color: #00758d;
            }
        }
    }

    &--pink {
        @include theme(dh) {
            .subscribe-form {
                &__row:before {
                    background-color: var(--colors_highlight);
                }

                &__row {
                    @include facet("dark", 0.4, 100%);

                    @include breakpoint($breakpoint_desktop_nav) {
                        @include facet("dark", 0.4, auto, 100%);
                    }
                }
            }
        }
    }

    &--purple {
        @include theme(dh) {
            .subscribe-form {
                &__row:before {
                    background-color: #671980;
                }

                &__row {
                    @include facet("dark", 0.4, 100%);

                    @include breakpoint($breakpoint_desktop_nav) {
                        @include facet("dark", 0.4, auto, 100%);
                    }
                }
            }
        }
    }

    &--dark {
        &.subscribe-form .react-form__button {
            @include theme(dh) {
                &:hover {
                    background-color: var(--colors_teal) !important;
                    border-color: var(--colors_teal) !important;
                    color: black !important;
                }
            }
        }

        .subscribe-form {
            &__row:before {
                background-color: #2d2d2d;
            }
        }

        @include theme(dh) {
            .subscribe-form {
                &__row:before {
                    background-color: #1e1e1e;
                }

                &__row {
                    @include facet("light", 0.04, 100%);

                    @include breakpoint($breakpoint_desktop_nav) {
                        @include facet("light", 0.04, auto, 100%);
                    }
                }
            }
        }
    }

    &--harbour {
        .subscribe-form {
            &__row:before {
                background-color: var(--colors_highlight) !important;
            }

            &__heading,
            &__description {
                color: white !important;
            }
        }

        .react-form input,
        .react-form__field label {
            color: white !important;
            border-bottom: 1px solid white !important;
        }

        .react-form__field__label  {
            color: white !important;
        }

        .react-form__field.is-valid label {
            color: white !important;
        }

        .react-form__button {
            background-color: var(--colors_rust) !important;
            border-color: var(--colors_rust) !important;
            color: black;
        }

        .react-form__success-message > * {
            color: white !important;
        }
    }

    &--sandstone {
        .subscribe-form {
            &__row:before {
                background-color: var(--colors_sandstone) !important;
            }
        }
    }

    &--paleleaf {
        .subscribe-form {
            &__row:before {
                background-color: var(--colors_pale_leaf) !important;
            }
        }
    }

    &--green {
        .subscribe-form {
            &__row:before {
                background-color: var(--colors_highlight) !important;
            }
        }
    }

    &--muted {
        .subscribe-form {
            &__row:before {
                background-color: var(--colors_green_light);
            }
        }

        @include theme(dh) {
            .subscribe-form {
                &__row {
                    @include facet("light", 0.2, auto, 100%);
                }
            }
        }

        @include theme(br) {
            .subscribe-form {
                &__row:before {
                    background-color: var(--colors_pale_leaf);
                }
            }
        }

        @include theme(wb) {
            .subscribe-form {
                &__row:before {
                    background-color: var(--colors_highlight) !important;
                }

                &__heading,
                &__description {
                    color: black !important;
                }

                &__row {
                    .react-form {
                        &__button {
                            background: var(--colors_body_black) !important;
                            color: white !important;
                            border-color: var(--colors_body_black) !important;

                            &:hover {
                                background: #333;
                                border-color: #333;
                                color: white;
                            }
                        }

                        input {
                            font-family: var(--font_family_main);
                            color: #000;
                            border-bottom: 1px solid rgba(#000, 0.7);
                        }

                        &__field__label {
                            color: black;
                        }
                    }
                }
            }
        }
    }

    &--yellow {
        .subscribe-form {
            &__row:before {
                background-color: var(--colors_soft_yellow) !important;
            }
        }
    }

    &--paleblue {
        .subscribe-form {
            &__row:before {
                background-color: var(--colors_pale_blue) !important;
            }
        }
    }

    &.subscribe-form--highlighted.subscribe-form--green,
    &.subscribe-form--highlighted.subscribe-form--yellow,
    &.subscribe-form--highlighted.subscribe-form--paleblue {
        .subscribe-form {
            &__heading,
            &__description {
                color: black !important;
            }

            &__row {
                .react-form {
                    &__button {
                        background: var(--colors_body_black) !important;
                        color: white !important;
                        border-color: var(--colors_body_black) !important;

                        &:hover {
                            background: #333;
                            border-color: #333;
                            color: white;
                        }
                    }

                    input {
                        font-family: var(--font_family_main);
                        color: #000;
                        border-bottom: 1px solid rgba(#000, 0.7);
                    }

                    &__field__label {
                        color: black;
                    }
                }
            }
        }

        .rich-text {
            color: black !important;
        }

        .icon-checkmark-circle {
            color: #2d2d2d !important;
        }
    }

    &__copy {
        margin: 30px;
        margin-bottom: 20px;

        .subscribe-form--cta-banner & {
            margin-bottom: 0;
        }

        @include breakpoint(sm) {
            margin: 45px;
            margin-bottom: 35px;
        }
        @include breakpoint(md) {
            margin: 60px;
            margin-bottom: 0px;
        }
        @include breakpoint(lg) {
            margin-bottom: 60px;

            .subscribe-form--cta-banner & {
                margin-bottom: 60px;
            }
        }
    }

    &__form {
        margin: 30px;
        margin-top: 0;
        @include breakpoint(sm) {
            margin: 45px;
            margin-top: 0;
        }
        @include breakpoint(md) {
            margin: 60px;
            margin-top: 0;
        }
        @include breakpoint(lg) {
            margin-left: 0;
            margin-bottom: 0;
        }
        @include breakpoint(xl) {
            margin-right: 0;
        }
    }

    &__heading {
        font-size: rem(24px);
        line-height: rem(34px);
        margin-top: -5px;
        margin-bottom: 5px;
        padding-top: 3px;

        @include theme(dh) {
            font-family: var(--font_family_heading);
            text-transform: uppercase;
            font-size: rem(24px);
            line-height: rem(32px);
        }

        @include theme(br) {
            color: #000;
        }

        @include theme(wb) {
            color: #fff;
        }

        @include breakpoint(md) {
            font-size: rem(28px);
            line-height: rem(40px);

            @include theme(dh) {
                font-size: rem(28px);
                line-height: rem(36px);
            }
        }
    }

    &__description {
        font-size: rem(16px);
        line-height: rem(26px);
        margin-bottom: -30px;
    }

    @include breakpoint(sm) {
        margin: 80px 0;

        @include theme(br) {
            margin: 80px 0 180px;
        }

        &__heading {
            margin-top: 0;
            margin-bottom: 10px;
        }
        &__description {
            margin-bottom: 0px;
        }
    }

    @include breakpoint(sm, down) {
        @include theme(br) {
            margin: 60px 0 90px;
        }
    }
}
