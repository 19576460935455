// Animation/Transition delay staggering
@mixin stagger($type, $items, $amount, $offset: 0) {
    @for $i from 1 through $items {
        $adjustedIndex: ($i - 1);
        $delay: unquote($offset + ($adjustedIndex * $amount) + "s");
        &:nth-child(#{$i}) {
            #{$type}-delay: $delay;
        }
    }
}
@mixin staggerChildren($type, $items, $amount, $offset: 0) {
    @for $i from 1 through $items {
        $adjustedIndex: ($i - 1);
        $delay: unquote($offset + ($i * $amount) + "s");
        &:nth-child(#{$i}) {
            > * {
                #{$type}-delay: $delay;
            }
        }
    }
}

// Pseudo ::before stagger
@mixin staggerBefore($el, $type, $items, $amount, $offset: 0) {
    @for $i from 1 through $items {
        $adjustedIndex: ($i - 1);
        $delay: unquote($offset + ($i * $amount) + "s");
        &:nth-child(#{$i}) {
            #{$el} {
                &::before {
                    #{$type}-delay: $delay;
                }
            }
        }
    }
}

@mixin outline {
    outline-color: rgb(77, 144, 254);
    outline-offset: -2px;
    outline-style: auto;
    outline-width: 5px;
}

/*----- PX to REM  -----*/
@mixin rem($key, $px) {
    #{$key}: $px;
    #{$key}: $px/$rem-ratio;
}

/*----- PX to EM  -----*/
@function em($pixels, $context: 16) {
    @return $pixels/$context;
}

@function rem($pixels) {
    @return $pixels/$rem-ratio;
}

/*----- Touch  -----*/
@mixin is-touch {
    .touch & {
        @content;
    }
}

/*----- Touch  -----*/
@mixin is-no-touch {
    .no-touch & {
        @content;
    }
}

/*----- Non touch hover -----*/
@mixin hover {
    .no-touch & {
        &:hover {
            @content;
        }
    }
}

/*----- Has HTML class  -----*/
@mixin isIE11 {
    .IE-11 & {
        @content;
    }
}

/*---- Placeholder Color ----*/
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

/*---- Inset Overlay ----*/
@mixin insetOverlay($opacity, $color: black) {
    box-shadow: inset 0 0 0 999em rgba($color, $opacity); // overlay
}

/*---- Helper for Parallax Debugging ----*/

.center-marker-inside {
    position: absolute;
    right: 0px;
    background-color: orange;
    border-top: 1px solid red;
    display: none;
    top: 50%;
    height: 4px;
    width: 10px;
    z-index: 999;
}

body:after {
    content: "";
    position: fixed;
    right: 10px;
    background-color: violet;
    border-top: 1px solid purple;
    display: none;
    top: 50%;
    height: 4px;
    width: 10px;
    z-index: 999;
}

body:before {
    content: "XS";
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    background-color: black;
    color: white;
    z-index: 10000;
    font-size: rem(13px);
    line-height: 13px;
    padding: 3px;
    @include breakpoint(sm, only) {
        content: "SM";
    }
    @include breakpoint(md, only) {
        content: "MD";
    }
    @include breakpoint(lg, only) {
        content: "LG";
    }
    @include breakpoint(xl, only) {
        content: "XL";
    }
}

body.debug:before,
body.debug:after {
    display: block;
}

body.debug [class^="center-marker-"] {
    display: block;
}
