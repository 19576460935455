.content-page {
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        background: $content_page_bg;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .simple-header + * {
        margin-top: 30px;

        @include theme(br) {
            margin-top: 0;

            @include breakpoint(sm, down) {
                margin-top: 20px;
            }
        }
    }

    .simple-header + .rich-text-component,
    .rich-text-component:first-child {
        margin-top: 30px;

        @include breakpoint(sm) {
            margin-top: 30px;
        }

        @include theme(br) {
            margin-top: 0;
        }
    }

    .simple-header__background {
        background-color: transparent !important;
    }

    .tile__content {
        background: $content_page_bg;
    }

    // form component styling
    .form-builder {
        .react-form__field input,
        .react-form__field textarea,
        .react-form__field select {
            background-color: white;
        }

        .react-form__field--checkbox .react-form__field__checkbox-indicator,
        .react-form__field--checkbox .react-form__field__radio-indicator,
        .react-form__field--radio .react-form__field__checkbox-indicator,
        .react-form__field--radio .react-form__field__radio-indicator {
            border-color: #8f8f8f;

            &:before {
                color: #8f8f8f;
            }
        }

        .react-form__field--radio .react-form__field__radio-indicator:before {
            color: transparent;
        }

        @include theme(tr) {
            .react-form__field--checkbox.is-checked
                .react-form__field__checkbox-indicator,
            .react-form__field--checkbox.is-checked
                .react-form__field__radio-indicator,
            .react-form__field--checkbox
                input:checked
                ~ .react-form__field__checkbox-indicator,
            .react-form__field--checkbox
                input:checked
                ~ .react-form__field__radio-indicator,
            .react-form__field--radio.is-checked
                .react-form__field__checkbox-indicator,
            .react-form__field--radio.is-checked
                .react-form__field__radio-indicator,
            .react-form__field--radio
                input:checked
                ~ .react-form__field__checkbox-indicator,
            .react-form__field--radio
                input:checked
                ~ .react-form__field__radio-indicator {
                border-color: var(--colors_teal);
                background-color: var(--colors_teal);

                &:before {
                    color: white;
                }
            }

            .cta {
                background-color: var(--colors_teal);
                color: white;
                border-color: var(--colors_teal);

                &:hover {
                    border-color: $colors_grey_dark;
                }
            }
        }
    }

    @include breakpoint(sm) {
        .curated-content__title,
        .accordion__title,
        .form-builder__title,
        .gallery__heading,
        .image-text__title {
            font-size: rem(28px);
            line-height: (36px);
        }
    }

    @include breakpoint($breakpoint_desktop_nav) {
        background: none;
        padding-bottom: 1px;

        &:before {
            top: 30px;
            left: 30px;
            right: 30px;
            bottom: 30px;
        }

        .curated-content__title,
        .accordion__title,
        .form-builder__title,
        .gallery__heading,
        .image-text__title {
            font-size: rem(38px);
            line-height: rem(48px);
        }

        .simple-header__content {
            padding-left: 0;
        }

        .container,
        .container-fluid {
            padding-left: $layout_nav_desktop_width_smaller + 60px;
        }
    }

    @include breakpoint(lg) {
        .container,
        .container-fluid {
            padding-left: $layout_nav_desktop_width_smaller + 60px;
        }
    }

    @include breakpoint(xl) {
        .container,
        .container-fluid {
            padding-left: $layout_nav_desktop_width + 90px;
        }
    }

    @include theme(br) {
        margin-bottom: 100px;

        @include breakpoint(sm) {
            margin-bottom: 60px;
        }

        @include breakpoint(md) {
            margin-bottom: 150px;
        }
    }
}
