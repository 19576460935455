.tabs {
    display: inline-flex;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(207, 207, 207);
    border-radius: 4px;
    &__tab {
        font-family: var(--font_family_main);
        letter-spacing: 6px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        cursor: pointer;
        padding: 16px 30px;
        &:not(:first-child) {
            border-left-width: 1px;
            border-left-style: solid;
            border-left-color: rgb(207, 207, 207);
        }
        &.selected {
            background-color: var(--colors_maroon);
            color: white;

            @include theme(dh) {
                background-color: #9be5d5;
                color: $colors_black_med;
            }

            @include theme(br) {
                background-color: var(--colors_rust);
            }

            @include theme(wb) {
                background-color: black;
            }
        }
    }
}
