.tile-listing {
    position: relative;
    padding: 55px 0;
    background: var(--colors_green_light);
    overflow: hidden;

    @include theme(dh) {
        @include facet("dark", 0.2, 100%, auto);

        @include breakpoint($breakpoint_desktop_nav) {
            @include facet("dark", 0.2, auto, 100%);
        }
    }

    @include theme(br) {
        padding: 55px 0 95px;
        background: var(--colors_sandstone);

        &:before {
            content: "";
            position: absolute;
            z-index: 1;
            right: 0;
            display: block;
            width: 40%;
            height: 115px;
            background-image: url("../assets/media/barangaroo-lines-rust-right.png");
            background-repeat: no-repeat;
            background-position: right top;
            background-size: contain;

            @include breakpoint(xs, down) {
                top: 90px;
                height: 80px;
                width: 74%;
            }
        }
    }

    @include theme(wb) {
        background: var(--colors_pale_blue);

        &:before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            display: block;
            width: 450px;
            height: 450px;
            background-image: url("../assets/media/texture-featured-tiles.png");
            background-repeat: no-repeat;
            background-position: right top;
            background-size: contain;

            @include breakpoint(xs, down) {
                top: 113px;
                height: 250px;
                width: 250px;
            }
        }
    }

    @include breakpoint(md) {
        padding: 135px 0;

        @include theme(br) {
            padding: 135px 0 185px;
        }
    }

    &__heading {
        margin-top: 0;
        font-size: rem(28px);
        line-height: rem(44px);

        @include breakpoint($breakpoint_desktop_nav) {
            font-size: rem(42px);
            line-height: rem(66px);

            @include theme(dh) {
                font-size: rem(48px) !important;
                line-height: rem(52px) !important;
            }
        }

        @include breakpoint(xl) {
            font-size: rem(52px);
            line-height: rem(82px);
        }

        @include theme(br) {
            letter-spacing: -0.02em;

            @include breakpoint(xs, down) {
                margin-bottom: 55px;
            }
        }
    }

    &__tiles {
        margin-bottom: -$layout_page_padding;

        & > *:nth-child(even) {
            margin-top: 50px;
        }

        @include theme(br) {
            z-index: 2;
            position: relative;
        }

        @include theme(wb) {
            z-index: 2;
            position: relative;
            padding-top: 10px;

            @include breakpoint(xs, down) {
                padding-top: 20px;
            }
        }
    }

    .tile {
        min-height: 0;
        margin-bottom: $layout_page_padding;
    }
}
