.map-detail-card {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    color: $colors_grey_dark;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &__close.cta {
        position: absolute;
        top: 15px;
        left: 20px;
        background-color: transparentize($colors_body_black, 0.35);
        border: none;
        font-size: rem(10px);
        padding: 7px 0px;
        min-width: 100px;
        z-index: 10;
        color: white;

        @include theme(dh) {
            padding: 10px 20px;
            font-size: rem(12px);
            line-height: rem(14px);
        }

        @include theme(br) {
            color: white;
            background-color: rgba(0, 0, 0, 0.44);
            border-radius: 19.5px;
        }

        &:hover {
            background-color: transparentize(
                $colors_body_black,
                0.5
            ) !important;
            color: white;
        }

        i {
            font-size: 1.2em;
            margin-left: 0;
            margin-right: 5px;
        }
    }

    &__inner {
        position: relative;
    }

    &__image-wrapper {
        position: relative;
        line-height: 0;
        top: 0;
        @include aspect-ratio(3, 2);
    }
    &__logo {
        position: absolute;
        width: 100px;
        height: 100px;
        bottom: -50px;
        right: 30px;
        z-index: 1;
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid $colors_grey_light;
        @include breakpoint($breakpoint_desktop_nav) {
            bottom: -30px;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;
    }

    &__header {
        position: relative;
        padding: 30px;
        @include breakpoint($breakpoint_desktop_nav) {
            padding: 45px 30px;
        }
    }

    &__type {
        text-transform: uppercase;
        letter-spacing: 5px;
        font: 600 #{rem(12px)}/#{rem(15px)} var(--font_family_main);
        opacity: 0.65;
        margin-bottom: 8px;

        @include theme(br) {
            text-transform: none;
            font: 600 #{rem(14px)}/#{rem(15px)}
                var(--font_family_heading_semibold);
            letter-spacing: -0.02em;
        }
    }
    &__title {
        text-transform: uppercase;
        letter-spacing: -0.6px;
        font: #{rem(26px)}/#{rem(32px)} var(--font_family_heading);
        margin: 0;
        margin-bottom: 8px;

        @include theme(br) {
            text-transform: none;
            font-family: var(--font_family_heading_bold);
            font-weight: 700;
            line-height: 36px;
            letter-spacing: -0.02em;
        }
    }
    &__short-description {
        font: #{rem(16px)}/#{rem(26px)} var(--font_family_main);
        color: $colors_grey_med;
        margin-bottom: 25px;

        @include theme(br) {
            text-transform: none;
            font-family: var(--font_family_heading_regular);
            font-size: rem(16px);
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.02em;
        }
    }

    &__cta.cta {
        font-size: rem(12px);
        color: $colors_grey_med_dark;
        @include breakpoint($breakpoint_desktop_nav) {
            font-size: rem(10px);
        }
        @include breakpoint(sm, down) {
            padding: 15px 20px;
        }
    }

    &__cta.cta-directions {
        margin-top: 20px;
        @include breakpoint(sm) {
            margin-top: 0;
        }

        @include theme(br) {
            border-color: var(--colors_highlight);
            color: var(--colors_highlight);

            &:hover {
                border-color: $colors_black_med;
                color: white;
            }
        }
    }

    &__divider {
        border: none;
        position: relative;
        font-size: 0;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 1px;
            width: 100%;
            background-color: #d8d8d8;
        }
    }

    &__infos {
        position: relative;
        padding: 27px 30px 25px;
    }

    &__info-block {
        position: relative;
        font: #{rem(14px)}/#{rem(22px)} var(--font_family_main);
        letter-spacing: -0.3px;
        margin-bottom: 15px;

        .label {
            display: inline-block;
            opacity: 0.65;
            text-transform: uppercase;
            letter-spacing: 4.3px;
            font: 700 #{rem(10px)}/#{rem(14px)} var(--font_family_main);
            margin-bottom: 7px;

            @include theme(dh) {
                @extend .eyebrow;
                opacity: 1;
                color: #666;
            }

            @include theme(br) {
                opacity: 1;
                text-transform: none;
                letter-spacing: -0.02em;
                font: #{rem(14px)}/#{rem(18px)} var(--font_family_heading_semibold);
                color: black;
            }
        }

        p {
            padding: 0 0 20px;
            margin: 0;
        }

        a {
            text-decoration: none;
            color: $colors_grey_dark;
        }

        .time {
            font-weight: 600;
            margin-bottom: 6px;

            @include theme(br) {
                font-family: var(--font_family_heading_bold);
            }
        }

        .address {
            @include theme(br) {
                font-family: var(--font_family_heading_regul ar);
            }
        }

        &--market-opening {
            display: block;
            margin-top: -40px;
            margin-bottom: 10px;
        }

        &--market-toggle {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            padding: 30px 0;
            &:last-child {
                padding-bottom: 0;
            }
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 1px;
                width: 100%;
                background-color: #d8d8d8;
            }
            hr {
                flex-basis: 100%;
            }
            input {
                position: relative;
                flex-basis: 40px;
                margin-right: 20px;
                outline: none;
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    border-radius: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                &:before {
                    height: 20px;
                    width: 20px;
                    border: 1px solid var(--colors_teal);

                    @include theme(br) {
                        border: 1px solid var(--colors_highlight);
                    }
                }
                &:checked:after {
                    height: 14px;
                    width: 14px;
                    background-color: var(--colors_teal);

                    @include theme(br) {
                        background-color: var(--colors_highlight);
                    }
                }
            }
            label {
                flex-basis: calc(100% - 60px);
                padding-right: 40px;
            }
            .title {
                font: #{rem(16px)}/#{rem(24px)} var(--font_family_heading);

                @include theme(br) {
                    font-family: var(--font_family_heading_bold);
                }
            }
        }
    }
    &__scroll-btn {
        @extend .icon;
        @extend .icon-arrow-right-thick;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 58px;
        height: 58px;
        position: absolute;
        right: 30px;
        top: calc(50% + 30px);
        z-index: 1;
        border-radius: 100%;
        background-color: var(--colors_highlight);
        cursor: pointer;
        &:before {
            font-size: rem(12px);
        }
        @include breakpoint(sm, down) {
            display: none !important;
        }
    }

    &__related {
        position: relative;
        .related-content {
            &__heading {
                margin-left: 30px;
                @include theme(br) {
                    position: relative;
                    z-index: 2;
                    margin-bottom: 45px;
                }
            }
            @include theme(br) {
                &:before {
                    width: 65%;
                    right: -5%;
                    top: 82px;
                    background-size: contain;
                }
            }
        }
        .tile:first-child {
            margin-left: 20px;
            @include breakpoint($breakpoint_desktop_nav) {
                margin-left: 50px;
            }
        }
    }

    // Desktop
    @include breakpoint($breakpoint_desktop_nav) {
        width: $map_desktop_sidebar_width;
    }
}
