.search-results {
    &__list {
        position: relative;
        background-color: white;
        list-style: none;
        margin: 0;
        padding: 0 10px;
        li {
            border-bottom: 1px solid #d6d6d6;

            &:last-child {
                border-bottom: none;
            }
        }
    }
    @include breakpoint(sm) {
        &__list {
            padding: 0 60px;
            margin: 0 40px 0 40px;
            li {
                &:first-child {
                    padding-top: 10px;
                }
                &:last-child {
                    padding-bottom: 30px;
                }
            }
        }
    }

    @include breakpoint(md) {
        &__list {
            margin: 0 40px 0 150px;
        }
    }
}
