.cta {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 100%;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    background: var(--colors_highlight);
    color: var(--colors_body_black);
    border: 2px solid var(--colors_highlight);
    border-radius: 100px;
    @include rem(font-size, 13px);
    line-height: 1.5em;
    font-family: var(--font_family_main);
    font-weight: bold;
    letter-spacing: var(--font_letterspace_wide);
    transition: all 100ms linear;

    @include theme(dh) {
        border-radius: 3px;
        @include rem(font-size, 12px);
        font-family: var(--font_family_heading_extrabold);
        font-weight: normal;
        letter-spacing: 0.125em;
        color: white;

        @include breakpoint($breakpoint_desktop_nav) {
            @include rem(font-size, 16px);
        }

        &--dark {
            background: white;
            color: $colors_black_med;
            border-color: white;

            .icon {
                color: #eb054e;
            }

            &:hover {
                background: $colors_black_med;
                color: white;

                .icon {
                    color: white;
                }
            }
        }

        &--white {
            color: $colors_black_med;
        }

        &--secondary {
            color: var(--colors_highlight);
            border-color: var(--colors_highlight);

            &:hover {
                background: var(--colors_highlight) !important;
                background-color: var(--colors_highlight) !important;
                border-color: var(--colors_highlight) !important;
                color: white;

                .icon {
                    color: white;
                }
            }
        }

        &--small {
            font-family: var(--font_family_heading_bold);
            font-size: rem(12px);
            line-height: rem(14px);
        }
    }

    @include theme(br) {
        font-family: var(--font_family_heading_semibold);
        color: white;
        border-radius: 4px;
        text-transform: none;
        letter-spacing: -0.02em;
        font-size: rem(14px);

        &--big {
            background-color: var(--colors_highlight);
            border-color: var(--colors_highlight);
        }

        &--dark {
            background-color: var(--colors_highlight);
            border-color: var(--colors_highlight);
        }
    }

    @include theme(wb) {
        font-size: rem(14px);
        font-weight: 700;
        letter-spacing: -0.14px;
        line-height: 18px;
        text-transform: none;

        &--dark {
            background-color: var(--colors_body_black);
            border-color: var(--colors_body_black);

            &:hover {
                @extend .cta--hover-grey;
            }
        }

        &--secondary {
            background-color: transparent;
            border-color: var(--colors_body_black);
        }

        &--black {
            &:hover {
                @extend .cta--hover-grey;
            }
        }
    }

    @include breakpoint($breakpoint_desktop_nav) {
        padding: 16px 40px;
    }

    &:hover {
        @extend .cta--hover-black;
    }

    &:last-child {
        margin-right: 0;
    }

    .icon,
    span {
        @include rem(font-size, 8px);
    }

    .icon {
        margin-left: 5px;
    }

    &--inline {
        display: inline-flex;
        min-width: 140px;
        width: auto;

        & + & {
            margin-left: 20px;
        }
    }

    &--small {
        padding: 10px 20px;
        border-width: 2px;
        @include rem(font-size, 12px);
    }

    &--big {
        padding: 20px 30px;
        border-width: 2px;
    }

    &--black {
        background: black;
        border-color: black;
        color: white;

        &:hover {
            @extend .cta--hover-white;
        }
    }
    &--white {
        background: white;
        border-color: white;
        color: black !important;
        &:hover {
            @extend .cta--hover-black;
            color: white !important;
        }
    }

    &--dark {
        background: $colors_black_med;
        border-color: $colors_black_med;
        color: white;
        &:hover {
            @extend .cta--hover-black;
        }
    }

    &--teal {
        background: var(--colors_teal);
        border-color: white;
        color: white;
    }

    &--secondary {
        background: none !important;
        border-color: $colors_black_med;
        color: $colors_black_med;

        &:hover {
            color: white;
            background: $colors_black_med !important;
        }

        &.cta--teal {
            border-color: var(--colors_teal);
            color: var(--colors_teal);
            &:hover {
                @extend .cta--hover-black;
            }
        }
        &.cta--black {
            border-color: $colors_black_med;
            color: $colors_black_med;
            &:hover {
                @extend .cta--hover-black;
            }
        }
    }

    &--secondary-black {
        background: none !important;
        border-color: white;
        color: white;

        &:hover {
            @extend .cta--hover-black;
        }

        &.cta--teal {
            border-color: var(--colors_teal);
            color: var(--colors_teal);
            &:hover {
                @extend .cta--hover-black;
            }
        }
        &.cta--black {
            border-color: $colors_black_med;
            color: $colors_black_med;
            &:hover {
                @extend .cta--hover-black;
            }
        }
    }

    &--hover-black {
        background: $colors_grey_dark !important;
        border-color: $colors_grey_dark;
        color: white !important;
    }

    &--hover-white {
        background: white !important;
        border-color: white;
        color: $colors_grey_dark !important;
    }

    &--hover-grey {
        background: #333 !important;
        border-color: #333 !important;
        color: white !important;
    }

    &--hover-green {
        background: #64816B !important;
        border-color: #64816B !important;
        color: black !important;

        .icon {
            color: black;
        }
    }

    &--hover-black-outline {
        background: #ffffff33 !important;
        border-color: var(--colors_heading_black);
        color: black !important;
    }

    &--hover-white-outline {
        background: #ffffff33 !important;
        border-color: white !important;
        color: white;

        .icon {
            color: white;
        }
    }
}

.primary-cta-component {
    margin: 50px 0;

    @include breakpoint(sm) {
        margin: 80px 0;
    }
}

.cta-secondary {
    background-color: transparent;
    border-color: black;
    color: black;
}
