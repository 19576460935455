.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding: 110px 0 $layout_page_padding;

    &:before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#2d2d2d, 0.95);
        @extend .animated;
        @extend .fadeIn;
        animation-duration: 500ms;

        @include theme(br) {
            background: rgba(45, 45, 45, 0.95);
        }
    }

    &__close {
        @extend .icon;
        @extend .icon-close;
        position: absolute !important;
        top: 0;
        right: 0;
        color: white;
        font-size: rem(28px);
        background: none;
        border: none;
        justify-self: flex-end;
        height: 80px;
        width: 70px;
        padding: 0;
        cursor: pointer;

        @include breakpoint(sm) {
            height: 110px;
            width: 110px;
        }

        @include theme(br) {
            color: white !important;
        }
    }

    &__contents {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        @extend .animated;
        @extend .fadeIn;
        animation-delay: 500ms;

        & > * {
            width: 100%;
        }
    }

    &__container {
        padding-left: $layout_page_padding;
    }

    &.lightbox--acknowledgement-of-country {
        padding: 20px 0;

        &:before {
            background-color: rgba(0, 0, 0, 0.92);
        }

        .lightbox_container {
            padding-left: 20px;
        }

        .lightbox__close {
            color: white !important;
            z-index: 1;
        }

        .acknowledgement-of-country {
            display: block;
        }

        @include breakpoint(sm) {
            padding: 110px 0 $layout_page_padding;
        }

        @include theme(br) {
            .lightbox__close {
                color: black !important;
            }

            &:before {
                background-color: rgba(243, 214, 160, 0.94);
            }
        }

        @include theme(dh) {
            .lightbox__close {
                color: black !important;
            }

            &:before {
                background-color: rgba(155, 229, 213, 0.97);
            }
        }

        @include theme(wb) {
            &:before {
                background-color: rgba(black, 0.85);
            }
        }
    }

    @include theme(br) {
        &.lightbox--live-search-container {
            &:before {
                background-color: var(--colors_highlight);
            }
            .live-search__button {
                &.live-search__button--clear i {
                    color: white;
                }
                &.live-search__button--search i {
                    color: var(--colors_sandstone);
                }
            }
        }
    }

      @include theme(wb) {
        &.lightbox--live-search-container {
            &:before {
                background-color: black;
            }
            .live-search__button {
                &.live-search__button--clear i {
                    color: var(--colors_highlight);
                }
                &.live-search__button--search i {
                    color: var(--colors_highlight);
                }
            }
        }
    }
}
