.appropriate-use {
    padding-top: 30px;
    &__title {
        padding-bottom: 30px;
    }
    &__list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
    }

    &__item {
        flex: 0 0 100%;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 20px;

        @include breakpoint(lg) {
            flex: 0 0 50%;
            &:nth-child(even) {
                .appropriate-use__label {
                    margin-right: $layout_page_padding;
                }
            }

            &:nth-child(odd) {
                .appropriate-use__label {
                    margin-right: 2 * $layout_page_padding;
                }
            }
        }
    }

    &__icon {
        flex: 0 0 46px;
        background-color: var(--colors_highlight);
        height: 46px;
        width: 46px;
        border-radius: 100%;
        display: inline-flex !important;
        justify-content: center;
        align-items: center;
        margin-right: 30px;

        @include theme(wb) {
            background-color: var(--colors_rust);
        }

        svg {
            max-width: 24px;
            max-height: 24px;

            @include theme(dh) {
                &,
                * {
                    fill: white;
                }
            }

            @include theme(br) {
                &,
                * {
                    fill: white;
                }
            }

            @include theme(wb) {
                &,
                * {
                    fill: white;
                }
            }
        }
    }

    &__label {
        font-size: rem(18px);
        line-height: rem(26px);
        color: $colors_grey_med;
    }
}
