.date-tile {
    position: relative;
    z-index: 2;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    min-width: 70px;
    background: var(--colors_teal);
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    @include rem(font-size, 12px);
    @include rem(line-height, 12px);

    @include theme(dh) {
        background: #9be5d5;
        color: black;
        font-family: var(--font_family_heading_bold);
        font-weight: normal;
    }

    @include theme(br) {
        background: #1e5bc0;
        color: white;
        font-family: var(--font_family_heading_regular);
        font-weight: normal;
        text-transform: none;
        letter-spacing: -0.02em;
    }

    @include theme(wb) {
        background: var(--colors_rust);
    }

    .dash {
        display: inline-block;
        width: rem(15px);
        border-top: 1px solid;
        padding-bottom: 5px;
        margin-left: -14px;
    }

    &__day {
        @include rem(font-size, 18px);
        @include rem(line-height, 18px);
        margin-bottom: 3px;
        font-weight: normal;
        width: 70px;
    }

    &__month {
        @include theme(br) {
            font-family: var(--font_family_heading_semibold);
        }
    }

    &__all-year {
        font-size: rem(12px);
        line-height: rem(17px);
        width: 70px;

        @include theme(br) {
            font-family: var(--font_family_heading_semibold);
        }
    }

    &__date:nth-of-type(3) {
        margin-left: -14px;
        margin-right: 2px;
    }
}
