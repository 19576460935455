.trending-content {
    flex: 0 1 auto;
    background: white;
    text-align: center;
    @include rem(font-size, 14px);
    overflow: auto;
    box-shadow: inset 0 0 20px 0 rgba(black, 0.12);

    &__inner {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 20px;
    }

    &__items {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        & > * {
            flex: 0 1 auto;
        }
    }

    &__heading,
    &__items > * {
        backface-visibility: hidden;
        animation-fill-mode: both;
        animation-name: fadeInLeft;
        animation-duration: 1000ms;
        @include stagger("animation", 10, 0.1, 1);
    }

    &__heading {
        color: $colors_grey_med_dark;
        font-size: rem(8px);
        line-height: rem(10px);
        font-weight: normal;
        text-align: left;
        padding-right: 10px;

        @include theme(dh) {
            font-family: var(--font_family_heading_extrabold);
            letter-spacing: 0.05em;
            font-size: rem(8px);
            line-height: rem(10px);
        }

        @include theme(br) {
            text-transform: none;
            font-weight: 600;
            letter-spacing: -0.02em;
            font-family: var(--font_family_heading_semibold);
            color: black;
            font-size: rem(16px);
            line-height: rem(18px);

            @include breakpoint(xs, down) {
                font-size: rem(12px);
                line-height: rem(14px);
            }
        }

        @include theme(wb) {
            font-family: var(--font_family_heading);
            font-size: rem(16px);
            line-height: 20px;
            letter-spacing: -0.32px;
            font-weight: 500;
            color: black;
        }
    }

    @include breakpoint(md) {
        min-height: 100%;
        flex: 0 0 200px;
        overflow: auto;
        box-shadow: none;

        &__heading,
        &__items > * {
            animation-name: none;
        }

        &__inner {
            display: block;
            padding: 30px;
            padding-right: 0;
        }

        &__items {
            display: block;
        }

        &__heading {
            text-align: center;
            padding-right: 0;
            font-size: rem(14px);
            line-height: rem(25px);

            @include theme(dh) {
                font-size: rem(18px);
                line-height: rem(25px);
            }
        }
    }
}
