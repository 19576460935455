.live-search-container {
    display: none;

    @include theme(dh) {
        color: #fff;
    }

    .lightbox & {
        display: block;
    }

    &__mobile-button {
        @extend .animated;
        @extend .fadeIn;
        cursor: pointer;
        z-index: 90;
        position: fixed;
        width: 75px;
        height: 75px;
        bottom: 10px;
        right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: #000;
        color: #fff;
        font-size: rem(9px);
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.3px;
        box-shadow: 0px 20px 40px rgba($colors_body_black, 0.2);
        animation-delay: 1s;
        transition: all 250ms ease-in;

        i {
            font-size: rem(23px);
            margin-bottom: 5px;

            color: #fff;
        }

        @include theme(dh) {
            i {
                color: #000;
            }
                color: #000;

                background-color: #9be5d5;
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
            color: #fff;
            background-color: var(--colors_highlight);
        }

        @include theme(wb) {
            text-transform: capitalize;
            font-size: 10px;
            letter-spacing: -0.1px;
        }
    }

    @include breakpoint(md) {
        &__mobile-button {
            display: none;
        }
    }
}

.newsletter-toaster--show:not(.newsletter-toaster--hide)
    ~ .live-search-container__mobile-button {
    bottom: 130px;
}

.lightbox--live-search-container {
    .lightbox__contents {
        justify-content: flex-start;
    }
    @include breakpoint(sm, down) {
        overflow: auto;

        @include theme(dh) {
            @include facet("dark", 0.4, 100%);
        }

        &:before {
            animation-name: bubbleGrow;
            background-color: #000;
            position: fixed;
            width: 75px;
            height: 75px;
            border-radius: 100%;
            top: auto;
            left: auto;
            bottom: 10px;
            right: 10px;

            @include theme(br) {
                background-color: var(--colors_highlight);
            }

            @include theme(dh) {
                background-color: var(--colors_green_light);
            }
        }

        .lightbox__close {
            color: #fff;

            @include theme(br) {
                color: $colors_grey_dark;
            }

            @include theme(dh) {
                color: #000;
            }
        }
    }
    @include breakpoint(md) {
        &:before {
            background-color: rgba($colors_grey_dark, 0.98);
        }
    }
}

.live-search {
    color: $colors_grey_dark;
    &__input {
        background: none;
        border: none;
        outline: none;
        position: relative;
        width: calc(100% - 70px);
        font-family: var(--font_family_heading);
        font-size: rem(25px);
        line-height: rem(44px);
        color: #fff;

        &::placeholder {
            color: rgba($colors_grey_med, 0.55);
        }

        @include theme(dh) {
            color: black;
            font-family: var(--font_family_heading_bold);

            &::placeholder {
                color: rgba($colors_grey_med, 0.55);

                @include breakpoint(md) {
                    color: rgba(white, 0.55);
                }
            }

            @include breakpoint(md) {
                color: white;
            }
        }

        @include theme(br) {
            color: #fff;
            font-family: var(--font_family_heading_regular);
            letter-spacing: -0.02em;

            &::placeholder {
                color: rgba(white, 0.55);
            }
        }
    }

    &__heading {
        font-size: rem(30px);
        line-height: rem(44px);
        margin: 0;
        margin-bottom: 32px;
        padding-right: 100px;
        color: #fff;

        @include theme(dh) {
            font-family: var(--font_family_heading_regular);
            color: #000;

            @include breakpoint(md, up) {
                color: #fff;
            }
        }

        @include theme(br) {
            font-family: var(--font_family_heading_semibold);
            color: #fff;
        }
    }

    &__form {
        position: relative;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        margin-bottom: 52px;
        &:before {
            content: "";
            position: absolute;
            height: 4px;
            width: 100%;
            bottom: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.2);

            @include theme(dh) {
                background-color: rgb(138, 169, 162);
            }

            @include theme(br) {
                background-color: rgba(white, 0.2);
            }
        }
    }

    &__button {
        background: none;
        border: none;
        outline: none;
        width: 35px;
        color: #fff;

        @include theme(dh) {
            color: #000;
        }

        @include theme(br) {
            color: var(--colors_sandstone);
        }

        &.live-search__button--search {
            text-align: left;
            i {
                font-size: rem(25px);

                @include theme(br) {
                    color: var(--colors_highlight);
                }
            }
        }
        &.live-search__button--clear {
            text-align: right;
            cursor: pointer;
            i {
                @include theme(br) {
                    color: #000;
                    font-size: rem(25px);

                    @include breakpoint(xs, down) {
                        font-size: rem(15px);
                    }
                }
            }
        }
        &--hidden {
            opacity: 0 !important;
            cursor: default;
        }
    }

    &__results {
        margin: 0;
        padding: 0;
        padding-bottom: 140px;
        list-style: none;
        color: #fff;

        @include theme(dh) {
            color: #000;
        }
    }

    &__result-item {
        padding: 0;

        &--is-state {
            text-transform: uppercase;
            letter-spacing: 4.3px;
            font-weight: 700;
            font-size: rem(12px);
            line-height: rem(15px);
            margin-bottom: 16px;

            @include theme(dh) {
                font-family: var(--font_family_heading_medium);
                font-weight: normal;
                letter-spacing: 2px;
            }

            @include theme(br) {
                color: #fff;
                text-transform: none;
                letter-spacing: -0.02em;
            }
        }

        &--is-result a {
            display: inline-block;
            text-decoration: none;
            font-size: rem(16px);
            line-height: rem(19px);
            margin-bottom: 17px;
            color: #fff;

            @include theme(dh) {
                color: #000;
            }

            @include theme(br) {
                color: #fff;
            }

            @include breakpoint(md) {
                @include theme(dh) {
                    color: #fff;
                }
            }
        }
    }

    @include breakpoint(md) {
        color: #fff;

        &__heading {
            color: #fff;
            font-size: rem(30px);
            line-height: rem(44px);
            margin-bottom: 12px;

            @include theme(br) {
                color: #fff;
            }
        }

        &__input {
            color: #fff;
            font-size: rem(25px);
            line-height: rem(44px);
        }

        &__form {
            margin-bottom: 40px;
            margin-right: 110px;
            &:before {
                background-color: rgba(white, 0.2);
            }
        }

        &__button {
            color: var(--colors_highlight);
            width: 50px;

            @include theme(dh) {
                color: #99e4d5;
            }

            i {
                font-size: rem(25px);

                @include theme(br) {
                    color: var(--colors_sandstone);
                }
            }
            &--clear {
                opacity: 0.5;
                color: #fff;

                @include theme(br) {
                    opacity: 1;
                    color: #fff;
                }
            }
        }

        &__result-item {
            margin-bottom: 10px;
            &--is-state {
                color: var(--colors_highlight);
                margin-bottom: 15px;

                @include theme(dh) {
                    color: #99e4d5;
                }
            }

            &--is-result a {
                color: #fff;
                margin-bottom: 7px;
            }
        }
    }
}

.bubbleGrow {
    animation-name: bubbleGrow;
    transform-origin: center bottom;
}

@keyframes bubbleGrow {
    from {
        opacity: 0.5;
        transform: scale3d(1, 1, 1);
    }

    to {
        opacity: 1;
        transform: scale3d(50, 50, 1);
    }
}
