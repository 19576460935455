.acknowledgement-of-country {
    display: none;

    .container-fluid {
        padding-left: 30px;
    }

    &__text {
        margin: auto;
        max-width: 780px;

        font-family: var(--font_family_heading);
        font-size: rem(32px);
        line-height: 45px;
        text-align: center;
        color: white;

        @include theme(br) {
            font-family: var(--font_family_heading_regular);
            color: black;
        }

        @include theme(dh) {
            font-family: var(--font_family_heading_regular);
            color: black;
        }

        @include theme(wb) {
            font-family: var(--font_family_main);
            color: white;
            line-height: 44.8px;
            letter-spacing: -0.64px;
        }
    }

    .cta.cta--secondary {
        margin: 40px auto 0;
        width: auto;

        color: white;
        cursor: pointer;
        border-color: white;

        &:hover {
            background-color: white !important;
            color: black !important;
        }

        @include theme(br) {
            color: black;
            border-color: #2d2d2d;

            &:hover {
                background-color: #2d2d2d !important;
                color: white !important;
            }
        }

        @include theme(dh) {
            color: black;
            border-color: black;

            &:hover {
                color: black;
                border-color: white !important;
            }
        }

        @include theme(wb) {
            &:hover {
                @extend .cta--hover-white-outline;
            }
        }
    }

    @include breakpoint(sm, down) {
        .container-fluid {
            padding-left: 0;
        }

        &__text {
            max-width: 450px;

            font-size: rem(20px);
            line-height: 28px;
        }
    }
}
