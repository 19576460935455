.outdated-browser {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    padding: $layout_page_padding;
    justify-content: center;
    align-items: center;

    @extend .animated;
    @extend .fadeIn;

    &:before {
        content: "";
        display: block;
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(black, 0.6);
        animation-duration: 500ms;
    }

    &__inner {
        max-width: 690px;
        max-height: calc(100vh - #{2 * $layout_page_padding});
        background-color: white;
        padding: 40px 30px;
        overflow: auto;
        @include breakpoint(sm) {
            padding: 80px;
        }
    }

    .eyebrow {
        opacity: 0.65;
        font-size: rem(10px);
        line-height: rem(12px);
        @include breakpoint(sm) {
            font-size: rem(14px);
            line-height: rem(17px);
        }
    }

    &__title {
        font-size: rem(20px);
        line-height: rem(30px);
        margin: 10px 0;
        @include breakpoint(sm) {
            font-size: rem(28px);
            line-height: rem(40px);
        }
    }

    &__description {
        margin-bottom: 30px;
    }
}
