.react-form__gallery {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 20px;

  &>div:nth-child(2) {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
  }

  &>div:nth-child(3) {
    width: 100px;
  }

  &>div:nth-child(4) {
    width: 0px;
  }

  &:only-child {
    &>div:not(:first-child) {
      display: none;
    }
  }

  border-top: 1px solid $colors_grey_light;

  &:not(:only-child):last-child {
    border-bottom: 1px solid $colors_grey_light;
  }
}
