.trending-item {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: var(--colors_heading_black);
    font-weight: bold;
    min-width: 180px;

    &__image {
        flex: 0 0 60px;
        @include aspect-ratio(1, 1);
        max-width: 70px;
        background-size: cover;
        border-radius: 100%;
    }

    &__content {
        flex: 0 0 110px;
        text-align: left;
        padding: 0 15px 0 9px;
    }

    &__title {
        font-size: rem(12px);
        line-height: rem(16px);

        @include theme(br) {
            letter-spacing: -0.02em;
        }

        @include theme(wb) {
            line-height: 18px;
            letter-spacing: -0.14px;
        }
    }

    &__description {
        @include rem(font-size, 8px);
        line-height: rem(10px);
        text-transform: uppercase;
        letter-spacing: var(--font_letterspace_wide);
        color: $colors_grey_med_light;
        margin-bottom: 5px;

        @include theme(dh) {
            font-family: var(--font_family_heading_medium);
            font-weight: normal;
            color: #6839b6;
        }

        @include theme(br) {
            font-weight: 600;
            font-family: var(--font_family_heading_semibold);
            color: var(--colors_highlight);
            text-transform: capitalize;
            letter-spacing: -0.02em;
            font-size: rem(12px);
            line-height: rem(14px);

            @include breakpoint(xs, down) {
                font-size: rem(9px);
                line-height: rem(11px);
            }
        }

        @include theme(wb) {
            font-family: var(--font_family_main);
            font-size: rem(12px);
            line-height: 14px;
            letter-spacing: 0.4px;
            font-weight: 700;
            color: var(--colors_rust);

            @include breakpoint(xs, down) {
                font-size: rem(11px);
            }
        }
    }

    @include breakpoint(md) {
        display: block;
        margin-top: 35px;
        min-width: 0px;

        &__image {
            margin: 10px auto;
        }

        &__content {
            text-align: center;
        }

        &__title {
            font-size: rem(14px);
            line-height: rem(18px);

            @include theme(br) {
                letter-spacing: -0.02em;
            }
        }

        &__description {
            @include rem(font-size, 11px);
            line-height: rem(14px);
        }
    }
}
