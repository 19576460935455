$map_mobile_dragbar_height: 26px;
$map_mobile_expanded_header_height: 80px;
$map_column_padding: 25px;
$map_modal_padding: 20px 30px;
$map_desktop_sidebar_width: 420px;

.gmnoprint,
.gmnoscreen,
.gm-style-cc {
    display: none;
}

.gm-bundled-control.gmnoprint {
    display: block;
    img {
        transform: translate(-50%, -50%) scale(0.6);
        transform-origin: center center;
    }
    .gmnoprint {
        display: block;
        > div {
            border: 1px solid #d7d7d7;
            border-radius: 5px !important;
            box-shadow: none !important;
        }
    }
}

[src^="https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"] {
    display: none;
}

.gm-bundled-control-on-bottom {
    .gmnoprint {
        position: relative;
    }
}

.precinct-map {
    &,
    * {
        backface-visibility: hidden;
    }

    &__inner {
        @extend .animated;
        @extend .fadeIn;
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: white;
        overflow: hidden;
    }

    &__close {
        position: absolute;
        z-index: 5;
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background: rgba(black, 0.44);
        color: white;
        font-size: 14px;
        font-weight: bold;
    }

    &__mobile-dragbar {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: $map_mobile_dragbar_height;
        padding: 0 10px;
        width: 100%;
        z-index: 1;
        background-color: rgba(#f7f7f7, 0.5);
        border-radius: 15px;
        align-self: center;

        &:before,
        &:after {
            content: "";
            display: inline-block;
            height: 1px;
            width: 35px;
            background: #979797;
        }

        &:before {
            margin-bottom: 2px;
        }
    }

    &__amenities-toggle {
        @extend .eyebrow;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        left: 10px;
        padding: 10px;
        font-size: rem(12px);
        font-weight: bold;
        letter-spacing: 1px;
        background: white;
        border: 1px solid #d7d7d7;
        border-radius: 5px;

        &.is-active {
            .icon {
                @extend .icon-visibility-hidden;
            }
        }

        .icon {
            @extend .icon-visibility;
            font-size: 1.4em;
            margin-left: 5px;
        }
        @include breakpoint(sm, down) {
            font-size: rem(10px);
            padding: 6px 12px;
            padding-right: 8px;
        }
        @include breakpoint(md, up) {
            padding-left: 12px;
        }

        @include theme(br) {
            font-family: var(--font_family_heading_regular);
            font-weight: 600;
            text-transform: none;
            letter-spacing: -0.02em;
        }
    }

    &__user-location-toggle {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 100px;
        right: 10px;
        background: white;
        border: 1px solid #d7d7d7;
        border-radius: 100%;
        height: 40px;
        width: 40px;
        font-size: rem(20px);
        outline: none;
        cursor: pointer;

        .icon {
            @extend .icon-location-disabled;
            margin-left: -4px;
            margin-bottom: -4px;
        }
        &.is-active {
            .icon {
                @extend .icon-location;
            }
        }

        @include breakpoint($breakpoint_desktop_nav) {
            display: none;
        }
    }

    &__map {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        transform: translate3d(0, 0, 0);
        transition: height 500ms;

        .is-collapsed & {
            @include breakpoint(sm, down) {
                height: calc(100% - #{$map_mobile_dragbar_height});
            }
        }

        .is-expanded & {
            @include breakpoint(sm, down) {
                height: $map_mobile_expanded_header_height;
            }
        }

        .is-full & {
            @include breakpoint(sm, down) {
                height: 0;
            }
        }

        &__google {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &__error-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(black, 0.7);
        z-index: 1;
    }

    &__error-modal {
        position: relative;
        background-color: white;
        color: $colors_black_med;
        padding: 20px 50px 20px 20px;
        border-radius: 5px;
        max-width: 270px;

        i {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }

    &__error-title {
        font-size: rem(18px);
        line-height: rem(26px);
        font-weight: 600;
    }

    &__error-description {
        font-size: rem(16px);
        line-height: rem(26px);
        margin-top: 5px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        background: #f7f7f7;
        height: calc(100% - #{$map_mobile_expanded_header_height});
        width: 100%;
        overflow: visible;
        -webkit-overflow-scrolling: touch;
        box-shadow: 0 -18px 24px 0 rgba(black, 0.13);
        transform: translate3d(
            0,
            calc(50% + #{$map_mobile_expanded_header_height/2}),
            0
        );
        transition: transform 500ms;

        padding-bottom: calc(50% - #{$map_mobile_expanded_header_height});

        .is-collapsed & {
            @include breakpoint(sm, down) {
                padding-bottom: 0;
                transform: translate3d(
                    0,
                    calc(
                        100% - #{$map_mobile_dragbar_height} + #{$map_mobile_expanded_header_height}
                    ),
                    0
                );
            }
        }

        .is-expanded & {
            @include breakpoint(sm, down) {
                padding-bottom: 0;
                transform: translate3d(
                    0,
                    $map_mobile_expanded_header_height,
                    0
                );
            }
        }

        .is-full & {
            @include breakpoint(sm, down) {
                padding-bottom: 0;
                transform: translate3d(0, 0, 0);
                height: 100%;
                z-index: 6;
            }
        }

        &__body {
            // padding: 0 $map_column_padding;
            background: white;
            overflow: auto;
            height: calc(100% - 130px);
        }
    }

    &__header {
        display: none;
        flex-direction: row;
        justify-content: space-between;
        padding: $map_column_padding $map_column_padding 0;

        &__title {
            font-weight: bold;
            font-family: var(--font_family_heading);
            font-size: rem(24px);
            line-height: (32/30) + em;
            text-transform: uppercase;
            text-decoration: none;
            color: $colors_grey_dark;

            @include theme(br) {
                text-transform: none;
                letter-spacing: -0.02em;
            }
        }

        .logo-lockup {
            font-size: rem(60px);

            .logo-lockup__logo--nsw {
                display: none;
            }

            @include theme(dh) {
                font-size: rem(40px);

                .logo-lockup__logo--darlingharbour--desktop {
                    display: none;
                }
            }

            html:not(.theme_dh) {
                .logo-lockup__logo {
                    color: $colors_grey_dark;
                }

                .logo-lockup__logo svg * {
                    fill: $colors_grey_dark;
                }
            }
        }
    }

    &__search {
        padding: 10px $map_column_padding;
        @include breakpoint(sm, down) {
            padding-top: $map_mobile_dragbar_height;
        }

        &__inner {
            display: flex;
            flex-direction: row;
            border-radius: 50px;
            border: none;
            background: white;

            @include theme(dh) {
                border-radius: 3px;
            }

            label {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 45px;
            }

            input {
                display: block;
                flex: 1 1 auto;
                width: 100%;
                height: 45px;
                border: none;
                background: transparent;
                padding-right: 20px;
                font-size: rem(14px);
            }
        }
    }

    &__tenant__selection {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0 $map_column_padding;
        overflow: auto;

        &__btn {
            @extend .eyebrow;
            display: block;
            flex: 0 0 auto;
            padding: 5px 0 15px;
            margin-left: 25px;
            font-size: rem(10px);
            color: $colors_grey_med;
            text-decoration: none;
            border-bottom: 2px solid transparent;
            transition: all 300ms;

            @include theme(dh) {
                margin-left: 18px;
                font-family: var(--font_family_heading);
                font-size: rem(12px);
                line-height: rem(14px);
                letter-spacing: 2px;
            }

            @include theme(br) {
                font-family: var(--font_family_heading_regular);
                font-weight: 600;
                font-size: rem(14px);
                line-height: rem(17.64px);
                text-transform: none;
                letter-spacing: -0.02em;
            }

            &:first-child {
                margin-left: 0;
            }

            &.is-active {
                color: $colors_grey_dark;
                border-color: $colors_grey_dark;
            }
        }
    }

    &__filter-btn {
        @extend .eyebrow;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        text-align: left;
        padding: 15px 30px;
        font-size: rem(10px);
        letter-spacing: var(--font_letterspace_wide);
        border-bottom: 1px solid $colors_grey_light;
        background-color: white;

        @include theme(br) {
            font-size: rem(12px);
        }

        .icon {
            font-size: rem(13px);
            margin-right: 10px;
        }
    }

    &__filter-count {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        margin-left: 10px;
        min-width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: var(--colors_highlight);
        color: black;
        font-weight: 600;

        @include theme(dh) {
            color: white;
        }

        @include theme(br) {
            color: white;
        }
    }

    .lazyload-placeholder {
        position: relative;
        &:after {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            left: 30px;
            height: 1px;
            width: calc(100% - 60px);
            background-color: $colors_grey_light;
        }
    }

    &__tenants {
        background: white;
        &__btn {
            position: relative;
            display: flex;
            width: 100%;
            text-align: left;
            padding: 15px 30px;
            //border-top: 1px solid $colors_grey_light;
            text-decoration: none;
            @include breakpoint(sm, down) {
                padding: 15px 20px;
            }
            &:after {
                display: block;
                content: "";
                position: absolute;
                bottom: 0;
                left: 30px;
                height: 1px;
                width: calc(100% - 60px);
                background-color: $colors_grey_light;
                @include breakpoint(sm, down) {
                    left: 20px;
                    width: calc(100% - 40px);
                }
            }

            .precinct-map__inner:not(.is-expanded) & {
                @include breakpoint(sm, down) {
                    &:last-child {
                        margin-bottom: 100px;
                    }
                }
            }

            &__image {
                @include aspect-ratio(80, 53);
                flex: 0 0 80px;
                background-color: grey;
                background-size: cover;
                background-position: center;
            }

            &__content {
                flex: 1 1 auto;
                margin: 0 15px;

                &__type {
                    @extend .eyebrow;
                    font-size: rem(9px);
                    font-weight: bold;
                    opacity: 0.65;
                    margin-bottom: 5px;
                    color: $colors_grey_dark;

                    @include theme(dh) {
                        font-size: rem(10px);
                        line-height: rem(13px);
                    }

                    @include theme(br) {
                        text-transform: none;
                        letter-spacing: -0.02em;
                        font-family: var(--font_family_heading_semibold);
                    }
                }

                &__title {
                    @extend .heading;
                    font-size: rem(17px);

                    @include theme(dh) {
                        text-transform: none;
                        font-family: var(--font_family_heading_bold);
                        font-size: rem(17px);
                        line-height: rem(21px);
                        letter-spacing: -0.02em;
                    }

                    @include theme(br) {
                        text-transform: none;
                        font-family: var(--font_family_heading_semibold);
                        font-weight: 600;
                        font-size: rem(16px);
                        line-height: rem(20px);
                        letter-spacing: -0.02em;
                    }
                }
            }

            &__icon {
                flex: 0 1 auto;
                font-size: 25px;
                align-self: center;
            }
        }
    }

    &__modal {
        .modal__content {
            position: relative;
            width: 90%;
            background: white;
        }

        &__pane {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            background: white;
            box-shadow: 0 -18px 24px 0 rgba(black, 0.13);
            z-index: 3;

            &.is-active {
                z-index: 2;
            }
        }

        &__header {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 0 0 auto;
            padding: $map_modal_padding;
            background: #f7f7f7;

            &__icon {
                flex: 0 0 auto;
                margin-right: 10px;
            }

            &__title {
                @extend .eyebrow;
                font-size: rem(13px);
                line-height: $font_lineheight_heading;
                flex: 1 0 auto;

                @include theme(dh) {
                    font-family: var(--font_family_heading_medium);
                    font-weight: normal;
                    font-size: rem(12px);
                    line-height: rem(18px);
                }

                @include theme(br) {
                    font-family: var(--font_family_heading_regular);
                    font-weight: 600;
                    font-size: rem(14px);
                    line-height: rem(17.64px);
                    text-transform: capitalize;
                }
            }

            &__close {
                flex: 0 0 auto;
                margin-left: 10px;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }

        &__footer {
            flex: 0 0 auto;
            padding: $map_modal_padding;

            .cta {
                text-transform: uppercase;
            }
            @include theme(br) {
                .cta {
                    text-transform: capitalize;
                }
            }
        }

        .react-form {
            padding: 15px 0;
        }
    }

    &__filter-pane-option {
        @extend .eyebrow;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 5px 30px;
        font-size: rem(12px);
        text-align: left;

        &.is-incomplete .react-form__field__label__inner {
            color: #818181;
        }

        &.has-value .react-form__field__label__inner {
            color: black;
        }

        &--btn {
            padding: 25px 30px;
            border-bottom: 1px solid #e2e2e2;

            &:first-of-type {
                border-top: 1px solid #e2e2e2;
            }
        }

        &__label {
            flex: 1 1 auto;

            @include theme(br) {
                font-family: var(--font_family_heading_semibold);
                font-size: rem(14px);
                text-transform: capitalize;
            }
        }

        &__active-filters {
            flex: 0 0 auto;
        }

        &__icon {
            flex: 0 0 auto;
            margin-left: 20px;
            color: #505050;

            @include theme(dh) {
                color: var(--colors_highlight);
            }

            @include theme(br) {
                color: var(--colors_highlight);
            }
        }

        &.react-form__field--checkbox,
        &.react-form__field--radio {
            input {
                height: auto;
            }

            .react-form__field__label {
                min-height: 0 !important;
                order: 0;
                font-size: rem(12px) !important;
                @include theme(br) {
                    font-family: var(--font_family_heading_semibold);
                    font-size: rem(14px) !important;
                }
            }
            .react-form__field__checkbox-indicator {
                order: 3;
                margin: 0 0 0 10px;
                text-align: center;

                &:before {
                    margin-left: 0.5em;
                    @include theme(br) {
                        margin-left: 0;
                    }
                }
            }
        }

        @include theme(br) {
            label {
                font-family: var(--font_family_heading_regular);
                text-transform: none;
                letter-spacing: -0.02em;
            }
        }
    }

    &__filter-reset-btn {
        display: block;
        width: 100%;
        text-align: center;
        padding: 10px 30px;
        margin-top: auto;

        .icon {
            margin: 0 10px;
        }
    }

    &__no-results {
        padding: 25px;
    }

    // Desktop
    @include breakpoint($breakpoint_desktop_nav) {
        &__inner {
            display: flex;
            flex-direction: row;
        }

        &__detail-view {
            width: 420px;
        }

        &__mobile-dragbar {
            display: none;
        }

        &__amenities-toggle {
            left: auto;
            bottom: 10px;
            right: 60px;
        }

        &__map {
            position: relative;
            flex: 1 1 auto;
            width: auto;
            height: 100%;
            order: 1;
            transition: none;
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 0 0 $map_desktop_sidebar_width;
            width: auto;
            height: 100%;
            transform: none;
            order: 0;
            overflow: hidden;
            padding-bottom: 0;
            transition: none;

            &__nav {
                flex: 0 1 auto;
            }

            &__body {
                flex: 1 1 auto;
                overflow: auto;
            }
        }

        &__header {
            display: flex;
        }

        &__search {
            padding: $map_column_padding;
        }

        &__modal {
            .modal__content {
                width: $map_desktop_sidebar_width;
            }
        }
    }
}

// tenant marker

.user-marker {
    position: relative;
    z-index: 2;
    font-size: 30px; // 25px
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-top: -0.5em; //shift marker up so the map point is at bottom of the marker
    cursor: pointer;
    background-color: #3852f7;
    border: 3px solid white;
}

// tenant marker

.tenant-marker {
    position: relative;
    z-index: 2;
    font-size: 35px; // 25px
    width: 0.76666666667em;
    height: 1em;
    border-radius: 100%;
    margin-top: -0.5em; //shift marker up so the map point is at bottom of the marker
    cursor: pointer;

    &:hover,
    .html-map-marker.is-active & {
        z-index: 99999999 !important;
        .tenant-marker__label {
            display: block;
            z-index: 99999999 !important;
            @include breakpoint(sm, down) {
                //display: none;
            }
        }
    }

    &:before {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        bottom: 20%;
        right: 0;
        font-size: 0.3em;
        line-height: 1em;
        color: white;
    }

    .map-pin {
        position: absolute;
        top: 1px;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 2;
    }

    &.icon-stay {
        &:before {
            font-size: 0.25em;
            bottom: 25%;
        }
        .map-pin__inner {
            fill: var(--colors_tenant_stay);
        }
    }

    &.icon-eat-and-drink {
        .map-pin__inner {
            fill: var(--colors_tenant_eat_and_drink);
        }
    }

    &.icon-shopping {
        &:before {
            bottom: 25%;
        }
        .map-pin__inner {
            fill: var(--colors_tenant_shopping);
        }
    }

    &.icon-market {
        &:before {
            bottom: 25%;
        }
        .map-pin__inner {
            fill: var(--colors_tenant_market);
        }
    }

    &.icon-things-to-do {
        &:before {
            bottom: 25%;
            font-size: 0.25em;
        }
        .map-pin__inner {
            fill: var(--colors_tenant_things_to_do);
        }
    }

    &__offer {
        display: flex !important;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: -10px;
        z-index: 5;
        height: 20px;
        width: 20px;
        font-size: 20px;
        background-color: var(--colors_highlight);
        border: 2px solid white;
        border-radius: 100%;
        color: #505050;
        @include theme(br) {
            color: white;
        }

        &:before {
            position: relative;
            left: -0.05em;
            top: -0em;
        }
    }

    &__label {
        display: none;
        position: absolute;
        top: 0;
        left: 50%;
        background: white;
        padding: 10px;
        width: 200px;
        text-align: center;
        box-shadow: 0 32px 44px 0 rgba(black, 0.17);
        transform: translate3d(-50%, -100%, 0);
        transform-origin: center bottom;
        margin-top: -10px;
        @extend .animated;
        @extend .fadeIn;
        animation-duration: 500ms;

        &:before {
            content: "";
            @include arrow(down, 7px, white);
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
        }

        &__type {
            @extend .eyebrow;
            font-size: rem(9px);
            font-weight: bold;
            opacity: 0.65;
            padding-top: 4px;
        }

        &__title {
            @extend .heading;
            font-size: rem(17px);
            padding-bottom: 5px;
        }
    }
}

// tenant cluster marker

.tenant-cluster-marker {
    position: relative;
    z-index: 2;
    font-size: 30px; // 25px
    margin-top: -0.5em; //shift marker up so the map point is at bottom of the marker
    cursor: pointer;

    &:hover {
        z-index: 99999999 !important;
        .tenant-cluster-marker__label {
            display: block;
            z-index: 99999999 !important;
            &:after {
                content: "";
                background-color: transparent;
                position: absolute;
                bottom: -10px;
                left: 0;
                height: 20px;
                width: 100%;
            }
        }
    }

    &__counter {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 20%;
        height: 45px;
        width: 45px;
        font-size: rem(14px);
        font-family: var(--font_family_main);
        color: $colors_grey_dark;
        border-radius: 100%;

        @include theme(dh) {
            font-weight: normal;
        }

        @include theme(br) {
            color: white;
        }
    }

    &__label {
        display: none;
        position: absolute;
        top: 0;
        left: 50%;
        background: $colors_black_med;
        padding: 6px;
        text-align: center;
        box-shadow: 0 32px 44px 0 rgba(black, 0.17);
        transform: translate3d(-50%, -100%, 0);
        margin-top: -10px;
        animation-duration: 500ms;
        color: white;
        font-size: rem(10px);
        line-height: rem(10px);
        font-weight: 600;
        white-space: nowrap;
        @extend .animated;
        @extend .fadeIn;
        @extend .eyebrow;

        &:before {
            content: "";
            @include arrow(down, 7px, $colors_black_med);
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
        }
    }

    .map-pin {
        position: absolute;
        top: 1px;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        transform: scale(1.2) translateY(5%);
    }

    .map-pin__inner {
        fill: var(--colors_highlight);

        @include theme(dh) {
            fill: #9be5d5;
        }
    }

    &--size-1 .tenant-cluster-marker__counter {
        height: 45px;
        width: 45px;
        padding-top: 20%;
    }

    &--size-2 .tenant-cluster-marker__counter {
        height: 55px;
        width: 55px;
        padding-top: 25%;
    }

    &--size-3 .tenant-cluster-marker__counter {
        height: 65px;
        width: 65px;
        padding-top: 25%;
    }
}

// amenity markers

.amenity-marker {
    display: flex !important;
    font-size: 30px;
    width: 1em;
    height: 1em;
    border-radius: 4px;
    background: white;
    border: 1px solid #757575;
    cursor: pointer;
    position: relative;

    &:hover,
    .html-map-marker.is-active & {
        z-index: 99999999;
        .amenity-marker__label {
            display: flex;
            z-index: 99999999 !important;
            @include breakpoint(sm, down) {
                //display: none;
            }
            &:after {
                content: "";
                background-color: transparent;
                position: absolute;
                bottom: -10px;
                left: 0;
                height: 20px;
                width: 100%;
            }
        }
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7em;
        color: #2d2d2d;
    }

    &.icon-amenity-atm {
        &:before {
            transform: scale(0.8);
        }
    }

    &.icon-amenity-ferry,
    &.icon-amenity-train,
    &.icon-amenity-bus {
        border-radius: 100%;
        &:before {
            transform: scale(0.95);
        }
    }

    &__label {
        display: none;
        position: absolute;
        top: 0;
        left: 50%;
        max-width: 180px;
        background: $colors_black_med;
        padding: 5px 10px;
        flex-direction: column;
        box-shadow: 0 32px 44px 0 rgba(black, 0.17);
        transform: translate3d(-50%, -100%, 0);
        margin-top: -10px;
        @extend .animated;
        @extend .fadeIn;
        animation-duration: 500ms;
        &--extended {
            padding: 10px;
            .amenity-marker__label__title {
                padding: 0 10px;
            }
        }

        &:before {
            content: "";
            @include arrow(down, 7px, $colors_black_med);
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
        }

        &__title {
            @extend .eyebrow;
            color: white;
            font-size: rem(10px);
            line-height: 1.25em;
            text-align: center;
            white-space: nowrap;

            @include theme(br) {
                font-family: var(--font_family_heading_semibold);
                font-size: rem(12px);
            }
        }

        &__short-description {
            width: 100%;
            color: white;
            font-size: rem(12px);
            line-height: rem(20px);
            margin-top: 10px;

            @include theme(dh) {
                color: white;
            }

            p {
                padding-bottom: 5px;
            }
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                padding-left: 10px;

                li {
                    position: relative;
                    margin-bottom: 0;
                    &:before {
                        content: "";
                        position: absolute;
                        left: -10px;
                        top: 8px;
                        height: 5px;
                        width: 5px;
                        background-color: var(--colors_highlight);

                        @include theme(br) {
                            background-color: var(--colors_rust);
                        }
                    }
                }
            }
        }

        &__link {
            color: white;
            font-size: rem(12px);
            line-height: rem(20px);
            font-weight: 600;
            margin-top: 10px;
            white-space: nowrap;
            align-self: flex-start;
            text-decoration: none;
            @extend .underline;
            box-shadow: inset 0 -0.2em 0 var(--colors_highlight);

            @include theme(br) {
                box-shadow: inset 0 -0.2em 0 var(--colors_rust);
                font-family: var(--font_family_heading_semibold);
                &:hover {
                    color: white;
                }
            }
        }
    }
}
