$primary_nav_mobile_height: 88px;

.primary-nav {
    position: sticky;
    z-index: $layout_nav_zindex;
    top: 0;
    left: 0;
    background: white;
    @include rem(font-size, 15px);
    transition: all 300ms;

    &:not(.is-active).is-scrolled {
        transform: translate3d(0, -100%, 0);
    }

    &__desktop {
        display: none;
    }

    &__mobile {
        border-bottom: 1px solid $colors_grey_light;
        &__header {
            height: $primary_nav_mobile_height;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $colors_grey_light;
            .primary-nav__btn:before {
                margin-bottom: 10px;
            }
        }

        .primary-nav & .primary-nav__btn {
            padding-right: 15px;
            padding-left: 15px;
            min-width: auto;

            &:before {
                font-size: rem(16px);
            }
        }

        @include theme(br) {
            .primary-nav__btn {
                &.primary-nav__btn--map {
                    &:after {
                        font-size: rem(11px);
                        font-family: var(--font_family_heading_semibold);
                    }
                }
                &.primary-nav__btn--mobile-menu {
                    &::before {
                        color: var(--colors_highlight);
                    }
                    &:after {
                        font-size: rem(11px);
                        font-family: var(--font_family_heading_semibold);
                    }
                }
            }
        }

        &__logos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }

        &__quick-links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            padding: 0 15px;

            .primary-nav__link {
                flex: 0 0 auto;
                font-size: rem(10px);
                margin-left: 5px;
                margin-right: 20px;
                letter-spacing: rem(1.8px);

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                @include theme(br) {
                    font-size: rem(12px);
                    font-family: var(--font_family_heading_bold);
                    margin-left: 7px;
                    margin-right: 7px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @include theme(dh) {
                    margin-right: 5px;
                }
            }
        }

        &__dropdown {
            width: 100%;
            position: absolute;
            background: white;
            top: $primary_nav_mobile_height;
            left: 0;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            transition: all 500ms;
            transition-timing-function: $easeInOutCubic;

            &__inner {
                height: 0;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                & > * {
                    animation-fill-mode: both;
                    animation-duration: 500ms;
                    animation-name: fadeOut;
                    backface-visibility: hidden;
                }

                .primary-nav.is-active & {
                    height: calc(100vh - #{$primary_nav_mobile_height});

                    & > * {
                        animation-name: fadeInLeft;
                        @include stagger("animation", 20, 0.05, 0.25);
                    }
                }
            }

            .primary-nav__spacer--after-primary {
                margin-top: -8px;
            }

            .primary-nav__link {
                &--primary {
                    margin: 0 0 15px;
                }

                &--secondary {
                    margin: 4px 0 4px;
                }

                &:first-child {
                    margin-top: 45px;
                }

                &:last-child {
                    margin-bottom: 45px;
                }

                &:last-of-type {
                    margin-bottom: 40px;
                }
            }
        }
    }

    &.is-active #{&}__mobile__header #{&}__btn--map {
        opacity: 0;
        pointer-events: none;
    }
    &.is-active .primary-nav__mobile .primary-nav__btn--mobile-menu {
        @extend .icon-close;
        &:after {
            content: "CLOSE";

            @include theme(br) {
                content: "Close";
            }
        }
    }
    .primary-nav__btn {
        min-width: 88px;
        height: 100%;
        @extend .icon;
        display: inline-flex !important;
        color: var(--colors_body_black);
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
        text-align: center;
        transition: all 750ms;

        &:after {
            font-size: rem(9px);
            letter-spacing: 2px;
            @include breakpoint($breakpoint_desktop_nav) {
                font-size: rem(12px);
            }

            @include theme(br) {
                letter-spacing: -0.02em;
            }
        }

        &--mobile-menu {
            @extend .icon-menu;
            &:after {
                content: "MENU";
            }

            @include theme(br) {
                width: 66px;
                &:after {
                    content: "Menu";
                    text-transform: capitalize;
                }
            }

            @include theme(wb) {
                width: 66px;
                padding-bottom: 0;
                &:after {
                    content: "Menu";
                    text-transform: capitalize;
                    font-size: rem(12px);
                    letter-spacing: -0.12px;
                    color: var(--colors_heading_black);
                }
            }
        }

        &--map {
            padding-bottom: 0;
            @extend .icon-map;

            &:before {
                transform: scale(1.3);
            }
            &:after {
                content: "MAP";
            }

            @include theme(br) {
                padding-bottom: 20px;

                &:before {
                    color: var(--colors_highlight);
                }
                &:after {
                    content: "Map";
                    text-transform: capitalize;
                    font-size: rem(12px);
                    letter-spacing: -0.02em;
                }
            }

            @include theme(wb) {
                &:after {
                    content: "Map";
                    text-transform: capitalize;
                    font-size: rem(12px);
                    letter-spacing: -0.12px;
                    color: var(--colors_heading_black);
                }
            }

            @include theme(dh) {
                padding-bottom: 20px;
            }
        }

        &--search {
            padding-bottom: 0;

            @extend .icon-search;
            &:after {
                content: "SEARCH";
            }

            @include theme(br) {
                padding-bottom: 20px;

                &:before {
                    color: var(--colors_highlight);
                }

                &:after {
                    content: "Search";
                    text-transform: capitalize;
                    font-size: rem(12px);
                    letter-spacing: -0.02em;
                }
            }

            @include theme(wb) {
                &:after {
                    content: "Search";
                    text-transform: capitalize;
                    font-size: rem(12px);
                    letter-spacing: -0.12px;
                    color: var(--colors_heading_black);
                }
            }

            @include theme(dh) {
                padding-bottom: 20px;
            }
        }
    }

    &__compact {
        display: none;

        .primary-nav__btn {
            width: $layout_nav_desktop_width_compact * 0.8;
            height: $layout_nav_desktop_width_compact * 0.8;
            transition: all 250ms;
            &:after {
                font-size: rem(10px);
            }

            @include breakpoint(xl, only) {
                width: $layout_nav_desktop_width_compact;
                height: $layout_nav_desktop_width_compact;
                &:after {
                    font-size: rem(12px);
                }
            }

            &.primary-nav__btn--mobile-menu {
                &:before {
                    margin-top: 10px;
                    margin-bottom: 16px;
                    font-size: rem(13px);
                    @include breakpoint(xl, only) {
                        margin-top: 14px;
                        margin-bottom: 26px;
                        font-size: rem(18px);
                    }
                }
            }

            &.primary-nav__btn--search:before {
                margin-top: -4px;
                margin-bottom: 14px;
                font-size: rem(18px);
                @include breakpoint(xl, only) {
                    margin-top: 14px;
                    margin-bottom: 26px;
                    font-size: rem(18px);
                }
            }

            &.primary-nav__btn:hover {
                background: #e2e2e2;
            }

            &.primary-nav__btn--dark:hover {
                background: $colors_grey_med;
            }
        }
    }

    .logo-lockup {
        font-size: 38px;

        @include theme(dh) {
            font-size: 36px;

            @media (max-width: 350px) {
                font-size: 30px;
            }
        }

        @include theme(wb) {
            height: 60px;
            margin-top: 20px;
        }

        html:not(.theme_dh) {
            .logo-lockup__logo {
                color: $colors_grey_dark;
            }

            .logo-lockup__logo svg * {
                fill: $colors_grey_dark;
            }
        }
    }

    .text-logo-dh {
        display: block;
        width: 100%;
    }

    &__title {
        display: block;
        font-weight: bold;
        font-family: var(--font_family_heading);
        line-height: (32/30) + em;
        text-transform: uppercase;
        text-decoration: none;
        color: $colors_grey_dark;

        @include theme(dh) {
            display: none;
        }

        @include theme(br) {
            display: none;
        }
    }

    &__link {
        display: inline-block;
        text-decoration: none;
        color: $colors_grey_dark;
        padding-bottom: 0.1em;
        transition: all 0.3s;

        @include theme(dh) {
            font-family: var(--font_family_heading);
            font-weight: normal;
            letter-spacing: var(--font_letterspace_wide);

            &:hover {
                box-shadow: inset 0 -2em 0 #9be5d5;
            }
        }

        @include theme(br) {
            &:hover {
                box-shadow: inset 0 -8px 0 var(--colors_highlight);
            }
        }

        @include theme(wb) {
            &:hover {
                box-shadow: inset 0 -8px 0 var(--colors_rust);
            }
        }

        &:hover {
            box-shadow: inset 0 -2em 0 var(--colors_highlight);
            color: $colors_grey_dark;
        }

        &--primary {
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: var(--font_letterspace_wide);
            margin: 11px 0;

            @include theme(br) {
                text-transform: none;
                letter-spacing: -0.02em;
            }

            @include theme(wb) {
                text-transform: none;
                letter-spacing: -0.02em;
                font-size: rem(12px);
                color: var(--colors_heading_black);
            }
        }

        .primary-nav__link--primary:first-of-type {
            margin-top: 35px;
        }

        &--secondary {
            margin: 12px 0;
            color: $colors_grey_med;

            @include theme(dh) {
                font-family: var(--font_family_main);
                letter-spacing: 0;

                @include breakpoint($breakpoint_desktop_nav) {
                    font-size: rem(14px);
                }
            }

            @include theme(br) {
                letter-spacing: -0.02em;
            }
        }

        &--active {
            position: relative;
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 7px;
                background-color: var(--colors_highlight);
                left: 0;
                bottom: 6px;
                z-index: -1;

                @include theme(dh) {
                    background-color: #9be5d5;
                }

                @include theme(br) {
                    bottom: 2px;
                    height: 5px;
                }

                @include theme(wb) {
                    bottom: 2px;
                    height: 5px;
                    background-color: var(--colors_rust);
                }
            }
        }

        .primary-nav__link--secondary:first-of-type {
            margin-top: 35px;
        }
    }

    &__btn {
        display: block;
        border: none;
        padding: $layout_page_padding;
        font-weight: bold;
        letter-spacing: var(--font_letterspace_wide);
        text-transform: uppercase;

        @include theme(dh) {
            &:after {
                font-family: var(--font_family_heading_bold);
                font-weight: normal;
            }

            &:not(.primary-nav__btn--dark):before,
            .icon {
                color: #671980;
            }
        }

        &--dark#{&} {
            background: $colors_grey_dark;
            color: white;
        }

        &:before,
        .icon {
            display: block;
            font-size: rem(18px);
            margin-bottom: 15px;
        }

        & > * {
            display: block;
        }
    }

    &__utility-wrapper {
        position: relative;
        display: flex;
        justify-content: flex-start;
        left: 0;
        & > * {
            flex-basis: 1%;
            &:first-child {
                margin-right: 0px;
                padding-left: 0;
                padding-right: 0;
            }
        }
        .primary-nav__btn {
            &:after {
                font-size: rem(12px);
                @include breakpoint($breakpoint_desktop_nav) {
                    font-size: rem(10px);
                }
            }
        }
        &:after {
            display: none;
            content: "";
            position: absolute;
            left: 50%;
            top: 10px;
            height: calc(100% - 20px);
            width: 1px;
            background-color: $colors_grey_light;
            color: yellow;
        }
    }

    &__spacer--after-primary {
        height: 4px;
    }

    // desktop nav
    @include breakpoint($breakpoint_desktop_nav) {
        position: fixed;
        box-shadow: 0 20px 65px 0 rgba(#000000, 0.02);
        transition: height 1000ms, width 1000ms;
        transition-delay: 500ms;

        .logo-lockup {
            height: 60px;
            margin-bottom: 20px;

            @include theme(dh) {
                height: 150px;
                margin-bottom: 30px;
            }

            @include theme(br) {
                height: 30px;
            }

            @include theme(wb) {
                height: 84px;
                margin-bottom: 0;
            }
        }

        &__desktop {
            display: block;
        }

        &__mobile {
            display: none;
        }

        &__utility-wrapper {
            position: relative;
            margin-top: 60px;
            width: 100%;
        }

        &__expanded,
        &__compact {
            display: block;
            position: relative;
            overflow: visible;
            transition: opacity 500ms;
        }

        &__expanded {
            z-index: 2;
            width: $layout_nav_desktop_width_smaller;
            background: white;
            transition-delay: 1250ms;

            &__inner {
                padding: 40px 50px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                & > * {
                    animation-fill-mode: both;
                    animation-duration: 500ms;
                    animation-name: fadeInLeft;
                    backface-visibility: hidden;
                    @include stagger("animation", 20, 0.1, 0.5);
                }
            }
        }

        &__compact {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: $layout_nav_desktop_width_compact * 0.8;
            height: 0;
            opacity: 0;
            pointer-events: none;
            box-shadow: 0 0 33px 0 rgba(0, 0, 0, 0.05);
            @include breakpoint(xl, only) {
                width: $layout_nav_desktop_width_compact;
            }
        }

        // desktop scrolled/compact state
        &.is-scrolled {
            transform: none !important;
            width: $layout_nav_desktop_width_compact * 0.8;
            transition-delay: 0ms !important;
            @include breakpoint(xl, only) {
                width: $layout_nav_desktop_width_compact;
            }

            .primary-nav__expanded {
                z-index: 1;
                height: 0;
                opacity: 0;
                pointer-events: none;
                transition-delay: 0ms;
            }

            .primary-nav__compact {
                position: relative;
                z-index: 2;
                height: auto;
                opacity: 1;
                pointer-events: auto;
                transition-delay: 800ms;
            }
        }
    }
    @include breakpoint(md) {
        // compacter desktop navi
        width: $layout_nav_desktop_width_smaller;
        @include rem(font-size, 13px);
        &__expanded {
            width: $layout_nav_desktop_width_smaller;
            &__inner {
                padding: 40px 22px 40px 50px;

                @include theme(br) {
                    padding: 40px 22px 20px 50px;
                }

                @include theme(dh) {
                    padding: 40px 22px 20px 50px;
                }

                @include theme(wb) {
                    padding: 40px;
                }
            }
        }
        &__utility-wrapper {
            margin-top: 5px;

            & > .primary-nav__btn {
                min-width: 85px;
            }
            & > .primary-nav__btn:first-child {
                margin-left: -15px;
            }
            &:after {
                display: none;
            }
        }
        &__title {
            font-size: rem(22px);
            line-height: rem(28px);
            padding-bottom: 0;
            margin-bottom: 24px;

            @include theme(dh) {
                padding-bottom: 0;
                margin-bottom: 25px;
            }

            @include theme(br) {
                padding-bottom: 22px;
            }
        }

        &__link {
            &--primary {
                margin-top: 0;
                margin-bottom: 4px;

                @include theme(wb) {
                    margin-bottom: 16px;
                    font-size: 15px;
                }
            }
            &--secondary {
                margin-top: 0;
                margin-bottom: 4px;
            }
        }

        &__spacer--after-primary {
            height: 18px;
            margin-top: 0;
        }
    }
    @include breakpoint(xl) {
        // wider desktop navi
        width: $layout_nav_desktop_width;
        @include rem(font-size, 15px);

        @include theme(dh) {
            font-size: rem(16px);
        }

        &__expanded {
            width: $layout_nav_desktop_width;
            &__inner {
                padding: 40px;

                @include theme(dh) {
                    padding-bottom: 25px;
                }

                @include theme(br) {
                    padding-bottom: 25px;
                }
            }
        }
        &__utility-wrapper {
            margin-top: 10px;
            justify-content: flex-start;
            & > .primary-nav__btn {
                padding-left: 0;
                padding-right: 0;
                min-width: 80px;
            }
            & > .primary-nav__btn:first-child {
                margin-right: 0;
            }

            @include theme(dh) {
                margin-top: 30px;
            }

            @include theme(br) {
                margin-top: 30px;
            }
        }
        &__title {
            font-size: rem(30px);
            margin-bottom: 30px;

            @include theme(dh) {
                font-size: rem(24px);
                margin-bottom: 35px;
            }

            @include theme(br) {
                margin-bottom: 25px;
            }
        }

        &__link {
            &--primary {
                margin-top: 0;
                margin-bottom: 8px;
            }
            &--secondary {
                margin-top: 0;
                margin-bottom: 4px;
            }
        }

        &__spacer--after-primary {
            height: 10px;
        }
    }
}
