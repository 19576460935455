.gallery-image__field {
    &__input-container {
        height: 0px;
        width: 0px;
        overflow: hidden;
    }
    &__field-container {
        width: 158px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 33px;
        background: url("/assets/media/image-upload.png");
        background-repeat: no-repeat;
        background-size: 21px 21px;
        background-position: center 33px;
        background-color: $colors_grey_background;
    }
    label {
        flex-direction: column;
        display: inline-flex;
        align-items: center;
        width: 158px;
        position: relative;
    }
    &__filename {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        text-align: center;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__preview {
        position: absolute;
        width: 158px;
        height: 130px;
    }
    &__browse-button {
        background-color: var(--colors_maroon);
        border-color: var(--colors_maroon);
        color: white;
        width: 103px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15.5px;
        letter-spacing: 3.14px;
        font-size: 11px;
        font-weight: bold;
        text-transform: uppercase;

        @include theme(dh) {
            border-radius: 3px;
            background-color: transparent;
            border: 2px solid var(--colors_highlight);
            color: var(--colors_highlight);
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
        }
    }

    &__delete-button {
        position: absolute;
        right: 0;
        color: white;
        width: 36px;
        height: 36px;
        background-color: rgba(0, 0, 0, 0.6);
    }
    &__errors {
        font-size: rem(12px);
        line-height: rem(16px);
        margin-top: 8px;
    }
    &.is-invalid {
        color: $colors_red_dark;
    }
}
