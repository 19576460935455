body {
    font-family: var(--font_family_main);
    font-size: $font_size_base;
    line-height: $font_lineheight_base;
    color: var(--colors_body_black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    // heading resets
    font-weight: normal;

    @include theme(br) {
        letter-spacing: -0.02em;
    }
}

[class^="heading-"],
[class*=" heading-"],
.heading {
    // all headings
    font-family: var(--font_family_heading);
    font-weight: normal;
    line-height: $font_lineheight_heading;
    letter-spacing: var(--font_letterspace_heading);
    color: var(--colors_heading_black);

    @include theme(dh) {
        text-transform: uppercase;
    }
}

.heading-1 {
    @include rem(font-size, 28px);

    @include breakpoint(lg) {
        @include rem(font-size, 62px);
    }

    @include theme(br) {
        letter-spacing: -0.02em;
        font-family: var(--font_family_heading_bold);
        font-size: rem(34px);
        line-height: (38px);

        @include breakpoint(lg) {
            font-size: rem(48px);
            line-height: (52px);
        }
    }

    @include theme(wb) {
        letter-spacing: -0.34px;
        font-family: var(--font_family_heading);
        font-size: rem(34px);
        line-height: (36px);
        text-transform: uppercase;

        @include breakpoint(lg) {
            letter-spacing: -0.48px;
            font-size: rem(48px);
            line-height: (50px);
            text-transform: uppercase;
        }
    }
}

.heading-2 {
    @include rem(font-size, 28px);

    @include breakpoint(lg) {
        @include rem(font-size, 42px);
    }

    @include theme(dh) {
        font-size: rem(28px);
        line-height: rem(34px);
        @include breakpoint(sm) {
            font-size: 2.4rem;
            line-height: 2.6rem;
        }
        @include breakpoint(md) {
            font-size: 2.6rem;
            line-height: 2.9rem;
        }
        @include breakpoint(xl) {
            font-size: rem(48px);
            line-height: rem(52px);
        }
    }

    @include theme(br) {
        letter-spacing: -0.02em;
        font-family: var(--font_family_heading_bold);
        font-size: rem(28px);
        line-height: (36px);

        @include breakpoint(lg) {
            font-size: rem(38px);
            line-height: rem(48px);
        }
    }

    @include theme(wb) {
        letter-spacing: -0.28px;
        font-family: var(--font_family_heading);
        font-size: rem(28px);
        line-height: (30px);
        text-transform: uppercase;

        @include breakpoint(lg) {
            letter-spacing: -0.38px;
            font-size: rem(38px);
            line-height: (42px);
        }
    }
}

.heading-3 {
    @include rem(font-size, 32px);

    @include breakpoint(lg) {
        @include rem(font-size, 36px);
    }

    @include breakpoint(sm, down) {
        @include theme(br) {
            font-family: var(--font_family_heading_semibold);
        }
    }

    @include theme(br) {
        letter-spacing: -0.02em;
        font-family: var(--font_family_heading_bold);
        font-size: rem(24px);
        line-height: (32px);

        @include breakpoint(lg) {
            font-size: rem(28px);
            line-height: rem(36px);
        }
    }

    @include theme(wb) {
        letter-spacing: -0.24px;
        font-family: var(--font_family_heading);
        font-size: rem(24px);
        line-height: (28px);
        text-transform: uppercase;

        @include breakpoint(lg) {
            letter-spacing: -0.28px;
            font-size: rem(28px);
            line-height: (32px);
        }
    }
}

.heading-4 {
    @include rem(font-size, 20px);

    @include breakpoint(lg) {
        @include rem(font-size, 24px);
    }

    @include theme(dh) {
        font-family: var(--font_family_heading_medium);
        text-transform: none;
    }

    @include theme(br) {
        letter-spacing: -0.02em;
        font-family: var(--font_family_heading_semibold);
        font-size: rem(20px);
        line-height: rem(28px);

        @include breakpoint(lg) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    @include theme(wb) {
        letter-spacing: -0.2px;
        font-family: var(--font_family_heading);
        font-size: rem(20px);
        line-height: (24px);
        text-transform: uppercase;

        @include breakpoint(lg) {
            letter-spacing: -0.24px;
            font-size: rem(24px);
            line-height: (26px);
        }
    }
}

.heading-5 {
    @include theme(br) {
        letter-spacing: -0.02em;
        font-family: var(--font_family_heading_semibold);
        font-size: rem(16px);
        line-height: rem(24px);

        @include breakpoint(lg) {
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    @include theme(wb) {
        letter-spacing: -0.16px;
        font-family: var(--font_family_heading);
        font-size: rem(16px);
        line-height: (20px);
        text-transform: uppercase;

        @include breakpoint(lg) {
            letter-spacing: -0.2px;
            font-size: rem(20px);
            line-height: (24px);
        }
    }
}

.heading-6 {
}

.eyebrow {
    text-transform: uppercase;
    letter-spacing: var(--font_letterspace_wide);
    line-height: 25 / 14 + em;
    font-weight: bold;

    @include theme(dh) {
        font-family: var(--font_family_heading_bold);
        font-weight: normal;
    }

    @include theme(br) {
        font-family: var(--font_family_heading_semibold);
        text-transform: none;
        letter-spacing: -0.02em;
    }

    @include theme(wb) {
        font-family: var(--main);
        letter-spacing: 0.64px;
        font-weight: 700;
        line-height: 20px;
        color: black;
    }

    &--wide {
        letter-spacing: var(--font_letterspace_extra_wide);
    }

    &--badge {
        display: inline-block;
        background-color: #464646;
        color: white;
        border-radius: 100px;
        padding: 3px 10px;
        @include rem(font-size, 10px);
        @include rem(line-height, 18px);

        @include theme(dh) {
            background-color: $colors_body_black;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.uppercase {
    text-transform: uppercase;
}

p {
    @include theme(br) {
        letter-spacing: -0.02em;
    }
}

strong {
}

i {
}
