.market-highlights {
    margin: 70px 0;
    overflow: hidden;

    @include breakpoint($breakpoint_desktop_nav) {
        margin: 90px 0;
    }

    &__heading {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 28px;
        line-height: 36px;

        @include breakpoint($breakpoint_desktop_nav) {
            margin-top: 25px;
            font-size: 52px;
            line-height: 72px;
        }
    }

    &__link {
        margin-top: 15px;

        @include breakpoint($breakpoint_desktop_nav) {
            margin-top: 25px;
        }
    }

    .tile__description {
        white-space: normal;
    }

    @include breakpoint(xs, down) {
        .tile {
            min-height: 0;
            margin-bottom: $layout_page_padding;
            display: flex;
            align-items: flex-start;

            .tile__poster {
                flex-basis: 30%;
                min-width: 100px;
                max-width: 200px;
                margin-left: $layout_page_padding;
                @include aspect-ratio(1, 1);
            }

            .tile__content {
                flex-basis: 70%;
                padding: 3px $layout_page_padding 0 15px;
            }

            .tile__title {
                margin-bottom: 2px;
            }

            .tile__description {
                line-height: rem(22px);
                margin-bottom: 2px;
            }

            .tile__readmore {
                align-self: flex-start;
            }
        }
    }

    @include breakpoint(sm, down) {
        &__container {
            padding-left: 0;
        }

        &__heading,
        &__link {
            margin-left: $layout_page_padding;
            margin-right: $layout_page_padding;
        }
    }

    @include breakpoint(sm) {
        .tile {
            text-align: center;
        }

        .tile__content {
            padding-top: 40px;
        }

        .tile__title {
            justify-content: center;
        }

        .tile__readmore {
            align-self: center;
        }

        &__heading {
            margin-bottom: 35px;
        }
    }
}
