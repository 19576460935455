// CSS Variables

// defaults (the rocks)

:root {
    // colors
    @include setCssVar(colors_body_black, black);
    @include setCssVar(colors_heading_black, #2a2a2a);

    @include setCssVar(colors_highlight, #ffee67);
    @include setCssVar(colors_highlight_light, #f5c35f);
    @include setCssVar(colors_blue_light, #e8f1f2);
    @include setCssVar(colors_green_light, #eef2e8);
    @include setCssVar(colors_maroon, #00758d);
    @include setCssVar(colors_teal, #00758d);

    @include setCssVar(colors_tenant_shopping, #1a8dd3);
    @include setCssVar(colors_tenant_eat_and_drink, #f6525b);
    @include setCssVar(colors_tenant_stay, #17c3a9);
    @include setCssVar(colors_tenant_market, #00758d);
    @include setCssVar(colors_tenant_things_to_do, #854799);

    // fonts
    @include setCssVar(font_family_main, ("Lato", sans-serif));
    @include setCssVar(font_family_heading, ("Libre Baskerville", serif));

    @include setCssVar(font_letterspace_heading, -0.005em);
    @include setCssVar(font_letterspace_wide, 0.25em);
    @include setCssVar(font_letterspace_med_wide, 0.25em);
    @include setCssVar(font_letterspace_extra_wide, 0.6em);
}

// darling harbour theme

.theme_dh {
    // colors
    @include setCssVar(colors_body_black, #1e1e1e);
    @include setCssVar(colors_heading_black, #1e1e1e);

    @include setCssVar(colors_highlight, #eb044e);
    @include setCssVar(colors_highlight_light, #c83c87);
    @include setCssVar(colors_blue_light, #bfe6e6);
    @include setCssVar(colors_green_light, #cdf2ea);
    @include setCssVar(colors_maroon, #f04141);
    @include setCssVar(colors_teal, #9be5d5);

    @include setCssVar(colors_tenant_shopping, #0069b4);
    @include setCssVar(colors_tenant_eat_and_drink, #f04141);
    @include setCssVar(colors_tenant_stay, #009b9b);
    @include setCssVar(colors_tenant_market, #fa914b);
    @include setCssVar(colors_tenant_things_to_do, #854799);

    // fonts
    @include setCssVar(font_family_heading_regular, ("BrutalType", serif));
    @include setCssVar(
        font_family_heading_medium,
        ("BrutalType-Medium", serif)
    );
    @include setCssVar(font_family_heading_bold, ("BrutalType-Bold", serif));
    @include setCssVar(
        font_family_heading_extrabold,
        ("BrutalType-ExtraBold", serif)
    );

    @include setCssVar(font_family_main, ("Roboto", sans-serif));
    @include setCssVar(
        font_family_heading,
        (var(--font_family_heading_extrabold), serif)
    );

    @include setCssVar(font_letterspace_heading, -0.02857142857em);
    @include setCssVar(font_letterspace_heading_regular, -0.0165em);
    @include setCssVar(font_letterspace_wide, 0.133125em);
    @include setCssVar(font_letterspace_med_wide, 0.16666666667em);
    @include setCssVar(font_letterspace_extra_wide, 0.42857142857em);
}

// barangaroo theme

.theme_br {
    // colors
    @include setCssVar(colors_body_black, #2A2A2A);
    @include setCssVar(colors_heading_black, #2A2A2A);

    @include setCssVar(colors_highlight, #1E5BC0);
    @include setCssVar(colors_highlight_light, #c83c87);
    @include setCssVar(colors_rust, #DF725C);
    @include setCssVar(colors_sandstone, #F3D6A0);
    @include setCssVar(colors_blue_light, #bfe6e6);
    @include setCssVar(colors_maroon, #f04141);
    @include setCssVar(colors_teal, #9be5d5);
    @include setCssVar(colors_pale_leaf, #dbeddd);
    @include setCssVar(colors_orange, #EDA65A);
    @include setCssVar(colors_cream, #F8E6C6);

    @include setCssVar(colors_tenant_shopping, #00A9DD);
    @include setCssVar(colors_tenant_eat_and_drink, #F6525B);
    @include setCssVar(colors_tenant_stay, #0DBD88);
    @include setCssVar(colors_tenant_market, #fa914b);
    @include setCssVar(colors_tenant_things_to_do, #854799);

     // fonts
     @include setCssVar(font_family_heading_regular, ("Sora", serif));
     @include setCssVar(
        font_family_heading_medium,
        ("Sora-Medium", serif)
     );
     @include setCssVar(font_family_heading_bold, ("Sora-Bold", serif));
     @include setCssVar(font_family_heading_semibold, ("Sora-SemiBold", serif));
     @include setCssVar(font_family_heading_extrabold, ("Sora-ExtraBold", serif));
     @include setCssVar(font_family_main, ("Sora", serif));
     @include setCssVar(
        font_family_heading,
        (var(--font_family_heading_extrabold), serif)
     );

    @include setCssVar(font_letterspace_heading, -0.02857142857em);
    @include setCssVar(font_letterspace_heading_regular, -0.02857142857);
    @include setCssVar(font_letterspace_wide, 0.133125em);
    @include setCssVar(font_letterspace_med_wide, 0.16666666667em);
    @include setCssVar(font_letterspace_extra_wide, 0.42857142857em);
}

// whitebay theme

.theme_wb {
    // colors
    @include setCssVar(colors_body_black, black);
    @include setCssVar(colors_heading_black, #2A2A2A);
    @include setCssVar(colors_grey, #505050);
    @include setCssVar(colors_highlight, #7DA186);
    @include setCssVar(colors_soft_yellow, #FDE79A);
    @include setCssVar(colors_rust, #C45123);
    @include setCssVar(colors_pale_blue, #CBEDFD);

     // fonts
    @include setCssVar(font_family_main, ("Public Sans", sans-serif));
    @include setCssVar(font_family_heading, ("WhiteBay-Medium", serif));
}
