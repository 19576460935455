$filter_margin: 3px;
$filter_padding: 29px;
$filter_height: 70px;

.filter-container,
.date-container {
    @include breakpoint($breakpoint_desktop_nav) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        & > * {
            flex: 0 0 auto;
        }
    }
}

.filter-input,
.filter-dropdown {
    @include theme(dh) {
        .cta {
            @extend .cta--small;
            padding: 15px;
            background: white;
            color: $colors_black_med;
            border-color: white;

            &:hover {
                background: $colors_black_med;
                color: white;
                border-color: $colors_black_med;
            }
        }
    }

    @include theme(br) {
        .cta {
            @extend .cta--small;
            padding: 15px;
            background: var(--colors_rust);
            color: black;
            border-color: var(--colors_rust);

            &:hover {
                background: black !important;
                color: white;
                border-color: black;
            }
        }
    }

    input.radio,
    input.checkbox {
        @extend .visually-hidden;
        padding: 0;
        border: none;
        &:hover,
        &:focus {
            &[type="checkbox"],
            &[type="radio"] {
                & ~ .indicator:before {
                    opacity: 0.75;
                }
            }
        }
    }
}

.date-container {
    width: 100%;
    input[type="checkbox"] {
        border: none;
        padding: 0;
        width: 0;
    }
    .filter-dropdown__label:before {
        display: none;
    }

    .date-range-radio {
        position: absolute;
        right: 29px;
        top: calc(50% - 15px);
    }
    @include breakpoint(sm, down) {
        .has-values.filter-dropdown__label__inner {
            flex-direction: column;
            align-items: flex-start;

            .filter-dropdown__label__selected {
                padding-left: 0;
                padding-top: 10px;
            }
        }
    }
}

.date-input {
    border: 1px solid rgba(255, 0, 0, 0);
    .eyebrow {
        position: absolute;
        top: 0;
        left: $filter_padding;
        width: 100%;

        @include breakpoint($breakpoint_desktop_nav) {
            top: 0;
            left: 0;
        }
    }

    &.error {
        .eyebrow:after {
            content: "Invalid date";
            font-size: rem(8px);
            position: absolute;
            bottom: -60px;
            left: 0;
            color: $colors_red;
            @include breakpoint($breakpoint_desktop_nav) {
                bottom: -55px;
                left: 0;
            }
        }
        .datefield {
            border-bottom-color: $colors_red;
        }
    }

    .datefield {
        margin-top: 10px;
        padding-left: 0;
        background: none;
        border: none;
        border-bottom: 1px solid #757575;
        flex-basis: 100%;
        height: rem(38px);
        font-size: rem(18px);
        margin-bottom: 8px;
        color: $colors_grey_light;
        &:focus {
            outline: none;
            border-bottom-color: white;
        }
        @include breakpoint($breakpoint_desktop_nav) {
            //
        }
    }

    a.icon {
        position: absolute;
        right: $filter_padding;
        bottom: rem(32px);
        color: var(--colors_highlight);
        font-size: rem(24px);
        line-height: rem(24px);
        text-decoration: none;

        @include theme(dh) {
            color: #99e4d5;
        }

        @include theme(br) {
            color: var(--colors_rust);
        }

        @include breakpoint($breakpoint_desktop_nav) {
            right: 0;
            bottom: rem(25px);
        }
    }
}

.datepicker {
    display: none;
    transition: all 300ms;
    opacity: 0;
    z-index: 100;

    &.is-active {
        opacity: 1;
        display: block;
    }

    @include breakpoint($breakpoint_desktop_nav) {
        position: absolute;
        transform: none;
    }
}

.filter-input {
    position: relative;
    display: flex;
    //flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding: 15px $filter_padding;
    @include rem(font-size, 12px);
    color: white;
    text-transform: uppercase;
    transition: all 300ms;

    @include theme(br) {
        text-transform: capitalize;
    }

    @include theme(wb) {
        .eyebrow {
            color: white !important;
        }
    }

    @include breakpoint($breakpoint_desktop_nav) {
        padding-top: 0;
        padding-bottom: 0;
        min-height: $filter_height;
        margin: 0 $filter_margin $filter_margin 0;
        background: rgba(black, 0.4);
    }

    &:last-child {
        margin-left: 0;

        // @include breakpoint(sm, down) {
        //     padding-bottom: 25px;
        // }
    }

    &:first-child {
        // @include breakpoint(sm, down) {
        //     padding-top: 25px;
        // }
    }

    input.radio,
    input.checkbox {
        &[type="checkbox"] ~ .indicator {
            @extend .icon;
            @extend .icon-tick;
            margin-left: 15px;
            border: none;
            line-height: 1;
        }

        &[type="radio"] ~ .indicator {
            &:before {
                content: "";
            }
            margin-left: 15px;
            border: none;
            line-height: 1;
        }

        & ~ .indicator:before {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border: 1px solid;
            border-radius: 100%;
            font-size: 0.5em;
            color: white;
            opacity: 0.5;
            transition: all 300ms;
        }

        &:checked {
            & ~ .indicator:before {
                opacity: 1;
                background: var(--colors_highlight);
                color: black;
                border-color: var(--colors_highlight);

                @include theme(dh) {
                    border-color: #99e4d5;
                    background: #99e4d5;
                }

                @include theme(br) {
                    background: var(--colors_rust);
                    border-color: var(--colors_rust);
                }

                @include theme(wb) {
                    color: black;
                }
            }

            & ~ span {
                color: var(--colors_highlight);

                @include theme(dh) {
                    color: #99e4d5;
                }

                @include theme(br) {
                    color: var(--colors_rust);
                }
            }
        }
    }

    input.textfield {
        color: red;
    }
}

.filter-dropdown {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: $filter_margin;
    margin: 0 3px 3px 0;

    @include breakpoint(sm, down) {
        margin-bottom: 0;
    }

    .filter-input {
        margin: 0;
    }

    &__label {
        flex: 1 1 auto;
        position: relative;
        @extend .icon;
        @extend .icon-chevron-down;
        padding: 25px $filter_padding;

        .date-container & {
            padding: 15px $filter_padding;
        }

        // .date-container:last-child & {
        //     &:last-child {
        //         padding-bottom: 25px;
        //     }
        // }

        @include breakpoint($breakpoint_desktop_nav) {
            flex-wrap: wrap;
            padding-top: 0;
            padding-bottom: 0;
        }

        &:before {
            order: 2;
            color: var(--colors_highlight);
            font-size: 0.7em;
            margin-left: 15px;
            transform: rotate(-90deg);

            @include theme(dh) {
                color: #99e4d5;
            }

            @include theme(br) {
                color: var(--colors_rust);
            }

            @include breakpoint($breakpoint_desktop_nav) {
                transform: none;
            }
        }

        &__inner {
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            padding-right: 0;

            .date-container & {
                padding-right: 50px;
            }

            @include breakpoint($breakpoint_desktop_nav) {
                display: block;
            }
        }

        &__text {
            flex: 1 1 auto;

            @include breakpoint($breakpoint_desktop_nav) {
                display: block;
                order: 1;
                flex: 1 1 100%;

                .has-values & {
                    @include rem(font-size, 9px);
                    opacity: 0.7;

                    @include theme(br) {
                        font-size: rem(10px);
                    }
                }
            }

            @include theme(wb) {
                color: white;
            }
        }

        &__selected {
            flex: 0 0 auto;
            padding-left: 10px;
            text-transform: none;
            letter-spacing: 0;
            font-weight: normal;
            @include rem(font-size, 14px);

            @include theme(br) {
                font-size: rem(12px);
            }

            @include theme(wb) {
                color: white;
            }

            @include breakpoint($breakpoint_desktop_nav) {
                display: block;
                order: 0;
                flex: 1 1 100%;
                padding-left: 0;
                @include rem(font-size, 16px);
                overflow: hidden;

                @include theme(br) {
                    font-size: rem(12px);
                }
            }
        }
    }

    &__dropdown {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        background: $colors_black_med;
        z-index: $layout_nav_zindex + 10;
        top: 0;
        right: -80vw;
        width: 80vw;
        height: 100vh;
        //transform: translateY(100%);
        transition: transform 500ms;

        @include breakpoint($breakpoint_desktop_nav) {
            display: none;
            position: absolute;
            z-index: 10;
            top: $filter_height;
            left: 0;
            right: auto;
            width: 300px;
            height: auto;
            padding: 25px $filter_padding;
        }

        &__inner {
            flex: 1 1 100%;
            padding: 10px 0;
            overflow: auto;
            -webkit-overflow-scrolling: touch;

            @include breakpoint($breakpoint_desktop_nav) {
                padding: 0;
            }

            [data-animate-height] {
                overflow: hidden;
                transition: height 500ms;
            }
        }

        &__footer {
            padding: 25px 30px 100px;

            @include breakpoint($breakpoint_desktop_nav) {
                padding: 25px 0 10px 0;
            }

            button {
                text-transform: uppercase;
                @include theme(br) {
                    text-transform: capitalize;
                }
            }
        }

        .listing-page__filter-label {
            flex: 0 0 auto;
            @include breakpoint($breakpoint_desktop_nav) {
                display: none;
            }
        }
    }

    &__option {
        min-height: 0;
        background: none;
        padding: 15px $filter_padding;

        @include breakpoint($breakpoint_desktop_nav) {
            padding: 7px 0;
        }

        &.filter-input {
            input {
                &:after {
                    content: none;
                }
            }
        }
    }

    // dropdown underlay
    &__underlay {
        content: "";
        @extend .animated;
        @extend .fadeIn;
        position: fixed;
        z-index: $layout_nav_zindex + 1 !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(black, 0.4);
        animation-duration: 500ms;
        display: none;
    }

    &__toggle {
        width: 0;
        border: 0;
        &:focus + label {
            outline: auto;
        }

        &:checked {
            & ~ .filter-dropdown__underlay {
                display: block;
            }

            & ~ .filter-dropdown__label {
                z-index: $layout_nav_zindex + 10;
                color: var(--colors_highlight);
                background: $colors_black_med;

                @include theme(dh) {
                    color: #99e4d5;
                }

                @include theme(br) {
                    color: var(--colors_rust);
                }
            }

            & ~ .filter-dropdown__dropdown {
                z-index: $layout_nav_zindex + 10;
                display: flex;
                transform: translate3d(-100%, 0, 0);

                @include breakpoint($breakpoint_desktop_nav) {
                    display: block;
                    transform: none;
                }
            }
        }
    }
}
