.file-downloads {
    padding-top: 30px;
    margin-bottom: 80px;

    @include theme(br) {
        @include breakpoint(sm) {
            margin-bottom: 150px;
        }
    }

    &__title {
        padding-bottom: 30px;
    }
    &__list {
        padding: 0;
        margin: 0;
        width: 100%;
        @include breakpoint(md) {
            width: 50%;
        }
    }

    &__item {
        list-style: none;
        padding: 20px 0;
        border-top: 1px solid #f2f2f2;
        &:last-child {
            border-bottom: 1px solid #f2f2f2;
        }
    }

    &__link {
        display: flex;
        text-decoration: none;
        &:hover,
        &:focus,
        &:active {
            outline: none;
            .file-downloads__icon {
                color: white;
                background-color: $colors_grey_dark;
            }
        }
    }

    &__icon {
        flex: 0 0 53px;
        background-color: var(--colors_highlight);
        height: 53px;
        width: 53px;
        display: inline-flex !important;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        font-size: 28px;
        color: black;
        transition: all 100ms linear;

        @include theme(dh) {
            &,
            * {
                color: white;
            }
        }

        @include theme(br) {
            &,
            * {
                color: white;
            }
        }
        @include theme(wb) {
            background-color: black;
            color: white;
        }
    }

    &__fileinfos {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__filename {
        font-family: var(--font_family_heading);
        font-size: rem(18px);
        line-height: rem(22px);
        color: $colors_grey_dark;
        text-decoration: none;
    }

    &__filemeta {
        font-size: rem(14px);
        line-height: rem(17px);
        color: #757575;
    }
}
