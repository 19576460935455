.footer {
    background: $colors_grey_dark;
    z-index: 1;
    transform-style: preserve-3d;
    overflow: hidden;

    @include theme(dh) {
        background-color: $colors_grey_dark;
        @include facet("light", 0.04, 100%, auto);

        @include breakpoint($breakpoint_desktop_nav) {
            @include facet("light", 0.04, auto, 100%);
        }
    }

    @include theme(br) {
        position: relative;
        background-color: var(--colors_cream);

        &:after {
            content: '';
            position: absolute;
            top: -150px;
            z-index: 1;
            left: 0;
            width: 26%;
            height: 148px;
            background-image: url("/assets/media/barangaroo-lines-footer.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
        }
    }

    @include theme(wb) {
        background-color: black;
    }

    &__br_graphic {
        position: relative;
        overflow: hidden;
        padding-bottom: 150px;
        margin-top: -140px;
        height: 0;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            z-index: 1;
            left: 0;
            width: 26%;
            height: 148px;
            background-image: url("/assets/media/barangaroo-lines-footer.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: left center;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &--desktop {
            display: block;
            background-image: url("/assets/media/barangaroo-shape-footer.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom center;
        }

        &--mobile {
            display: none;
        }

        @include breakpoint(sm, down) {
            padding-bottom: 50px;
            margin-top: -30px;
            overflow: visible;

            &--desktop {
                display: none;
            }

            &--mobile {
                display: block;
                background-image: url("/assets/media/barangaroo-shape-footer-mobile.png");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: bottom center;
            }

            &:before {
                width: 100%;
                background-image: url("/assets/media/barangaroo-lines-footer-mobile.png");
                height: 68px;
                top: -20px;
                background-position: left center;
            }
        }
    }

    &__inner {
        display: flex;
        color: white;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-top: 50px;
        padding-bottom: 20px;

        @include theme(br) {
           color: black;
        }
    }

    &__col {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        flex-basis: 100%;

        @include theme(br) {
            @include breakpoint(xs, down) {
                flex-direction: row;
            }
        }
    }

    &__logos {
        flex-basis: 100%;
        padding-bottom: 25px;

        @include theme(dh) {
            @include breakpoint(sm) {
                padding-right: 50px;
            }
        }

        .logo-lockup {
            font-size: rem(88px);
        }

        @include breakpoint(sm, down) {
            .logo-lockup {
                font-size: rem(70px);
            }
        }
    }

    &__newsletter {
        position: relative;
        flex-basis: 100%;
        background-color: #2d2d2d;
        margin: 0 -#{$layout_page_padding} 30px;
        padding: 30px $layout_page_padding;
        z-index: 5;

        @include theme(br) {
            color: black;

            .newsletter-form {
                &__heading {
                    color: black;
                }

                .react-form__field__label__inner {
                    color: var(--colors_grey) !important;
                }

                .react-form__field__label,
                label {
                    color: var(--colors_grey) !important;
                }

                input {
                    color: var(--colors_grey) !important;
                    border-bottom: 1px solid black;
                }
            }
        }

        .react-form__success-message {
            > * {
                color: #ffffff;
            }

            @include theme(br) {
                > * {
                    color: black;
                }

                .icon {
                    color: black;
                }

                @include breakpoint(xs, down) {
                    margin-top: 0;
                }
            }
        }

        @include theme(dh) {
            @include breakpoint(xs, down) {
                background-color: $colors_grey_dark;
            }
        }

        @include theme(br) {
            @include breakpoint(xs, down) {
                background-color: var(--colors_cream);
                margin: 0 0 30px;
                padding: 0 0 30px 0;
            }
        }

        @include theme(wb) {
            @include breakpoint(xs, down) {
                background-color: black;
            }
        }
    }

    &__acknoledgement {
        font-family: var(--font_family_heading);
        font-size: rem(16px);
        line-height: 32px;
        padding-bottom: 30px;

        @include breakpoint(sm, down) {
            font-size: rem(14px);

            @include theme(br) {
                line-height: rem(24px);
            }
        }

        @include theme(dh) {
            font-family: var(--font_family_main);
            font-size: rem(16px);
        }

        @include theme(br) {
            font-family: var(--font_family_heading_regular);
            letter-spacing: -0.02rem;
        }

        @include theme(wb) {
            font-family: var(--font_family_main);
            font-size: rem(14px);
            letter-spacing: -0.28px;
            line-height: 22px;
        }
    }

    &__link {
        // display: inline;
        display: inline-block;
        font-family: var(--font_family_main);
        font-size: rem(16px);
        line-height: 19px;
        opacity: 0.9;
        color: #ffffff;
        text-decoration: none;
        margin-bottom: calc(20px - 0.2em);
        padding-bottom: 0.2em;
        margin-right: 20px;

        &:hover {
            box-shadow: inset 0 -0.05em 0 white;
        }

        li &--subnav {
            color: #bcbcbc;

            @include theme(br) {
                color: black;
                display: inline-block;
                margin-bottom: calc(18px - 0.2em);
            }
        }

        &--social {
            white-space: nowrap;
            @extend .icon;

            &:before {
                margin-right: 15px;
                display: inline-block;
                width: 20px;
                transition: all 100ms;
                transform-origin: center left;
            }

            &--instagram {
                @extend .icon-instagram_footer;
                &:before {
                    transform: scale(1.2);
                }
            }
            &--facebook {
                @extend .icon-facebook;
                &:before {
                    transform: translateX(2px);
                }
            }
            &--twitter {
                @extend .icon-twitter;
                &:before {
                    transform: scale(0.9);
                }
            }
            &--youtube {
                @extend .icon-youtube;
                &:before {
                    transform: scale(0.8) translateY(2px);
                }
            }
            &--tiktok {
                @extend .icon-tiktok;
                &:before {
                    transform: scale(1) translateY(2px);
                }
            }
            &--linkedin {
                @extend .icon-linkedin;
                &:before {
                    transform: scale(0.9) translateY(2px);
                }
            }

            &:hover {
                box-shadow: none;
                &:before {
                    margin-right: 8px;
                }
            }
        }

        @include theme(br) {
            letter-spacing: -0.02em;
            margin-bottom: calc(15px - 0.2em);
            color: black;
        }
    }

    &__links__container {
        flex-basis: 50%;
        display: flex;
        justify-content: flex-end;

        @include breakpoint(sm, down) {
            justify-content: flex-start;
            flex-direction: column;
        }
    }

    &__links {
        margin: 0;
        margin-bottom: 30px;
        padding: 0;
        list-style: none;

        &--primarynav {
            flex-basis: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;

            &.per-col-1 {
                max-height: 80px;
            }

            &.per-col-2 {
                max-height: 130px;
            }

            &.per-col-3 {
                max-height: 160px;
            }

            &.per-col-4 {
                flex-basis: 50%;
                max-height: 190px;

                @include breakpoint(sm, down) {
                    flex-basis: 100%;
                    flex-direction: column;
                    max-height: unset;
                }
            }

            li {
                flex-basis: 50%;

                .footer__link {
                    @include breakpoint(sm, up) {
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                }
            }

            @include theme(br) {
                margin-bottom: 45px;
            }
        }

        &--subnav,
        &--socialnav {
            flex-basis: 50%;
        }
    }

    @include breakpoint(sm) {
        &__col {
            flex-direction: row;
            &:first-child {
                flex-basis: (1/3) * 100%;
            }
            &:last-child {
                flex-basis: (2/3) * 100%;
            }
        }

        &__inner {
            padding-top: 70px;
        }

        &__container {
            flex-wrap: nowrap;
            padding-top: 80px;
            padding-bottom: 30px;
        }

        &__newsletter {
            background: none;
            padding: 0;
            margin: 0;
        }

        &__newsletter,
        &__logos {
            height: 100px;
            padding-bottom: 0;

            @include theme(dh) {
                height: 100px;
            }
        }

        &__logos-wrapper {
            height: 90px;
        }

        &__logo-divider {
            margin: 0 24px;
        }

        &__acknoledgement {
            width: 80%;
            font-size: rem(16px);
            line-height: rem(28px);
        }

        &__links {
            &--primarynav {
                flex-basis: 100%;
                display: grid;
                grid-auto-flow: column;
                grid-template-rows: repeat(4, auto);
                grid-gap: 20px;
            }

            &--subnav {
                flex-basis: 25%;
            }
            &--socialnav {
                flex-basis: 23%;
            }
        }

        &__link--social {
            margin-right: 0;
            &:before {
                margin-right: 30px;
            }
            &:hover {
                &:before {
                    margin-right: 23px;
                }
            }
        }
    }

    @include breakpoint(xl) {
        &__logos {
            margin-top: 20px;
            margin-bottom: 20px;

            .logo-lockup {
                font-size: rem(90px);
            }
        }
    }
}
