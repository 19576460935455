.related-content {
    position: relative;
    padding: 55px 0;
    background: $colors_bg_beige;
    overflow: hidden;

    @include theme(dh) {
        background: #cdf2ea;
        @include facet("dark", 0.2, 100%, auto);

        @include breakpoint($breakpoint_desktop_nav) {
            @include facet("dark", 0.2, auto, 100%);
        }
    }

    @include theme(wb) {
        background: var(--colors_soft_yellow);

        .cta {
            background: black;
            border-color: black;
            color: white;
        }

        &:before {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            display: block;
            width: 450px;
            height: 450px;
            background-image: url("../assets/media/texture-related-content.png");
            background-repeat: no-repeat;
            background-position: right top;
            background-size: contain;

            @include breakpoint(xs, down) {
                top: 100px;
                height: 250px;
                width: 250px;
            }
        }
    }

    @include theme(br) {
        background-color: var(--colors_sandstone);

        &:before {
            content: "";
            position: absolute;
            z-index: 1;
            right: 0;
            display: block;
            width: 40%;
            height: 115px;
            background-image: url("../assets/media/barangaroo-lines-rust-right.png");
            background-repeat: no-repeat;
            background-position: right top;
            background-size: contain;

            @include breakpoint(xs, down) {
                top: 90px;
                height: 80px;
                width: 74%;
            }
        }

        @include breakpoint(xs, down) {
            padding: 80px 0 100px;
        }
    }

    .map-detail-card & {
        padding: 48px 0;
    }

    @include breakpoint(md) {
        padding: 135px 0;

        @include theme(br) {
            padding: 135px 0 180px;
        }
    }

    @include breakpoint(md, only) {
        padding-left: 140px;
    }

    @include breakpoint(lg, only) {
        padding-left: 140px;
    }

    &__heading {
        margin: 0 0 50px;
        font-size: rem(24px);
        line-height: rem(44px);

        @include theme(dh) {
            font-family: var(--font_family_heading_bold);
            text-transform: uppercase;
        }

        @include theme(br) {
            font-family: var(--font_family_heading_bold);
            color: black;
            position: relative;
            z-index: 2;
            margin-bottom: 30px;

            @include breakpoint(xs, down) {
                margin-bottom: 55px;
            }
        }

        @include breakpoint(md) {
            font-size: rem(42px);
            line-height: rem(82px);
        }
        @include breakpoint(sm) {
            margin: 0 0 20px;
        }
    }

    &__tiles {
        margin-bottom: -$layout_page_padding;

        & > *:nth-child(even) {
            margin-top: 50px;
        }

        @include theme(br) {
            position: relative;
            z-index: 2;
        }

        @include theme(wb) {
            position: relative;
            z-index: 2;
        }
    }

    &__next-btn {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        right: 20px;
        height: 58px;
        width: 58px;
        border-radius: 100%;
        font-size: 14px;
        color: var(--colors_body_black);
        background: var(--colors_highlight);
        transform: translateY(-50%);
        margin-top: 0 !important;
        z-index: 2;

        @include theme(dh) {
            color: white;
        }

        @include theme(br) {
            background: var(--colors_rust);
            color: #131415;
        }
    }

    .tile {
        min-height: 0;
        margin-bottom: $layout_page_padding;
    }

    &__link {
        margin-top: 40px;

        @include breakpoint($breakpoint_desktop_nav) {
            margin-top: 60px;
        }
    }

    @include breakpoint(sm, down) {
        @include theme(br) {
            &:before {
                background-position: right 40%;
            }
        }
    }

    @include breakpoint(xs, down) {
        &__tiles {
            > * {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                overflow-x: auto;
                padding: 0;
                margin-left: -10px;
            }
            [flexible-grid-item] {
                flex: 0 1 auto;
                min-width: 330px;
            }
        }
    }
}

.always-xs .related-content {
    padding: 55px 0;
    background: $colors_bg_beige;
    overflow: hidden;

    @include theme(dh) {
        background: #cdf2ea;
        @include facet("dark", 0.2, 100%, auto);
    }

    @include theme(br) {
        background: var(--colors_sandstone);
    }

    .map-detail-card & {
        padding: 48px 0;
    }

    &__heading {
        margin-top: 0;
        font-size: rem(24px);
        line-height: rem(44px);
    }

    .tile {
        min-height: 0;
        margin-bottom: $layout_page_padding;
    }

    &__tiles {
        margin-bottom: -$layout_page_padding;

        & > *:nth-child(even) {
            margin-top: 50px;
        }

        > * {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto;
            padding: 0;
            margin-left: -10px;
        }

        [flexible-grid-item],
        > div > .tile {
            display: flex;
            flex-direction: column;
            flex: 0 1 auto;
            min-width: 330px;

            .tile__content {
                flex: 1 1 auto;
            }
        }

        @include theme(br) {
            position: relative;
            z-index: 2;
        }
    }
}
