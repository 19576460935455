@mixin youtube() {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .youtube-player {
        position: static;
        &:before {
            content: none;
        }
        iframe {
            box-sizing: border-box;
            height: 100%;
            left: 50%;
            min-height: 130%;
            min-width: 100%;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            width: 177.777777785%;
            pointer-events: none;
        }
    }

    .youtube-player__iframe {
        z-index: auto;
    }
}
