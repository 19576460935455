.vertical-listing {
    margin: 55px 0;
    overflow: hidden;

    @include breakpoint(md) {
        margin: 135px 0;
    }

    &__tile-wrapper {
        position: relative;
        &:after {
            position: absolute;
            content: "";
            bottom: -35px;
            width: 55%;
            height: 1px;
            background-color: #f2f2f2;
        }
        &:last-child:after {
            display: none;
        }
        @include breakpoint(xs, only) {
            &:after {
                display: none;
            }
        }
        @include breakpoint(md, only) {
            &:after {
                bottom: -15px;
            }
        }
        @include breakpoint(sm) {
            .tile__content {
                padding-bottom: 20px;
            }
        }
    }

    &__heading {
        margin-top: 0;
    }
}
