// SASS Variables

$colors_body_black: black;
$colors_heading_black: #2a2a2a;

$colors_highlight: #ffee67;
$colors_highlight_light: #f5c35f;

$colors_blue_light: #e8f1f2;
$colors_green_light: #eef2e8;
$colors_maroon: #00758d;
$colors_teal: #00758d;
$colors_brown: #7a392d;
$colors_red: #ff0000;
$colors_red_dark: #b00020;

$colors_black_med: #2d2d2d;

$colors_grey_dark: #1e1e1e;
$colors_grey_med_dark: #353535;
$colors_grey_med: #505050;
$colors_grey_75: #757575;
$colors_grey_med_light: #868686;
$colors_grey_light_dark: #e2e2e2;
$colors_grey_light: #f2f2f2;
$colors_grey_background: #f6f6f6;

$colors_bg_beige: #f8f8f8;

$colors_tenant_shopping: #1a8dd3;
$colors_tenant_eat_and_drink: #f6525b;
$colors_tenant_stay: #17c3a9;
$colors_tenant_market: #00758d;
$colors_tenant_things_to_do: #854799;

$content_page_bg: #f8f8f8;
