$theme-names: (
    "tr": ".theme_tr",
    "dh": ".theme_dh",
    "br": ".theme_br",
    "wb": ".theme_wb",
) !default;

@mixin theme($name) {
    // If the key exists in the map
    @if map-has-key($theme-names, $name) {
        // Prints a media query based on the value
        #{inspect(map-get($theme-names, $name))} & {
            @content;
        }
    }
}
