$collage_offset: 17.20430107526882%;

.market-info-collage {
    position: relative;
    margin-bottom: 80px;

    .cta {
        margin-top: 30px;
        @include breakpoint(md) {
            display: inline-flex;
            width: auto;
        }

        @include theme(wb) {
            @extend .cta--dark;

            &:hover {
                @extend .cta--hover-grey;
            }
        }
    }

    &__bg {
        content: "";
        position: absolute;
        height: calc(100% - 80px);
        width: 100%;
        background-color: #eef2e8;
        z-index: -1;
        pointer-events: none;

        @include theme(dh) {
            background-color: #cdf2ea;

            @include breakpoint(md) {
                @include facet("dark", 0.2, auto, 100%);
            }
        }

        @include theme(br) {
            background-color: var(--colors_sandstone);

            @include breakpoint(sm, down) {
                height: calc(100% - 30px);
            }
        }

        @include theme(wb) {
            height: calc(100% - 87px);
            background-color: var(--colors_pale_blue);

            @include breakpoint(sm, down) {
                height: calc(100% - 45px);
            }
        }
    }

    .row {
        overflow: hidden;
    }

    &__button {
        width: 100%;
        display: inline-block;
        letter-spacing: 3.4px;
        text-decoration: none;
        text-transform: uppercase;
        border-radius: 16px;
        font: 700 #{rem(12px)}/#{rem(36px)} var(--font_family_main);
        height: 32px;
        border: none;
        outline: none;
        background-color: rgba($colors_body_black, 0.05);
        margin-top: 15px;
        transition: margin 1000ms;

        @include theme(dh) {
            font-family: var(--font_family_heading_medium);
            border-radius: 3px;
            color: rgba(#1e1e1e, 0.65);
            letter-spacing: var(--font_letterspace_med_wide);
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
        }
    }

    &__inner {
        padding: 60px 30px 40px;
    }

    &__title {
        font-size: rem(28px);
        line-height: rem(36px);
        margin: 0 0 22px;

        @include theme(dh) {
            font-size: rem(28px);
            line-height: rem(34px);
        }

        @include theme(br) {
            color: #000;
        }

        @include theme(wb) {
            font-size: rem(28px);
            line-height: rem(30px);
            letter-spacing: -0.28px;
        }
    }

    &__short-description {
        color: $colors_grey_med;
        font-family: var(--font_family_heading);
        font-size: rem(18px);
        line-height: rem(30px);
        margin-bottom: 20px;

        @include theme(dh) {
            color: var(--colors_body_black);
            font-family: var(--font_family_heading_regular);
            font-size: rem(20px);
            line-height: rem(30px);
        }

        @include theme(br) {
            letter-spacing: -0.02em;
            font-family: var(--font_family_heading_regular);
            color: #000;
            font-size: rem(22px);
            line-height: rem(34px);
        }

        @include theme(wb) {
            letter-spacing: -0.4px;
            font-family: var(--font_family_heading_regular);
            color: #000;
            font-size: rem(20px);
            line-height: rem(30px);
        }
    }

    &__description {
        color: $colors_grey_med;
        font-size: rem(14px);
        line-height: rem(22px);

        @include theme(dh) {
            color: var(--colors_body_black);
        }

        @include theme(br) {
            letter-spacing: -0.02em;
            color: #000;
        }
    }

    &__collage-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 30px;
        padding-right: 30px;

        @include theme(dh) {
            flex-direction: column;
            max-width: 560px;
            margin: auto;
        }

        @include theme(br) {
            position: relative;

            margin-top: 80px;
            align-items: flex-start;

            &:before {
                content: "";
                position: absolute;
                z-index: 1;
                right: 0%;
                display: block;
                width: 60%;
                height: 100%;
                background-image: url("../assets/media/barangaroo-lines-sandstone-right.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right 41%;

                @include breakpoint(sm, down) {
                    bottom: 0;
                    height: 100%;
                    background-size: contain;
                }

                @include breakpoint(xs, down) {
                    bottom: 41%;
                    width: 100%;
                    max-height: 80px;
                }
            }

            @include breakpoint(sm, down) {
                margin-top: 0;
                padding-right: 0;
            }
        }

        @include theme(wb) {
            position: relative;
            width: 35vw;
            height: 35vw;
            margin-top: 80px;
            margin-left: auto;

            @include breakpoint(md, down) {
                margin-top: -20px;
                width: 40vw;
                height: 40vw;
            }

            @include breakpoint(sm, down) {
                width: 95vw;
                padding-bottom: 100%;
            }
        }
    }

    &__collage-column {
        display: flex;
        flex-direction: column;

        @include theme(dh) {
            width: 100%;
            margin: 0 !important;
        }

        @include theme(br) {
            &:nth-child(1) {
                position: relative;

                flex-basis: 30%;
                margin-right: 0 !important;
                margin-top: 0 !important;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: -1;
                    display: block;
                    width: 170px;
                    height: 100%;

                    background-color: var(--colors_orange);

                    @include breakpoint(sm, down) {
                        width: 81.62px !important;
                    }
                }
            }

            &:nth-child(2) {
                position: relative;

                flex-basis: 70%;
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    z-index: -1;
                    display: block;
                }

                &:before {
                    left: 0;
                    width: 100%;
                    height: calc(100% - 179px);
                    background-color: var(--colors_highlight);

                    @include breakpoint(sm, down) {
                        height: calc(100% - 81.62px);
                        width: calc(100% - 11%);
                    }
                }
                &:after {
                    right: 0;
                    width: calc(100% - 68%);
                    height: calc(100% - 113px);
                    background-color: var(--colors_rust);

                    @include breakpoint(sm, down) {
                        width: calc(100% - 80%);
                        height: calc(100% - 54.25px);
                    }
                }
            }
        }

        &:nth-child(1) {
            flex-basis: 55%;
            margin-right: 10px;
            align-items: flex-end;

            .market-info-collage__collage-image {
                &:nth-child(1) {
                    width: 100%;
                    @include aspect-ratio(408, 612);
                }
                &:nth-child(2) {
                    width: 85%;
                    @include aspect-ratio(345, 345);
                }
            }
        }
        &:nth-child(2) {
            flex-basis: 45%;
            align-items: flex-start;

            .market-info-collage__collage-image {
                &:nth-child(1) {
                    width: 100%;
                    @include aspect-ratio(345, 345);
                }
                &:nth-child(2) {
                    width: 100%;
                    @include aspect-ratio(339, 509);
                }
            }
        }
    }

    &__collage-image {
        background-color: $colors_grey_light;
        background-size: cover;

        @include theme(dh) {
            margin: 0 !important;
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

            &:before {
                padding-top: 100% !important;
            }

            &--1 {
                width: 75.26881720430108% !important;
                top: 0;
                right: $collage_offset;
            }

            &--2 {
                width: 53.76344086021505% !important;
                margin-top: -25.5% !important;
                right: 0;
            }

            &--3 {
                width: 32.25806451612903% !important;
                margin-top: -55% !important;
                right: 0;
            }

            &--4 {
                width: 48.38709677419355% !important;
                margin-top: -14.8% !important;
                right: -9.3%;
            }
        }

        @include theme(br) {
            margin: 0 !important;

            &:before {
                padding-top: 100% !important;
            }

            &--1 {
                width: 245px !important;
                height: 164px !important;
                top: 0;
                right: 0 !important;

                &:before {
                    padding-top: 67% !important;
                }

                @include breakpoint(sm, down) {
                    width: 117.63px !important;
                    height: 78.58px !important;
                }
            }

            &--2 {
                width: 170px !important;
                height: 170px !important;
                margin-top: 170px !important;
                right: 0 !important;

                @include breakpoint(sm, down) {
                    margin-top: 81.62px !important;
                    width: 81.62px !important;
                    height: 81.62px !important;
                }
            }

            &--3 {
                width: 100% !important;
                height: 334px !important;
                margin-top: 0 !important;
                right: 0;
                top: 0;

                @include breakpoint(sm, down) {
                    height: 160.36px !important;
                }
            }

            &--4 {
                width: 68% !important;
                height: 226px !important;
                margin-top: 170px !important;
                right: 0;

                @include breakpoint(sm, down) {
                    margin-top: 81.62px !important;
                    height: 108.5px !important;
                    width: 80% !important;
                }
            }
        }

        @include theme(wb) {
            width: 100px;
            height: 100px;
            position: absolute !important;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            &--1 {
                top: 0%;
                left: 0%;
                width: 50% !important;
                height: 33.333%;
                &:after,
                &:before {
                    display: none;
                }
            }

            &--2 {
                top: 33.333%;
                left: 0%;
                width: 33.333% !important;
                height: 33.333%;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -100%;
                    left: 0%;
                    width: 200%;
                    height: 100%;
                    background-image: url("../assets/media/texture-mosaic-2.png");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                &:before {
                    display: none;
                }
            }

            &--3 {
                top: 0%;
                left: 50%;
                width: 50% !important;
                height: 33.333%;
                z-index: -1;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -100%;
                    left: -33.333%;
                    width: 66.666%;
                    height: 100%;
                    background-image: url("../assets/media/texture-mosaic-1.png");
                    background-position: center;
                    background-size: cover;
                }
                &:before {
                    display: none;
                }
            }

            &--4 {
                top: 33.333%;
                left: 66.666%;
                width: 33.333% !important;
                height: 33.333%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -100%;
                    left: 0%;
                    width: 100%;
                    height: 100%;
                    background-image: url("../assets/media/texture-mosaic-3.png");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                &:before {
                    display: none;
                }
            }
        }
    }

    &__collage-image:first-child {
        margin-bottom: 10px;
    }

    @include breakpoint(md) {
        margin-bottom: 90px;

        &__bg {
            top: 80px;
        }

        &__collapsible-inner {
            height: auto;
        }

        &__collapsible:after,
        &__button {
            display: none;
        }

        &__collage-wrapper {
            padding: 0 0 80px;

            @include theme(dh) {
                max-width: none;
                padding: 0;
                margin-right: -20.2043%;
            }
        }

        &__collage-column {
            &:nth-child(1) {
                margin-right: 20px;
            }
        }

        &__collage-image:first-child {
            margin-bottom: 20px;
        }

        &__inner {
            padding: 240px 80px 90px 0;
        }

        &__title {
            font-size: rem(52px);
            line-height: rem(64px);
            margin: 0 0 28px;

            @include theme(dh) {
                font-size: rem(48px);
                line-height: rem(52px);
            }

            @include theme(wb) {
                font-size: rem(48px);
                line-height: rem(50px);
                letter-spacing: -0.48px;
            }
        }

        &__short-description {
            font-size: rem(26px);
            line-height: rem(42px);
            margin-bottom: 20px;

            @include theme(dh) {
                font-size: rem(24px);
                line-height: rem(36px);
            }
        }

        &__description {
            font-size: rem(16px);
            line-height: rem(26px);
        }
    }

    @include breakpoint(lg) {
        &__collage-wrapper {
            padding: 0 0 80px;
        }
    }
}
