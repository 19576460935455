.tenant-landing {

  &__form {
    background-color: white;
    padding: 70px 80px 80px;
    margin-bottom: 160px;
  }
  .react-form__field__suggestions-list {
    max-height: 300px;
    border-width: 1px;
    border-style: solid;
    z-index: 3;
    &__item {
      width: 100%;
    }
    .react-form__field__suggestion-button {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      padding-left: 14px;
      cursor: pointer;
      &:hover,&.active {
        background-color: #F7F7F7;
      }
    }
  }
  .react-form__field__label__inner {
    height: 20px;
  }
}
