.curated-content {
    margin: 50px 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;

    @include breakpoint($breakpoint_desktop_nav) {
        margin: 80px 0;
    }

    &__title {
        margin-bottom: 20px;
        font-size: rem(32px);
        line-height: rem(44px);
        word-break: break-word;

        @include theme(dh) {
            font-size: rem(28px);
            line-height: rem(34px);
        }
        @include breakpoint(sm) {
            font-size: rem(42px);
            line-height: rem(50px);

            @include theme(dh) {
                font-size: rem(48px);
                line-height: rem(52px);
            }
        }
    }

    &__button-col {
        text-align: right;
    }

    &__description {
        margin-bottom: 30px;
        @include breakpoint(sm) {
            margin-bottom: 70px;
        }
    }

    .cta {
        display: flex;
        margin-bottom: 80px;

        @include breakpoint(sm) {
            display: inline-flex;
            margin-bottom: 70px;
        }

        @include theme(wb) {
            @extend .cta--black;

            &:hover {
                @extend .cta--hover-grey;
            }
        }
    }

    .tile {
        margin-bottom: 30px;

        .tile__content {
            @include breakpoint(xs, down) {
                @include theme(wb) {
                    padding: 25px 0;
                }
            }
        }

        .tile__title {
            font-size: rem(20px);
            line-height: rem(30px);

            @include theme(dh) {
                font-size: rem(20px);
                line-height: rem(26px);
            }

            @include theme(wb) {
                font-size: rem(24px);
                line-height: rem(28px);
            }
        }

        .tile__description {
            font-size: rem(16px);
            line-height: rem(26px);

            @include theme(dh) {
                font-size: rem(16px);
                line-height: rem(24px);
            }
        }
        .tile__readmore {
            font-size: rem(14px);
            line-height: rem(17px);
        }
        @include breakpoint(sm) {
            margin-bottom: 70px;

            .tile__title {
                font-size: rem(28px);
                line-height: rem(38px);

                @include theme(dh) {
                    font-size: rem(24px);
                    line-height: rem(30px);
                }

                @include theme(wb) {
                    font-size: rem(28px);
                    line-height: rem(32px);
                }
            }
            .tile__description {
                font-size: rem(18px);
                line-height: rem(30px);

                @include theme(dh) {
                    font-size: rem(16px);
                    line-height: rem(24px);
                }
            }
            .tile__readmore {
                font-size: rem(14px);
                line-height: rem(17px);
            }
        }
    }

    &--desktop-stack {
        .tile {
            @extend .tile--vertical;
        }
    }

    &--desktop-stack,
    &.vertical-listing {
        margin-bottom: 0px;

        @include theme(br) {
            margin-bottom: 50px;
        }

        @include breakpoint(sm) {
            margin-bottom: 50px;

            .tile {
                .tile__poster {
                    width: 40% !important;
                }

                .tile__content {
                    padding-left: 80px;
                    width: 60% !important;
                }
            }

            @include theme(br) {
                margin-bottom: 150px;
            }
        }

        .curated-content__tiles-container {
            @include breakpoint(sm) {
                > div {
                    position: relative;
                    &:after {
                        position: absolute;
                        content: "";
                        background-color: #d6d6d6;
                        width: 100%;
                        height: 1px;
                        bottom: -35px;
                        left: 0;
                    }
                    &:first-child:before {
                        position: absolute;
                        content: "";
                        background-color: #d6d6d6;
                        width: 100%;
                        height: 1px;
                        top: -35px;
                        left: 0;
                    }
                }
            }
        }
    }

    &--desktop-grid,
    &.grid-listing {
        .curated-content__tiles-container {
            > div {
                margin-bottom: 15px;
                .tile {
                    margin-bottom: 15px;
                    .tile__content {
                        background-color: $colors_bg_beige;
                        padding-bottom: 10px;

                        @include breakpoint(sm, down) {
                        }

                        @include theme(dh) {
                            background: none;
                        }
                    }

                    @include breakpoint(sm, down) {
                        .tile__date {
                            margin-top: -55px;

                            .date-tile {
                                width: auto;
                                height: 70px;
                                padding: 0 10px;
                            }

                            .date-tile__all-year {
                                height: auto;
                                width: auto;
                            }

                            br {
                                display: none;
                            }
                        }
                    }
                }
            }

            @include breakpoint(sm) {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                > div {
                    .tile {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 20px;
                    }
                    .tile__content {
                        flex-grow: 1;
                    }
                    flex-shrink: 1;
                    position: relative;
                    margin-right: 20px;
                    margin-bottom: 20px;
                }
            }
            @include breakpoint(sm, only) {
                > div {
                    flex-basis: calc(100% / 2 - (20px / 2));
                    &:nth-child(2n + 0) {
                        margin-right: 0;
                    }
                }
            }
            @include breakpoint(md) {
                > div {
                    flex-basis: calc(100% / 3 - (2 * 20px / 3));

                    &:nth-child(3n + 0) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &--mobile-small {
        @include breakpoint(sm, down) {
            .curated-content__tiles-container {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -#{$layout_page_padding/2};

                & > div {
                    padding: 0 #{$layout_page_padding/2};
                    flex: 0 1 50%;

                    .tile {
                        div.tile__content {
                            padding-left: 0;
                            padding-right: 0;

                            div.tile__date {
                                margin-left: 0;
                                .date-tile {
                                    > div {
                                        width: 40px;
                                        flex-shrink: 0;
                                    }
                                    height: 30px;
                                    .date-tile__date {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: auto;
                                    }

                                    .date-tile__date,
                                    .date-tile__day {
                                        @include rem(font-size, 12px);
                                        @include rem(line-height, 12px);
                                        font-weight: bold;
                                        width: auto;
                                        margin-bottom: 0;
                                    }
                                    .date-tile__day {
                                        padding-right: 3px;
                                    }
                                    .date-tile__all-year {
                                        width: auto;
                                        br {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--mobile-stack {
        @include breakpoint(sm, down) {
            .tile__content {
                padding-left: 0;
                padding-right: 0;
            }

            .tile__date {
                margin-left: 0;
            }
        }
    }
}
