.info-card {
    position: relative;

    a {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cta--secondary {
        border-color: white;
        color: white;

        &:hover {
            background: $colors_black_med !important;
            border-color: $colors_black_med !important;
            color: white;

            .icon {
                color: white;
            }
        }
    }

    @include theme(dh) {
        .cta--secondary {
            border-color: white;
            color: white;

            &:hover {
                background: $colors_black_med !important;
                border-color: $colors_black_med !important;
                color: white;

                .icon {
                    color: white;
                }
            }
        }
    }

    @include theme(wb) {
        .cta--secondary {
            border-color: white;
            background-color: white;
            color: white;

            &:hover {
                @extend .cta--hover-white-outline;
            }
        }
    }

    &:not(&--has-header) {
        .info-card__wrapper {
            padding-top: 40px;
            padding-bottom: 35px;
        }
    }

    &__wrapper {
        position: relative;
        background-color: rgba(30, 30, 30, 1);
        color: white;
        padding-top: 30px;

        @include theme(dh) {
            background: #eb054e;
            @include facet("dark", 0.4, 100%);
        }

        .info-flat-header--type-Event &,
        .info-flat-header--type-Offer & {
            @include theme(dh) {
                background: #671980;
            }

            @include theme(br) {
                background: var(--colors_sandstone);
            }

            @include theme(wb) {
                background: black;
            }
        }
        .info-flat-header--has-background-image & {
            @include theme(dh) {
                background: #eb054e;
            }
        }
    }

    &__inner {
        padding: 0 30px;

        @include theme(br) {
            color: black;
        }

        @include theme(wb) {
            color: white;
        }
    }

    &__logo-wrapper {
        position: absolute;
        width: 100px;
        height: 100px;
        top: -50px;
        right: 20px;
        z-index: 1;
        overflow: hidden;
        border-radius: 50%;
    }

    &__logo {
        display: inline-block;
        margin: 0 0;
        height: 100%;
        width: 100%;
    }

    &__date-wrapper {
        text-align: right;
        margin-top: -70px;

        @include theme(wb) {
            padding-bottom: 24px;
        }
    }

    &__header-image-wrapper {
        position: relative;
        line-height: 0;
        img {
            width: 100%;
        }
    }

    &__collapse-block {
        transition: height 1000ms;
        overflow: hidden;
    }

    &--is-collapsible.is-active {
        .close-button {
            display: inline;
        }

        .open-button {
            display: none;
        }

        .info-card__collapse-block-inner {
            height: auto;
        }
    }

    &--is-collapsible {
        .info-card__collapse-block-inner {
            height: 0;
            user-select: none;
        }
    }

    &__collapse-button {
        position: relative;
        line-height: 60px;
        color: white;
        text-decoration: none;
        background: none;
        border: none;
        text-align: left;
        outline: none;
        width: 100%;

        .close-button {
            display: none;
        }

        .open-button {
            display: inline;
        }

        i {
            color: var(--colors_highlight);
            margin-right: 15px;

            @include theme(dh) {
                color: white;
            }

            @include theme(br) {
                color: white;
            }

            &:before {
                vertical-align: middle;
            }
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: -20px;
            height: 1px;
            width: 100vw;
            background-color: #f9f9f9;
            opacity: 0.13;

            @include theme(dh) {
                opacity: 0.5;
            }
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
        }
    }

    &__info {
        &--type {
            text-transform: uppercase;
            letter-spacing: 4.3px;
            font: #{rem(10px)}/#{rem(12px)} var(--font_family_main);
            margin-bottom: 12px;

            @include theme(dh) {
                font-family: var(--font_family_heading_bold);
                letter-spacing: var(--font_letterspace_extra_wide);
            }

            @include theme(br) {
                font-family: var(--font_family_heading_semibold);
                font-size: rem(14px);
                text-transform: none;
                letter-spacing: -0.02em;
                color: white;

                @include breakpoint(xs, down) {
                    font-size: rem(12px);
                    line-height: rem(14px);
                }
            }

            @include theme(wb) {
                font-family: var(--font_family_main);
                font-size: rem(16px);
                text-transform: uppercase;
                letter-spacing: 0.64px;
                line-height: 20px;
                font-weight: 700;

                @include breakpoint(xs, down) {
                    font-size: rem(12px);
                    line-height: rem(14px);
                }
            }

            &.eyebrow--expired {
                display: inline-block;
                padding: 9px 15px;
                opacity: 1;
                background-color: $colors_red_dark;
                color: white;

                @include theme(dh) {
                    background-color: $colors_body_black;
                }
            }
        }
        &--title {
            text-transform: uppercase;
            letter-spacing: -0.6px;
            font: #{rem(28px)}/#{rem(36px)} var(--font_family_heading);
            margin-bottom: 25px;
            margin-top: 10px;

            @include theme(dh) {
                line-height: 34px;
            }

            @include theme(br) {
                font-family: var(--font_family_heading_bold);
                text-transform: none;
                letter-spacing: -0.02em;
                color: white;
            }

            + .row .cta {
                margin-top: -15px;

                @include theme(dh) {
                    margin-top: 0;
                }
            }
        }

        &--byline {
            margin-top: -20px;
            margin-bottom: 30px;
            font: #{rem(20px)}/#{rem(30px)} var(--font_family_heading_regular);
            a {
                color: white;

                @include theme(br) {
                    color: black;
                }
            }
        }

        &--divider {
            border: none;
            position: relative;
            padding-bottom: 35px;
            font-size: 0;

            &:before {
                content: "";
                position: absolute;
                left: -30px;
                top: 0;
                height: 1px;
                width: 100vw;
                background-color: #f9f9f9;
                opacity: 0.13;

                @include theme(dh) {
                    opacity: 0.5;
                }

                @include theme(br) {
                    background-color: #000;
                    opacity: 0.3;
                }
            }
        }

        &--block {
            font: #{rem(16px)}/#{rem(26px)} var(--font_family_main);
            letter-spacing: -0.3px;
            padding-bottom: 25px;

            &--hours {
                // padding-bottom: 0;

                p:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
            @include theme(wb) {
                color: white;
            }

            .label {
                display: inline-block;
                color: var(--colors_highlight);
                text-transform: uppercase;
                letter-spacing: 4.3px;
                font: 700 #{rem(12px)}/#{rem(15px)} var(--font_family_main);
                padding-bottom: 7px;

                @include theme(dh) {
                    color: white;
                    font-family: var(--font_family_heading_bold);
                    letter-spacing: var(--font_letterspace_wide);
                }

                @include theme(br) {
                    color: black;
                    font-weight: 600;
                    font-size: rem(14px);
                    text-transform: none;
                    letter-spacing: -0.02em;
                }

                @include theme(wb) {
                    color: white;
                    letter-spacing: 0.64px;
                    font-size: rem(16px);
                    line-height: 20px;
                }
            }

            p {
                padding: 0 0 10px;
                margin: 0;
                @include theme(wb) {
                    font-size: rem(14px);
                }
            }
        }
    }

    &__ticket-price {
        margin-right: 5px;
    }

    .cta {
        margin-bottom: 30px;
        padding: 10px 15px;
        white-space: nowrap;

        @include theme(dh) {
            padding: 15px;
        }
    }

    .link--external,
    .link--silent {
        position: relative;
        color: white;
        text-decoration: none;
        font: 700 #{rem(14px)}/#{rem(20px)} var(--font_family_main);
        @extend .underline;
        @extend .icon;
        @extend .icon-arrow-up-right;
        padding-bottom: 0.2em;
        margin-bottom: 5px;
        display: inline-block !important;
        overflow: visible;

        @include theme(dh) {
            text-transform: uppercase;
            font-family: var(--font_family_heading);
            box-shadow: inset 0 -0.15em 0 rgba(white, 0.7);

            &:hover {
                color: $colors_grey_dark;
                box-shadow: inset 0 -2em 0 rgba(white, 0.7);
            }
        }

        @include theme(br) {
            color: black;

            &:before {
                color: black;
            }

            &:hover:before {
                color: black;
            }
        }

        @include theme(wb) {
            color: white;
            border: 0px solid #1E5BC0;
            box-shadow: inset 0 -3px 0 rgba(#7DA186, 0.7);

            &:before {
                color: white;
            }

            &:hover:before {
                color: white;
            }
        }

        &:before {
            position: absolute;
            right: -20px;
            font-size: 0.7em;
            color: white;
        }

        &:hover:before {
            color: white;
        }
    }

    .link--silent {
        &:before {
            content: none;
        }
    }

    &__social-links {
        display: flex;
        align-items: center;
        margin-bottom: 35px;

        a {
            display: inline-block;
        }
    }

    .social {
        white-space: nowrap;
        color: white;
        @extend .icon;
        font-size: rem(26px);
        line-height: rem(30px);
        transition: all 100ms linear;

        &:hover {
            transform: scale(1.1, 1.1);
        }

        span {
            display: none;
        }

        &:before {
            vertical-align: middle;
            margin-right: 20px;
            display: inline-block;
        }

        &:last-child:before {
            margin-right: 0;
        }

        &--instagram {
            @extend .icon-instagram_card;
        }
        &--facebook {
            @extend .icon-facebook;
        }
        &--twitter {
            @extend .icon-twitter;
            &:before {
                transform: scale(0.8);
            }
        }
        &--youtube {
            @extend .icon-youtube;
        }

        @include theme(br) {
            color: black;
        }

          @include theme(wb) {
            color: white;
        }
    }

    &__map {
        position: relative;
        display: block;
        height: 200px;
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 240px;
            top: 0;
            left: 0;
            object-fit: cover;
        }
        i {
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 15px);
            color: $colors_grey_dark;
            font-size: rem(40px);
        }
    }

    &--type-Tenant {
        @include theme(br) {
            .info-card {
                &__wrapper {
                    background: linear-gradient(
                        155deg,
                        rgba(30, 30, 30, 0.85) 0%,
                        rgba(30, 30, 30, 1) 30%
                    );
                    @include breakpoint(xs, down) {
                        background: rgba(30, 30, 30, 1);
                    }
                }
                &__inner {
                    color: white;
                }

                &__info {
                    &--title,
                    &--type {
                        color: white;
                    }

                    &--block {
                        .label,
                        p,
                        div {
                            color: white;
                        }

                        &--address {
                            color: white;
                        }
                    }
                }
            }

            .social {
                color: white;
            }

            .link--external,
            .link--silent {
                color: white;
                box-shadow: inset 0 -0.15em 0 var(--colors_rust);

                &:hover {
                    box-shadow: inset 0 -7px 0 var(--colors_rust);
                }

                &:before {
                    color: white;
                }

                &:hover:before {
                    color: white;
                }
            }

            .cta--black {
                border-color: var(--colors_rust);
                background-color: var(--colors_rust);
                color: black;

                &:hover {
                    background: $colors_black_med;
                    border-color: $colors_black_med;
                    color: black;

                    .icon {
                        color: black;
                    }
                }
            }
        }

        @include theme(wb) {
            .info-card__wrapper {
                min-height: 390px;
                background: #1e1e1e;;
            }
        }
    }

    &--type-Event {
        @include theme(br) {
            .info-card__info--title {
                color: #000;
            }

            .cta--black {
                border-color: var(--colors_highlight);
                background-color: var(--colors_highlight);
                color: white;

                &:hover {
                    background: $colors_black_med !important;
                    border-color: $colors_black_med;
                    color: white !important;

                    .icon {
                        color: white;
                    }
                }
            }
        }

        @include theme(wb) {
            .info-card__wrapper {
                background: black;

                .eyebrow.eyebrow--badge {
                    color: #ACABAB;
                }

                .cta {
                    border-color: var(--colors_highlight);
                    background-color: var(--colors_highlight);
                    color: white;

                    &:hover {
                        background: $colors_black_med !important;
                        border-color: $colors_black_med !important;
                        color: white !important;

                        .icon {
                            color: white;
                        }
                    }

                    &--black {
                        border-color: white !important ;
                        background-color: black !important;
                        color: white;
                        &:hover {
                            border-color: white !important;
                        }
                    }
                }

                @include breakpoint(md) {
                    min-height: 27vw;
                }

                @include breakpoint(lg) {
                    min-height: 26vw;
                }

                @media (min-width: 1200px) and (max-width: 1348px) {
                    min-height: 36vw !important;
                }

                @media (min-width: 1480px) and (max-width: 1600px) {
                    min-height: 24vw !important;
                }

                @include breakpoint(xl) {
                    min-height: 22vw;
                }

                @media screen and (min-width: 2200px) {
                    min-height: 12vw;
                }
            }
        }
    }

    &--type-Offer {
        @include theme(wb) {
            .info-card__wrapper {
                background: black;

                @include breakpoint(md) {
                    min-height: 27vw;
                }

                @include breakpoint(lg) {
                    min-height: 26vw;
                }

                @media (min-width: 1200px) and (max-width: 1348px) {
                    min-height: 36vw !important;
                }

                @media (min-width: 1480px) and (max-width: 1600px) {
                    min-height: 27vw !important;
                }

                @include breakpoint(xl) {
                    min-height: 22vw;
                }

                @media screen and (min-width: 2200px) {
                    min-height: 12vw;
                }
            }
        }
    }

    &--type-Venue,
    &--type-Tenant {
        @include theme(wb) {
            .cta {
                &--black {
                    border-color: var(--colors_highlight);
                    background-color: var(--colors_highlight);
                    color: black;

                    &:hover {
                        @extend .cta--hover-green;
                    }
                }
            }
        }
    }

    &--type-Precinct,
    &--type-Stall,
    &--type-Venue {
        @include theme(br) {
            .info-card {
                &__info {
                    &--title,
                    &--type {
                        color: white;
                    }

                    &--block {
                        color: white;

                        .label,
                        p {
                            color: white;
                        }

                        &--address {
                            color: white;
                        }
                    }

                    &--divider {
                        &:before {
                            background-color: white;
                        }
                    }
                }
            }

            .social {
                color: white;
            }

            .link--external,
            .link--silent {
                color: white;
                box-shadow: inset 0 -0.15em 0 var(--colors_rust);

                &:hover {
                    box-shadow: inset 0 -7px 0 var(--colors_rust);
                }

                &:before {
                    color: white;
                }
                &:hover:before {
                    color: white;
                }
            }

            .cta--black {
                border-color: var(--colors_rust);
                background-color: var(--colors_rust);
                color: black;

                &:hover {
                    background: $colors_black_med;
                    border-color: white;
                    color: black;

                    .icon {
                        color: black;
                    }
                }
            }
        }
    }

    @include breakpoint(sm) {
        &:not(&--has-header) {
            .info-card__wrapper {
                padding-top: 60px;
            }

            .info-card__info--block:last-child {
                padding-bottom: 20px;
            }
        }

        &__wrapper {
            padding-top: 50px;
        }

        &--has-gradient .info-card__wrapper {
            background: linear-gradient(
                155deg,
                rgba(30, 30, 30, 0.85) 0%,
                rgba(30, 30, 30, 1) 30%
            );

            @include theme(dh) {
                background: #eb054e;
                @include facet("dark", 0.4, 100%);
            }

            @include theme(wb) {
                background: #1e1e1e;
            }
        }

        &__inner {
            padding: 0 40px;
        }

        &--is-collapsible {
            .info-card__collapse-block-inner {
                height: auto;
            }

            .info-card--divider {
                margin-top: 50px;
            }
        }

        &__info {
            &--block {
                font-size: rem(14px);
                line-height: rem(22px);
                &:last-child {
                    padding-bottom: 45px;
                }
                .label {
                    font-size: rem(12px);
                    line-height: rem(15px);
                }
            }

            &--type {
                font-size: rem(12px);
                line-height: rem(15px);
                letter-spacing: 5px;
            }

            &--title {
                font-size: rem(32px);
                line-height: rem(40px);
                letter-spacing: 0;
                margin-bottom: 30px;
            }

            &--divider {
                margin-top: 30px;

                @include theme(dh) {
                    // margin-top: 0;
                }

                &:before {
                    left: 0;
                    top: 0;
                    width: 100%;
                }
            }
        }

        &__map {
            height: 240px;
            img {
                height: 280px;
            }
        }

        .cta {
            margin-bottom: 50px;
        }

        &--type-Venue {
            .cta {
                margin-bottom: 0;
            }

            .cta--black {
                @include theme(dh) {
                    background-color: white;
                    border-color: white;
                    color: var(--colors_body_black);

                    &:hover {
                        background: $colors_black_med !important;
                        border-color: $colors_black_med;
                        color: white;
                    }
                }
            }

            @include theme(wb) {
                .cta {
                    &--black {
                        border-color: var(--colors_highlight) !important;
                        background-color: var(--colors_highlight) !important;
                        color: white;

                        &:hover {
                            background: $colors_black_med !important;
                            border-color: $colors_black_med !important;
                            color: white !important;

                            .icon {
                                color: white;
                            }
                        }
                    }
                }
            }
        }

        &--type-Tenant {
            @include theme(br) {
                .info-card {
                    &__info {
                        &--title,
                        &--type {
                            color: white;
                        }

                        &--block {
                            .label,
                            p {
                                color: white;
                            }

                            &--address {
                                color: white;
                            }
                        }
                    }
                }

                .social {
                    color: white;
                }

                .link--external,
                .link--silent {
                    color: white;
                    box-shadow: inset 0 -0.15em 0 var(--colors_rust);

                    &:hover {
                        box-shadow: inset 0 -7px 0 var(--colors_rust);
                    }

                    &:before {
                        color: white;
                    }
                    &:hover:before {
                        color: white;
                    }
                }

                .cta--black {
                    border-color: var(--colors_rust);
                    background-color: var(--colors_rust);
                    color: black;

                    &:hover {
                        background: $colors_black_med;
                        border-color: $colors_black_med;
                        color: black;

                        .icon {
                            color: black;
                        }
                    }
                }
            }

            @include theme(wb) {
                .cta {
                    &--black {
                        border-color: var(--colors_highlight) !important;
                        background-color: var(--colors_highlight) !important;
                        color: white;

                        &:hover {
                            background: $colors_black_med !important;
                            border-color: $colors_black_med !important;
                            color: white !important;

                            .icon {
                                color: white;
                            }
                        }
                    }
                }
            }
        }

        &--type-Venue,
        &--type-Tenant {
            @include theme(br) {
                .info-card {
                    &__info {
                        &--title,
                        &--type {
                            color: white;
                        }

                        &--block {
                            color: white;

                            .label,
                            p {
                                color: white;
                            }

                            &--address {
                                color: white;
                            }
                        }

                        &--divider {
                            &:before {
                                background-color: white;
                            }
                        }
                    }
                }

                .social {
                    color: white;
                }

                .link--external,
                .link--silent {
                    color: white;
                    box-shadow: inset 0 -0.15em 0 var(--colors_rust);

                    &:hover {
                        box-shadow: inset 0 -7px 0 var(--colors_rust);
                    }

                    &:before {
                        color: white;
                    }
                    &:hover:before {
                        color: white;
                    }
                }

                .cta--black {
                    border-color: var(--colors_rust);
                    background-color: var(--colors_rust);
                    color: black;

                    &:hover {
                        background: $colors_black_med;
                        border-color: white;
                        color: black;

                        .icon {
                            color: black;
                        }
                    }
                }
            }

            @include theme(wb) {
                .info-card {
                    &__info {
                        &--title,
                        &--type {
                            color: white;
                        }

                        &--title {
                            font-size: 28px;
                            line-height: 30px;

                            @include breakpoint(md) {
                                font-size: 48px;
                                line-height: 50px;
                            }
                        }

                        &--block {
                            .label,
                            p {
                                color: white;
                            }

                            &--address {
                                color: white;
                            }
                        }
                    }
                }

                .social {
                    color: white;
                }

                .link--external,
                .link--silent {
                    color: white;
                    box-shadow: inset 0 -0.15em 0 var(--colors_highlight);

                    &:hover {
                        box-shadow: inset 0 -7px 0 var(--colors_highlight);
                    }

                    &:before {
                        color: white;
                    }
                    &:hover:before {
                        color: white;
                    }
                }

                .cta--black {
                    border-color: var(--colors_highlight);
                    background-color: var(--colors_highlight);
                    color: black;

                    &:hover {
                        background: #64816B !important;
                        border-color: #64816B !important;
                        color: black !important;

                        .icon {
                            color: black;
                        }
                    }
                }
            }
        }

        &__social-links {
            margin-bottom: 0;
        }
    }

    @include breakpoint(md) {
        &:not(&--has-header) {
            .info-card__wrapper {
                padding-top: 35px;
            }
        }

        &__wrapper {
            padding-top: 80px;
        }

        &__inner {
            padding: 0 40px;
        }

        &__info {
            &--type {
                font-size: rem(12px);
                line-height: rem(15px);
            }

            &--title {
                font-size: rem(32px);
                line-height: rem(40px);
            }

            &--byline {
                font: #{rem(24px)}/#{rem(36px)}
                    var(--font_family_heading_regular);
                a {
                    color: white;

                    @include theme(br) {
                        color: black;
                    }

                    @include theme(wb) {
                        color: black;
                    }
                }
            }

            &--block {
                font-size: rem(14px);
                line-height: rem(22px);

                .label {
                    font-size: rem(12px);
                    line-height: rem(15px);
                }
            }
        }
    }

    @include breakpoint(lg) {
        &__info {
            &--type {
                font-size: rem(12px);
                line-height: rem(15px);
            }

            &--title {
                font-size: rem(32px);
                line-height: rem(40px);
            }

            &--block {
                font-size: rem(14px);
                line-height: rem(22px);

                .label {
                    font-size: rem(12px);
                    line-height: rem(15px);
                }
            }
        }
    }

    @include breakpoint(xl) {
        &__inner {
            padding: 0 70px;
        }

        &__logo-wrapper {
            width: 150px;
            height: 150px;
            top: -56px;
            right: 45px;
        }
        &__info {
            &--type {
                font-size: rem(14px);
                line-height: rem(17px);

                @include theme(dh) {
                    font-size: rem(14px);
                    line-height: rem(17px);
                }
            }

            &--title {
                font-size: rem(42px);
                line-height: rem(55px);

                @include theme(dh) {
                    font-size: rem(48px);
                    line-height: rem(52px);
                }
            }

            &--block {
                font-size: rem(16px);
                line-height: rem(26px);

                .label {
                    font-size: rem(12px);
                    line-height: rem(15px);
                }
            }
        }
    }
}
