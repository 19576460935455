.site-specification {
    list-style: none;
    padding: 0;
    margin: 0;

    &__item {
        position: relative;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding-top: 30px;
        padding-bottom: 26px;
        &:before {
            position: absolute;
            height: 1px;
            width: 100%;
            left: 0;
            top: 0;
            content: "";
            background-color: #d6d6d6;
        }
        &:last-child {
            padding-bottom: 0;
            margin-bottom: -10px;
        }
    }

    &__icon {
        flex: 0 0 42px;
        svg {
            max-width: 24px;
            max-height: 24px;
            * {
                fill: black;
            }
        }
    }

    &__label {
        flex: 0 0 calc(100% - 42px);
        font-size: rem(18px);
        line-height: rem(26px);
        font-weight: 600;
        color: $colors_grey_med;
        @include breakpoint(lg) {
            flex: 0 0 calc(30% - 42px);
        }
    }

    &__content {
        padding-left: 42px;
        padding-top: 5px;
        @include breakpoint(lg) {
            padding-left: 0;
            padding-top: 0;
            flex: 0 0 60%;
        }
    }

    &__content > *:last-child {
        padding-bottom: 0;

    }
}
