.venues-page {
    &__use-wrapper {
        margin-left: $layout_page_padding;
        width: calc(100% - 100px - 385px);
        @include breakpoint(sm) {
            width: calc(100% - 100px - 385px);
        }

        @include breakpoint(xl) {
            width: calc(100% - 100px - 540px);
        }
    }
    &__accordion-wrapper {
        .container-fluid {
            padding-left: 0;
            padding-right: 0;
        }
        .accordion {
            margin-top: 0;
            margin-bottom: 0;
        }
        .accordion__section__label {
            font-size: rem(20px);
            line-height: rem(30px);
            @include breakpoint($breakpoint_desktop_nav) {
                font-size: rem(24px);
                line-height: rem(34px);
            }
        }
    }
}
