.tile {
    display: block;
    position: relative;
    min-height: 100%;
    text-decoration: none;
    color: var(--colors_body_black);
    transition: all 100ms linear;

    &--event:hover {
        transform: translateY(-5px);
        .tile__poster,
        .tile__content {
            transform: none !important;
        }
    }

    &__poster[data-free-label]:not([data-free-label=""]):after {
        content: attr(data-free-label);
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 62px;
        height: 28px;

        font-family: var(--font_family_main);
        font-weight: bold;
        letter-spacing: var(--font_letterspace_wide);
        font-size: rem(12px);
        text-transform: uppercase;
        color: black;

        background-color: $colors_highlight;

        @include theme(br) {
            font-family: var(--font_family_heading_semibold);
            text-transform: none;

            background-color: var(--colors_rust);
        }

        @include theme(dh) {
            font-family: var(--font_family_heading);
            background-color: #EB054E;
            color: #fff;
        }

        @include theme(wb) {
            font-family: var(--font_family_main);
            font-size: 12px;
            color: #000;
            letter-spacing: unset;
            background-color: var(--colors_highlight);
        }
    }

    &__poster[data-expired-label]:not([data-expired-label=""]):after {
        content: attr(data-expired-label);
        color: white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: rgba($colors_body_black, 0.7) !important;
        text-transform: uppercase;
        letter-spacing: rem(2.5px);
        font-size: rem(10px);
        line-height: rem(10px);
        font-weight: bold;
        padding: 11px 15px;
        text-align: center;

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
        }

        @include theme(wb) {
            font-family: var(--font_family_main);
            font-size: 12px;
            color: #fff;
            letter-spacing: unset;
        }
    }

    &__poster[data-expired-label]:not([data-expired-label=""])[data-free-label]:not([data-free-label=""]):after {
        display: block;
    }

    &__poster {
        display: block;
        background-size: cover;
        background-color: white;
        transition: all 100ms linear;
    }

    &__poster,
    .youtube-player {
        @include aspect-ratio(344, 229);
    }

    &__poster[href]:hover {
        transform: translateY(-5px);

        & + .tile__content {
            transform: translateY(-5px);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 25px;
        @include rem(font-size, 14px);
        background: white;
        text-decoration: none;
        color: var(--colors_body_black);
        transition: all 100ms linear;

        @include breakpoint($breakpoint_desktop_nav) {
            padding: 45px 30px 40px;
        }
    }

    &__badge {
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-block;
        padding: 9px 13px;
        background: var(--colors_highlight);
        @include rem(font-size, 10px);
        color: $colors_heading_black;

        @include theme(dh) {
            background: #9be5d5;
        }

        @include theme(br) {
            color: white;
        }

        @include theme(wb) {
            background-color: var(--colors_rust);
            color: white;
            font-size: 12px;
        }

        @include breakpoint($breakpoint_desktop_nav) {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    &__date {
        order: 0;
        margin-left: -25px;
        margin-top: -80px;
        margin-bottom: 20px;

        @include breakpoint($breakpoint_desktop_nav) {
            margin-left: -30px;
        }
    }

    &__time-description,
    &__eyebrow {
        order: 1;
        font-weight: bold;
        color: var(--colors_teal);
        margin-bottom: 5px;

        @include theme(dh) {
            color: #757575;
            text-transform: uppercase;
            font-size: rem(12px);
            line-height: rem(18px);
            font-family: var(--font_family_heading_medium);
            font-weight: normal;
            letter-spacing: var(--font_letterspace_wide);

            @include breakpoint(sm, down) {
                font-size: rem(11px);
                line-height: rem(18px);
            }
        }

        @include theme(br) {
            color: var(--colors_highlight) !important;
            font-family: var(--font_family_heading_semibold);
            font-size: rem(14px);
            line-height: rem(18px);
            font-weight: 600;
            letter-spacing: -0.02em;
        }

        @include theme(wb) {
            color: var(--colors_rust) !important;

            @include breakpoint(sm, down) {
                font-size: rem(12px);
                line-height: 20px;
                letter-spacing: 0.64px;
            }
        }
    }

    &__title {
        order: 2;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: rem(16px);
        line-height: rem(22px);

        @include theme(dh) {
            font-family: var(--font_family_heading_bold);
            font-size: rem(20px);
            line-height: rem(26px);
        }

        @include theme(br) {
            font-family: var(--font_family_heading_semibold);
            font-size: rem(20px);
            line-height: rem(28px);
            font-weight: 700;
            letter-spacing: -0.02em;

            @include breakpoint($breakpoint_desktop_nav) {
                font-size: rem(24px);
                line-height: rem(32px);
            }
        }

        @include theme(wb) {
            font-size: rem(24px);
            text-transform: uppercase;
            line-height: 26px;
            letter-spacing: -0.24px;

            @include breakpoint($breakpoint_desktop_nav) {
                font-size: rem(24px);
                line-height: 32px !important;
                letter-spacing: -0.48px;
            }
        }

        @include breakpoint($breakpoint_desktop_nav) {
            font-size: rem(24px);
            line-height: rem(34px);
            letter-spacing: -0.6px;

            @include theme(dh) {
                font-size: rem(24px);
                line-height: rem(30px);
                letter-spacing: -1px;
            }

            @include theme(br) {
                font-size: rem(24px);
                line-height: rem(32px);
            }
        }

        .tile--highlights & {
            font-size: rem(20px);
            line-height: rem(24px);

            @include theme(dh) {
                font-size: rem(20px);
                line-height: rem(24px);
            }

            @include breakpoint($breakpoint_desktop_nav) {
                font-size: rem(30px);
                line-height: rem(38px);

                @include theme(dh) {
                    font-size: rem(30px);
                    line-height: rem(38px);
                }
            }
        }
    }

    &__logo {
        flex: 0 0 auto;
        position: absolute;
        top: -45px;
        right: 10px;
        z-index: 4;
        display: inline-block;
        width: 60px;
        height: 60px;
        background-size: cover;
        background-position: center center;
        border-radius: 100%;
        border: 1px solid $colors_grey_light;
        margin-left: $layout_page_padding;
        background-color: white;

        @include breakpoint($breakpoint_desktop_nav) {
            position: relative;
            top: auto;
            right: auto;
            width: 100px;
            height: 100px;
            margin-top: -75px;
            margin-right: -10px;
            .tile__eyebrow ~ * & {
                margin-top: calc(-75px - 21px);
            }
        }
    }

    &__author {
        order: 3;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: rem(14px);
        line-height: rem(17px);
        // max-height: rem(17px);
        width: 100%;

        &.tile__author--push {
            height: 1.2em;
            margin-top: 0;
        }
        @include theme(br) {
            order: 0;
            padding-left: 120px;
            margin-top: -30px;
            margin-bottom: 20px;
            text-align: right;
        }

        @include breakpoint($breakpoint_desktop_nav) {
            order: 0;
            padding-left: 120px;
            margin-top: -30px;
            margin-bottom: 20px;
            text-align: right;
        }

        &__link {
            display: inline;
            text-align: right;
            font-weight: bold;

            @include theme(br) {
                color: black;
            }
        }
    }

    &__description {
        order: 4;
        font-size: rem(12px);
        line-height: rem(16px);
        margin-bottom: -5px;

        @include theme(dh) {
            font-size: rem(16px);
            line-height: rem(24px);
        }

        @include theme(br) {
            font-size: rem(14px);
            line-height: rem(23px);
            letter-spacing: -0.02em;
        }

        @include theme(wb) {
            font-size: rem(14px);
            line-height: rem(22px);
            letter-spacing: -0.28px;
        }

        @include breakpoint($breakpoint_desktop_nav) {
            font-size: rem(16px);
            line-height: rem(26px);

            @include theme(wb) {
                font-size: rem(16px);
                line-height: rem(24px);
                letter-spacing: -0.16px;
            }
        }
    }

    &__readmore {
        order: 5;
        font-weight: bold;
        font-size: rem(12px);
        line-height: rem(15px);
        text-decoration: none;
        color: inherit;
        margin-top: 20px;
        margin-bottom: 5px;
        text-transform: capitalize;

        @include theme(dh) {
            text-transform: uppercase;
            font-size: rem(12px);
            line-height: rem(14px);
            font-family: var(--font_family_heading_extrabold);
            font-weight: normal;
            letter-spacing: 0.03571428571em;
        }

        @include theme(br) {
            letter-spacing: -0.02em;
        }

        @include theme(wb) {
            text-transform: none;

            .underline {
                box-shadow: inset 0 -0.2em 0 var(--colors_rust);
            }
        }

        @include breakpoint($breakpoint_desktop_nav) {
            font-size: rem(14px);
            line-height: rem(17px);

            @include theme(dh) {
                font-size: rem(14px);
                line-height: rem(17px);
            }
        }
    }

    // Modifiers

    &--big {
        @include breakpoint($breakpoint_desktop_nav) {
            .tile__content {
                padding-left: 20px;
                padding-right: 20px;
            }

            .tile__date {
                margin-left: -20px;
            }
        }
        @include breakpoint(xl) {
            .tile__content {
                padding-left: 25px;
                padding-right: 25px;
            }

            .tile__date {
                margin-left: -25px;
            }
        }
    }

    @include breakpoint(sm) {
        &--vertical {
            position: relative;
            display: flex;
            align-items: flex-start;
            margin-bottom: 70px;

            .vertical-listing__tile-wrapper:last-child & {
                margin-bottom: 0;
            }

            &:after {
                position: absolute;
                bottom: -35px;
                left: 0;
                height: 1px;
                width: 60%;
                background-color: #d8d8d8;
                content: "";
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            .tile__poster {
                width: 20%;
                &:after {
                    top: auto !important;
                    bottom: 0;
                }
            }

            .tile__time-description {
                order: 0;
            }

            .tile__author {
                display: none;
            }

            .tile__logo {
                display: none;
            }

            .tile__date {
                position: absolute;
                top: 0;
                left: 0;
                margin-top: -10px;
                margin-left: 0;
            }

            .tile__content {
                position: static;
                width: 35%;
                padding-top: 0;
            }
        }
    }
}

.listing-page--single-column-layout .tile {
    @include breakpoint(sm, down) {
        &__title {
            font-size: rem(20px);
            line-height: rem(30px);
        }

        &__time-description {
            font-size: rem(14px);
            line-height: rem(26px);
            margin-bottom: 2px;

            @include theme(dh) {
                font-size: rem(11px);
                line-height: rem(15px);
            }
        }

        &__description {
            font-size: rem(14px);
            line-height: rem(22px);

            @include theme(dh) {
                font-size: rem(16px);
                line-height: rem(24px);
            }
        }

        &__readmore {
            font-size: rem(12px);
            line-height: rem(15px);
        }

        &__author {
            font-size: rem(14px);
            line-height: rem(17px);
            order: 0;
            padding-left: 140px;
            padding-right: 25px;
            margin-top: -45px;
            margin-bottom: 20px;
            text-align: right;
        }

        &__date {
            margin-left: 0;
            margin-top: -45px;
        }
    }

    @include breakpoint($breakpoint_desktop_nav) {
        &__date {
            margin-left: 0;

            + .tile__title {
                margin-top: 4px;
            }
        }

        &__author {
            padding-left: 140px;
            top: 0;
            right: 25px;
        }
    }
}

.always-xs {
    .tile {
        display: block;
        position: relative;
        min-height: 100%;
        text-decoration: none;
        color: var(--colors_body_black);
        transition: all 100ms linear;

        &__poster {
            @include aspect-ratio(344, 229);
            background-size: cover;
            background-color: white;
        }

        &__content {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 25px 25px 40px;
            @include rem(font-size, 14px);
            background: white;
        }

        &__badge {
            position: absolute;
            bottom: 0;
            left: 0;
            display: inline-block;
            padding: 9px 13px;
            background: var(--colors_highlight);
            @include rem(font-size, 10px);

            @include theme(dh) {
                background: #9be5d5;
            }
        }

        &__date {
            order: 0;
            margin-left: -25px;
            margin-top: -80px;
            margin-bottom: 20px;
        }

        &__time-description {
            order: 1;
            font-weight: bold;
            color: var(--colors_teal);
            margin-bottom: 5px;
        }

        &__title {
            order: 2;
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            font-size: rem(20px);
            line-height: rem(30px);
        }

        &__logo {
            flex: 0 0 auto;
            position: absolute;
            top: -45px;
            right: 10px;
            z-index: 4;
            display: inline-block;
            width: 60px;
            height: 60px;
            background-size: cover;
            background-position: center center;
            border-radius: 100%;
            border: 1px solid $colors_grey_light;
            margin-left: $layout_page_padding;
            background-color: white;
        }

        &__author {
            order: 3;
            margin-top: 0;
            margin-bottom: 10px;
            font-size: rem(14px);
            line-height: rem(17px);

            &__link {
                display: inline;
                text-align: right;
                font-weight: bold;
            }
        }

        &__description {
            order: 4;
            margin-bottom: 20px;
            font-size: rem(14px);
            line-height: rem(22px);

            @include theme(dh) {
                font-size: rem(16px);
                line-height: rem(24px);
            }
        }

        &__readmore {
            order: 5;
            font-weight: bold;
            font-size: rem(14px);
            line-height: rem(17px);

            .underline {
                padding-bottom: 0.2em;
                box-shadow: inset 0 -0.2em 0 var(--colors_highlight);
            }
        }
    }
}
