.pagination {
    max-width: 800px;
    margin: 30px auto;

    &__prev-next {
        display: flex;
        flex-direction: row;
        justify-content: center;

        & > * {
            flex: 0 1 50%;
            margin: 0 $layout_page_padding/2;
        }
    }

    &__pages {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 30px auto;
    }

    &__bullet {
        background-color: transparent;
        height: 28px;
        width: 28px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $colors_grey_med;
        text-decoration: none;
        font-size: rem(13px);
        line-height: rem(15px);
        font-weight: 700;
        margin: 0 9px;

        &.is-active {
            background-color: $colors_grey_med;
            color: white;
            @include theme(wb) {
                color: black;
                background-color: black;
                @include breakpoint(sm) {
                    color: white;
                }
            }
        }

        &:not(.is-active):hover {
            color: $colors_grey_dark;
            background-color: $colors_grey_light;
            @include theme(wb) {
                background-color: black;
                color: white;
            }
        }
    }
}
