.accordion {
    margin: 50px 0;

    &__container {
        padding-right: 30px;
    }

    &__title {
        margin: 0 0 25px;
    }

    &__description {
        margin: 25px 0 40px;
    }

    &__section {
        border-bottom: 1px solid #d6d6d6;

        &:first-child {
            border-top: 1px solid #d6d6d6;
        }

        &__label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 25px 0;
            transition: all 300ms;
            @include rem(font-size, 16px);

            input:focus ~ &,
            &:hover {
                padding-left: 3px;
            }

            &__indicator {
                flex: 0 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 1em;
                height: 1em;
                margin-left: $layout_page_padding;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    width: 1em;
                    height: 0.1em;
                    border-radius: 100px;
                    background: var(--colors_teal);
                    transition: all 300ms;

                    @include theme(dh) {
                        background: var(--colors_highlight);
                    }

                    @include theme(br) {
                        background: var(--colors_highlight);
                    }

                    @include theme(wb) {
                        background: var(--colors_rust);
                    }
                }

                &:after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(90deg);

                    input:checked ~ .accordion__section__label &,
                    .accordion__section__label.active & {
                        transform: translate(-50%, -50%) rotate(0deg);
                    }
                }

                &:before {
                    input:checked ~ .accordion__section__label &,
                    .accordion__section__label.active & {
                        transform: rotate(-180deg);
                    }
                }
            }

            @include theme(wb) {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.16px;
                text-transform: uppercase;
            }
        }

        &__content {
            overflow: hidden;
            color: $colors_grey_med;
            transition-duration: 200ms;
            transition-timing-function: ease-in-out;

            .rich-text + .row {
                margin-top: 25px;
            }

            &__pad {
                padding-bottom: 40px;

                & > *:first-child {
                    margin-top: 0;
                }

                & > *:last-child {
                    margin-bottom: 0;
                }
            }

            &__inner {
                @include fadeHide;
                height: 0;
                transition: all 200ms ease;

                input:checked ~ .accordion__section__content &,
                ,
                .accordion__section__content.active & {
                    @include fadeShow;
                    height: auto;
                    transition-delay: 100ms;
                }
            }
        }
    }

    @include breakpoint($breakpoint_desktop_nav) {
        margin: 80px 0;

        &__section {
            &__label {
                @include rem(font-size, 20px);

                .react-form & {
                    @include rem(font-size, 16px);
                }

                @include theme(wb) {
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: -0.2px;
                    text-transform: uppercase;
                }
            }
        }
    }
}
