.row--no-pad {
    margin-left: 0;
    margin-right: 0;

    > {
        [class^="col-"],
        [class*=" col-"] {
            padding-left: 0;
            padding-right: 0;

            &:not(:first-child) {
                //margin-left: -$layout_page_padding;
            }
        }
    }
}

.hide-xs {
    display: none;

    @include breakpoint(sm) {
        display: block;

        &--inline {
            display: inline-block;
        }
    }
}

.hide-sm {
    display: none;

    @include breakpoint(md) {
        display: block;

        &--inline {
            display: inline-block;
        }
    }
}

.hide-md {
    display: none;

    @include breakpoint(lg) {
        display: block;

        &--inline {
            display: inline-block;
        }
    }
}

.hide-lg {
    display: none;

    @include breakpoint(xl) {
        display: block;

        &--inline {
            display: inline-block;
        }
    }
}

.hide-xl {
    display: none;
}

.show-xs {
    display: block;

    &--inline {
        display: inline-block;
    }
    @include breakpoint(sm) {
        display: none;
    }
}

.show-sm {
    display: block;
    &--inline {
        display: inline-block;
    }
    @include breakpoint(md) {
        display: none;
    }
}

.show-md {
    display: block;
    &--inline {
        display: inline-block;
    }
    @include breakpoint(lg) {
        display: none;
    }
}

.show-lg {
    display: block;
    &--inline {
        display: inline-block;
    }
    @include breakpoint(xl) {
        display: none;
    }
}

.show-xl {
    display: block;
    &--inline {
        display: inline-block;
    }
}
