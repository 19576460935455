.content-carousel {
    margin: 50px 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;

    @include theme(br) {
        padding: 60px 0;
        background-color: var(--colors_pale_leaf);

        @include breakpoint(sm, down) {
            padding: 30px 0;
        }
    }

    @include theme(wb) {
        position: relative;
        padding: 88px 0;
        background-color: var(--colors_soft_yellow);

        @include breakpoint(sm, down) {
            padding: 60px 0;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 450px;
            height: 450px;
            background-image: url("../assets/media/texture-related-content.png");
            background-repeat: no-repeat;
            background-position: right top;
            background-size: contain;

            @include breakpoint (md, down) {
                width: 300px;
                height: 300px;
            }

            @include breakpoint (xs, down) {
                display: none;
            }
        }
    }

    &__title {
        margin-bottom: 20px;
        font-size: rem(32px);
        line-height: rem(44px);

        @include theme(dh) {
            font-size: rem(28px);
            line-height: rem(34px);
        }

        @include breakpoint(sm) {
            font-size: rem(42px);
            line-height: rem(50px);

            @include theme(dh) {
                font-size: rem(48px);
                line-height: rem(52px);
            }
        }

        @include theme(br) {
            color: black;
        }
    }

    &__button-col {
        text-align: right;
    }

    &__description {
        margin-bottom: 30px;
        @include breakpoint(sm) {
            margin-bottom: 50px;
        }

        @include theme(br) {
            color: black;
        }

        @include theme(wb) {
            max-width: 70%;

            @include breakpoint(md, down) {
                max-width: 60%;
            }

            @include breakpoint(xs, down) {
                max-width: unset;
            }
        }
    }

    .container-fluid--bleed-right {
        @include breakpoint(sm, down) {
            padding-left: 0;
        }
    }

    &__tiles-container {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
    }

    &__tile-col {
        flex: 1 0 80vw;
        margin-left: 20px;

        &:first-child {
            @include breakpoint(md) {
                margin-left: 0;
            }
        }

        @include breakpoint(sm) {
            flex: 1 0 344px;
        }
    }

    &__controls {
        display: flex;
        justify-content: flex-end;

        .cta,
        .cta:last-child {
            margin-right: 20px;
        }

        @include theme(br) {
            margin-top: 30px;

            .cta {
                color: var(--colors_highlight);
                border-color: var(--colors_highlight);

                &:hover {
                    color: white;
                    border-color: #1e1e1e;
                }
            }

            .carousel__button {
                color: white;
                background-color: var(--colors_highlight);
            }
        }

        @include theme(wb) {
            .cta--secondary {
                border-radius: 2px;
                color: black;

                &:hover {
                    @extend .cta--hover-black-outline;
                }
            }
        }
    }

    .tile {
        // margin-bottom: 60px;

        .tile__title {
            font-size: rem(20px);
            line-height: rem(30px);

            @include theme(dh) {
                font-size: rem(20px);
                line-height: rem(26px);
            }
        }

        .tile__description {
            font-size: rem(16px);
            line-height: rem(26px);

            @include theme(dh) {
                font-size: rem(16px);
                line-height: rem(24px);
            }
        }

        .tile__readmore {
            font-size: rem(14px);
            line-height: rem(17px);
        }

        @include breakpoint(sm) {
            .tile__title {
                font-size: rem(28px);
                line-height: rem(38px);

                @include theme(dh) {
                    font-size: rem(24px);
                    line-height: rem(30px);
                }
            }
            .tile__description {
                font-size: rem(18px);
                line-height: rem(30px);

                @include theme(dh) {
                    font-size: rem(16px);
                    line-height: rem(24px);
                }
            }
            .tile__readmore {
                font-size: rem(14px);
                line-height: rem(17px);
            }
        }
    }

    @include breakpoint(sm) {
    }

    @include breakpoint($breakpoint_desktop_nav) {
        margin: 80px 0;
    }
}
