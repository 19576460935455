$listing_hero_offset: 80px;

.listing-page {
    margin-bottom: 50px;

    @include theme(br) {
        @include breakpoint(sm, down) {
            margin-bottom: 80px;
        }
    }

    @include breakpoint(md) {
        .trending-content {
            display: none;
        }
    }

    @include breakpoint($breakpoint_desktop_nav) {
        margin-bottom: 150px;
    }

    &--headless {
        @include breakpoint($breakpoint_desktop_nav) {
            margin-top: 150px;
        }
    }

    &--stalls,
    &--markets {
        @include breakpoint($breakpoint_desktop_nav) {
            .listing-page__filter-pane__inner {
                display: flex;
                flex-direction: column;
            }

            .listing-page__dates {
                order: 1;
            }

            .listing-page__categories {
                order: 2;
            }

            .listing-page__filters {
                order: 0;
            }
        }
    }

    &__inpage-heading {
        @extend .heading-2;
    }

    &__hero {
        min-height: 0;
        margin-bottom: $layout_page_padding;
        $hero_offset_bottom: -20px;

        @include breakpoint($breakpoint_desktop_nav) {
            min-height: 0vh;
            margin-bottom: -$listing_hero_offset - $hero_offset_bottom;
        }

        .hero__main {
            padding: 45px 10px 0;

            @include breakpoint($breakpoint_desktop_nav) {
                padding-bottom: $listing_hero_offset + $hero_offset_bottom +
                    45px;
                padding-top: 90px;
                min-height: 0;
            }

            @include breakpoint(lg) {
                min-height: calc(700px - #{50px +$hero_offset_bottom});
                &__content {
                    max-width: 630px;
                    width: 90%;
                }
            }

            @include breakpoint(xl) {
                min-height: calc(850px - #{50px +$hero_offset_bottom});
            }

            &__image {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                &__img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &__datepicker-modal {
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: $layout_nav_zindex + 8;
        background: rgba(black, 0.4);
        &.is-active {
            display: flex;
        }
    }

    &__fieldset {
        padding: 0;
        margin: 0;
        border: none;
        border-bottom: 1px solid $colors_grey_med;

        @include breakpoint($breakpoint_desktop_nav) {
            border: none;
        }

        @include theme(br) {
            .filter-dropdown__label {
                &:before {
                    color: white;
                }
            }
        }

        .listing-page__fieldset {
            border-bottom: none;
        }

        > legend {
            display: none;
        }

        .date-container {
            width: auto;
        }
    }

    &__detail {
        font-weight: bold;
        color: var(--colors_highlight);
        margin-bottom: 5px;
        @include rem(font-size, 10px);

        @include theme(dh) {
            color: #99e4d5;
        }

        @include breakpoint($breakpoint_desktop_nav) {
            margin-bottom: 20px;
            font-size: rem(14px);
        }

        @include theme(br) {
            color: white;
            font-size: rem(14px);
            line-height: rem(16px);

            @include breakpoint(xs, down) {
                font-size: rem(12px);
                line-height: rem(14px);
            }
        }

        @include theme(wb) {
            color: white;
        }
    }

    &__title {
        color: white;
        text-transform: uppercase;
        margin: 0;
        @include rem(font-size, 38px);
        @include rem(line-height, 53px);

        @include breakpoint($breakpoint_desktop_nav) {
            @include rem(font-size, 90px);
            @include rem(line-height, 88px);
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
        }

        @include theme(wb) {
            font-size: rem(34px);
            line-height: rem(36px);
            letter-spacing: -0.34px;

            @include breakpoint($breakpoint_desktop_nav) {
                font-size: rem(60px);
                line-height: rem(62px);
                letter-spacing: -1.2px;
            }
        }
    }

    &__description {
        color: white;
        margin-top: 10px;
        @include rem(font-size, 16px);
        @include rem(line-height, 26px);

        @include breakpoint($breakpoint_desktop_nav) {
            margin-top: 25px;
            @include rem(font-size, 20px);
            @include rem(line-height, 32px);
        }

        @include theme(br) {
            letter-spacing: -0.02em;
        }

        @include theme(wb) {
            font-size: rem(20px);
            line-height: rem(30px);
            letter-spacing: -0.4px;

            @include breakpoint($breakpoint_desktop_nav) {
                font-size: rem(22px);
                line-height: rem(34px);
                letter-spacing: -0.22px;
            }
        }
    }
    &__cta {
        margin-top: 20px;

        @include breakpoint (sm, down) {
            margin-bottom: 35px;
        }
    }

    &__filter-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 70px;
        padding: 20px 40px 20px 30px;
        color: white;
        background: $colors_grey_dark;
        @include rem(font-size, 12px);

        @include theme(dh) {
            letter-spacing: var(--font_letterspace_med_wide);
        }

        .icon {
            flex: 0 0 auto;
            color: var(--colors_highlight);

            @include theme(dh) {
                color: #99e4d5;
            }

            &:first-child {
                padding-right: 15px;
            }

            &:last-child {
                padding-left: 15px;
            }
        }

        &__close.icon {
            @include rem(font-size, 18px);
            color: white;
            position: relative;
            right: -0.3em;
            padding-right: 0 !important;
        }

        span {
            flex: 1 1 auto;
            text-transform: uppercase;

            @include theme(br) {
                text-transform: none;
                letter-spacing: -0.02em;
            }
        }

        @include breakpoint($breakpoint_desktop_nav) {
            min-height: 0;
            margin: 30px 0 20px;
            padding: 0;
            background: none;

            .icon {
                display: none;
            }
        }
    }

    &__mobile-filter-toggle {
        position: relative;
        margin: 0 -30px 0;
        background: $colors_grey_dark;

        button {
            display: flex;
            align-items: center;
            padding: 0 $layout_page_padding;
            flex: 1 0 auto;
            height: 60px;
            text-align: left;
            color: white;
            @include rem(font-size, 12px);
            text-transform: uppercase;

            @include theme(dh) {
                font-size: rem(10px);
                line-height: rem(13px);
                letter-spacing: 2px;
            }

            @include theme(br) {
                text-transform: capitalize;
            }

            @include theme(wb) {
                color: white;
            }
        }

        .icon {
            color: var(--colors_highlight);
            padding-right: 15px;

            @include theme(dh) {
                color: #99e4d5;
                font-size: 13px;

                &.icon-map {
                    font-size: 25px;
                }
            }

            @include theme(br) {
                color: var(--colors_rust);
            }
        }

        @include breakpoint($breakpoint_desktop_nav) {
            display: none;
        }
    }

    &__mobile-filter-count {
        color: $colors_grey_dark;
        background-color: var(--colors_highlight);
        border-radius: 100%;
        height: 20px;
        width: 20px;
        margin-left: 5px;
        display: none;
        justify-content: center;
        align-items: center;
        letter-spacing: 0;
        font-size: rem(10px);

        @include theme(dh) {
            background-color: #99e4d5;
        }

        @include theme(br) {
            background-color: var(--colors_rust);
            color: black;
        }
    }

    &__spotlight {
        display: none;
        flex: 0 0 390px;
        max-width: 390px;
        min-height: 100%;
        background: $colors_grey_dark;

        .content-spotlight {
            padding-bottom: $listing_hero_offset + $layout_page_padding;

            @include theme(br) {
                background-color: var(--colors_rust);

                .tile {
                    &__time-description {
                        color: var(--colors_sandstone);
                    }

                    &__content {
                        color: black;
                    }

                    &__title {
                        text-transform: none;
                        color: black;
                        letter-spacing: -0.02em;
                    }

                    &__description {
                        color: black;
                    }

                    &__author {
                        color: black;

                        &__link {
                            color: black;
                        }
                    }

                    &__readmore {
                        .underline {
                            box-shadow: inset 0 -0.2em 0 var(--colors_sandstone);
                        }
                    }
                }
            }
        }

        @include breakpoint($breakpoint_desktop_nav) {
            display: block;
        }

        @include breakpoint(md, only) {
            display: none;
        }
    }

    &__filter-pane {
        position: fixed;
        z-index: $layout_nav_zindex + 4;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        pointer-events: none;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(black, 0.5);
            opacity: 0;
            transition: opacity 500ms;
        }

        &__container {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            right: -80vw;
            height: 100vh;
            width: 80vw;
            background: $colors_black_med;
            transition: transform 500ms;

            @include breakpoint($breakpoint_desktop_nav) {
                display: block;
            }
        }

        &__header {
            flex: 0 0 auto;

            @include theme(br) {
                .icon-filter {
                    color: var(--colors_rust);
                }
            }
        }

        &__footer {
            padding: 25px 30px;
            padding-bottom: 100px;

            @include theme(dh) {
                .cta {
                    background: white;
                    color: $colors_black_med;
                    border-color: white;

                    &:hover {
                        background: $colors_black_med;
                        color: white;
                        border-color: $colors_black_med;
                    }
                }
            }

            .cta {
                text-transform: uppercase;

                @include theme(br) {
                    text-transform: capitalize;

                    background: var(--colors_rust);
                    color: black;
                    border-color: var(--colors_rust);

                    &:hover {
                        background: white !important;
                        color: black;
                        border-color: white;
                    }
                }
            }

            @include breakpoint($breakpoint_desktop_nav) {
                display: none;
            }
        }

        &__inner {
            flex: 1 1 100%;
            overflow: auto;
            -webkit-overflow-scrolling: touch;

            .hide-overflow & {
                overflow: visible;
            }

            @include breakpoint($breakpoint_desktop_nav) {
                overflow: visible;
            }
        }

        &.is-active {
            pointer-events: auto;

            &:before {
                opacity: 1;
            }

            .listing-page__filter-pane__container {
                transform: translate3d(-100%, 0, 0);
            }
        }

        @include breakpoint($breakpoint_desktop_nav) {
            pointer-events: auto;
            position: relative;
            width: auto;
            height: auto;

            &:before {
                content: none;
            }

            &__container {
                position: relative;
                height: auto;
                width: auto;
                background: none;
                right: auto;
                top: auto;
            }

            &.is-active {
                &:before {
                    opacity: 0;
                }

                .listing-page__filter-pane__container {
                    transform: none;
                    transition: none;
                }
            }
        }
    }

    .listing-page__fieldset {
        .listing-page__radio-group,
        .listing-page__checkbox-group {
            padding: 10px 0;

            .listing-page__checkbox-group,
            .listing-page__radio-group {
                padding: 0;
            }

            &:last-child {
                border-bottom: none;
            }

            @include breakpoint($breakpoint_desktop_nav) {
                padding: 0;
            }
        }
    }

    &__checkbox-group:not(.date-container)
        .listing-page__fieldset:first-child
        .filter-dropdown {
        // border-top: 1px solid $colors_grey_med;

        @include breakpoint($breakpoint_desktop_nav) {
            border-top: none;
        }
    }

    &.listing-page--single-column-layout {
        @include breakpoint(xs, only) {
            .listing-page__tile-results {
                & > * {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 0 5px;
                }
            }
        }
    }

    &__tile-results {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -#{$layout_page_padding / 2};
        min-height: 50vh;
        @extend .animated;
        animation-duration: 250ms;

        @include breakpoint($breakpoint_desktop_nav) {
            margin-right: -$main_hero_inset;
            margin-left: -$layout_page_padding;
        }

        & > * {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 #{$layout_page_padding / 2};

            @include theme(dh) {
                .tile__description {
                    font-size: rem(14px);
                    line-height: 1.5em;
                }
            }

            @include breakpoint(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @include breakpoint($breakpoint_desktop_nav) {
                flex: 0 0 33.33333%;
                max-width: 33.33333%;
                padding-left: $layout_page_padding;
                padding-right: 0;
            }

            @include breakpoint(xs, only) {
                .tile__content {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        @for $i from 1 through 5 {
            .tile:nth-of-type(#{$i}) {
                order: ($i - 1);
            }
        }

        .tile:nth-child(n + 6) {
            order: 5;
        }

        &__spotlight {
            order: 7;
            flex: 1 1 100%;
            max-width: none;
            margin: 0 -20px 20px;
            padding: 0;
            width: 100vw;

            @include breakpoint($breakpoint_desktop_nav) {
                display: none;
            }
        }
    }

    &__map-link {
        display: none;
        color: var(--colors_highlight);
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 3px;
        text-decoration: none;

        @include theme(dh) {
            &,
            & i {
                color: #99e4d5;
            }
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
            & i {
                color: var(--colors_rust);

                @include breakpoint(xs, down) {
                    color: var(--colors_rust);
                }
            }

            & {
                color: var(--colors_rust);

                @include breakpoint(xs, down) {
                    color: white;
                }
            }
        }

        i {
            font-size: rem(22px);
            line-height: rem(24px);
            padding-right: 12px;
            color: var(--colors_highlight);
        }
        &--mobile {
            @include theme(dh) {
                font-family: var(--font_family_heading_bold);

                @include breakpoint(sm, down) {
                    font-size: rem(10px);
                    line-height: rem(14px);
                    letter-spacing: 0.2em;
                    color: white;
                }
            }

            @include breakpoint(sm, down) {
                display: inline-flex;
                flex: 0 1 auto !important;
                align-items: center;
                outline: none;
                background-color: $colors_grey_med_dark;
                height: 100%;
                color: white;
            }
        }
        &--desktop {
            @include breakpoint(md, up) {
                display: inline-flex;
                font-size: rem(12px);
                line-height: rem(24px);
                padding-top: 20px;
            }
            @include breakpoint(xl, up) {
                padding-left: 5px;
            }
        }
    }

    .container-fluid--bleed-right {
        padding-right: $layout_page_padding;
        @include breakpoint($breakpoint_desktop_nav) {
            padding-right: $main_hero_inset;
        }
    }

    label {
        color: white;
    }

    [data-listing-page-no-results] {
        margin-top: $listing_hero_offset;
    }

    .pagination{
        order: 7;

        .cta{
            background-color: black;
            color: white;
            border-color: black;
        }

        &__pages {
            margin: 20px 0;
        }
    }
}
