.sitemap {
    color: $colors_grey_med;
    font-size: rem(16px);
    line-height: rem(26px);

    &__inner {
        padding: 0;
        margin: 0;
        padding-left: 35px;
        padding-top: 20px;

        @include theme(br) {
            padding-bottom: 150px;

            @include breakpoint(sm, down) {
                padding-bottom: 60px;
            }
        }
    }

    ul {
        list-style: none;
    }

    li {
        position: relative;
        margin-bottom: 20px;
        &:before {
            content: "";
            position: absolute;
            top: 10px;
            left: -35px;
            height: 12px;
            width: 12px;
            background-color: var(--colors_highlight);
            @include theme(wb) {
                background-color: var(--colors_rust);
            }
        }
    }

    li li:before {
        top: 12px;
        height: 10px;
        width: 10px;
    }

    li li li:before {
        top: 14px;
        height: 8px;
        width: 8px;
    }

    li li li li:before {
        top: 16px;
        height: 6px;
        width: 6px;
    }

    a {
        color: $colors_grey_med;
        text-decoration: none;
    }

    @include breakpoint(sm) {
        font-size: rem(18px);
        line-height: rem(30px);
    }

    @media (min-width: 1024px) and (max-width: 1700px) {
        &__inner {
            padding-left: 10%;
        }
    }
}
