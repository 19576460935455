.datepicker {
    $daySizeSm: 44px;
    $daySizeMd: 48px;

    &__wrapper {
        position: relative;
        background-color: #ffffff;
        color: $colors_grey_med;
        width: 7 * $daySizeSm + 7 * 2px;
        user-select: none;
        border-radius: 4px;
        padding: 20px;
        box-sizing: content-box;

        @include breakpoint($breakpoint_desktop_nav) {
            width: 7 * $daySizeMd + 7 * 2px;
        }
    }

    &__loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: transparentize(white, 0.1);
        border-radius: 4px;
        pointer-events: none;
        opacity: 0;
        transition: opacity 100ms linear;
        z-index: 1;

        &--active {
            display: flex;
            opacity: 1;
        }

        span {
            margin-top: 20px;
        }
    }

    &__header {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 19px;
    }

    &__month-button {
        border: none;
        background: none;
        color: var(--colors_maroon);
        width: (1 / 7) * 100%;
        margin-bottom: 26px;

        @include theme(dh) {
            color: #858585;
        }

        @include theme(br) {
            color: var(--colors_highlight);
        }

        @include theme(wb) {
            color: black;
        }
    }

    &__month {
        width: (5 / 7) * 100%;
        margin-bottom: 26px;
        margin-top: 0;
        text-align: center;
        font-size: rem(20px);
        line-height: rem(36px);

        @include theme(dh) {
            font-family: var(--font_family_heading_extrabold);
            text-transform: uppercase;
            letter-spacing: 0.1em;
        }
    }

    &__days-wrapper,
    &__weekdays {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__weekday {
        color: var(--colors_maroon);
        width: $daySizeSm;
        margin-right: 2px;
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 1px;

        @include theme(dh) {
            color: #858585;
        }

        @include theme(br) {
            color: var(--colors_highlight);
            text-transform: none;
            letter-spacing: -0.02em;
        }

        @include theme(wb) {
            color: black;
        }

        @include breakpoint($breakpoint_desktop_nav) {
            width: $daySizeMd;
        }
    }

    &__days-wrapper:focus {
        outline: none;

        .datepicker__day--focused {
            box-shadow: inset 0 0 0 3px var(--colors_maroon);

            @include theme(dh) {
                box-shadow: inset 0 0 0 3px var(--colors_grey_med);
            }

            @include theme(br) {
                box-shadow: inset 0 0 0 3px var(--colors_highlight);
            }

            @include theme(wb) {
                box-shadow: inset 0 0 0 3px black;
            }
        }

        .datepicker__day--selected.datepicker__day--focused {
            box-shadow: inset 0 0 0 3px var(--colors_maroon);
            // darken

            @include theme(dh) {
                box-shadow: inset 0 0 0 3px var(--colors_grey_med);
            }

            @include theme(br) {
                box-shadow: inset 0 0 0 3px var(--colors_highlight);
            }

            @include theme(wb) {
                box-shadow: inset 0 0 0 3px black;
            }
        }

        .datepicker__day--disabled.datepicker__day--focused {
            box-shadow: inset 0 0 0 3px gray;
        }

        .datepicker__day--closed.datepicker__day--focused {
            box-shadow: inset 0 0 0 3px gray;
        }
    }

    &__day {
        background-color: #f7f7f7;
        height: $daySizeSm;
        width: $daySizeSm;
        margin-right: 2px;
        margin-bottom: 2px;
        font-size: 14px;
        font-weight: 600;
        line-height: $daySizeMd;
        text-align: center;
        cursor: pointer;
        border: none;

        @include breakpoint($breakpoint_desktop_nav) {
            height: $daySizeMd;
            width: $daySizeMd;
        }

        &:hover {
            box-shadow: inset 0 0 0 3px var(--colors_maroon);

            @include theme(dh) {
                box-shadow: inset 0 0 0 3px #9be5d5;
            }

            @include theme(br) {
                box-shadow: inset 0 0 0 3px var(--colors_highlight);
            }

            @include theme(wb) {
                box-shadow: inset 0 0 0 3px black;
            }
        }

        &--today {
            color: var(--colors_maroon);

            @include theme(dh) {
                color: var(--colors_highlight);
            }

            @include theme(br) {
                color: var(--colors_highlight);
            }

            @include theme(wb) {
                color: black;
            }
        }

        &--disabled {
            opacity: 0.5;
            cursor: default;
            &:hover {
                box-shadow: none;
            }
        }

        &--closed {
            cursor: default;
            color: $colors_red;
            text-decoration: line-through;
            &:hover {
                box-shadow: none;
            }
        }

        &--selected {
            background-color: var(--colors_maroon);
            color: #ffffff;

            @include theme(dh) {
                background-color: #9be5d5;
                color: $colors_grey_med;
            }

            @include theme(br) {
                background-color: var(--colors_highlight);
                color: white;
            }

            @include theme(wb) {
                background-color: black;
                color: white;
            }
        }

        &--selected:hover {
            box-shadow: inset 0 0 0 3px var(--colors_maroon);
            // darken

            @include theme(dh) {
                box-shadow: inset 0 0 0 3px $colors_grey_med;
            }
        }

        @for $i from 0 through 6 {
            &--#{$i}-day-push {
                margin-left: $i * ($daySizeSm + 2);
                @include breakpoint(md) {
                    margin-left: $i * ($daySizeMd + 2);
                }
            }
        }
    }
    &__infotext {
        padding-top: 20px;
        font-size: rem(12px);
        line-height: rem(16px);
    }
}

.react-form__date-picker {
    &__icon {
        padding: 0 14px;
        font-size: 20px;
        color: var(--colors_maroon);
        background: $colors_bg_beige;
        cursor: pointer;

        @include theme(dh) {
            color: $colors_grey_med;
        }

        @include theme(br) {
            color: var(--colors_highlight);
        }

        @include theme(wb) {
            color: black;
            background-color: white;
        }

        &--clear {
            font-size: 14px;
            color: $colors_grey_med_light;
        }
    }

    .react-form__field__input-container {
        background: $colors_bg_beige;
        &:before {
            padding: 0 14px;
            order: 3;
            position: static !important;
            background: $colors_bg_beige;
            align-self: center;
            justify-self: center;
        }
    }
}
