.logo-lockup {
    position: relative;
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 100%;
    font-size: rem(44px);
    height: 1em;

    @include breakpoint(sm) {
        font-size: rem(62px);
    }

    .primary-nav & {
        .logo-lockup__logo--nsw {
            display: none;
        }

        @include theme(dh) {
            .logo-lockup__logo--darlingharbour--desktop {
                display: none;
            }

            @include breakpoint($breakpoint_desktop_nav) {
                .logo-lockup__logo--darlingharbour--desktop {
                    display: block;
                }

                .logo-lockup__logo--darlingharbour--long {
                    display: none;
                }

                .logo-lockup__logo--darlingharbour--mobile {
                    display: none;
                }
            }
        }

        @include theme(wb) {
            .logo-lockup__logo--whitebay {
                display: none;
            }

            .logo-lockup__logo--whitebay--mobile {
                display: block;
            }

            @include breakpoint($breakpoint_desktop_nav) {
                .logo-lockup__logo--whitebay {
                    display: block;
                }

                .logo-lockup__logo--whitebay--mobile {
                    display: none;
                }
            }
        }
    }

    .footer & {
        .logo-lockup__logo--therocks {
            display: none;
        }

        @include theme(dh) {
            max-width: 310px;

            .logo-lockup__logo {
                flex-shrink: 1;
            }

            .logo-lockup__logo--darlingharbour--desktop {
                display: none;
            }

            .logo-lockup__logo--darlingharbour--mobile {
                display: none;
            }

            @include breakpoint(sm) {
                .logo-lockup__logo--darlingharbour--mobile {
                    display: none;
                }

                .logo-lockup__logo--darlingharbour--desktop {
                    display: none;
                }
                .logo-lockup__logo--darlingharbour--short {
                    display: none;
                }
            }
        }

        @include theme(br) {
            .logo-lockup__logo--barangaroo {
                display: none;
            }
        }

        @include theme(wb) {
            .logo-lockup__logo--whitebay {
                display: none;
            }
        }
    }

    &__logo {
        display: block;
        position: relative;
        max-width: 100%;
        flex: 0 0 auto;
        color: white;

        &:first-child {
            padding-left: 0;
            border-left: none;
        }

        &:last-child {
            padding-right: 0;
        }

        .footer & {
            border-color: white;

            svg * {
                fill: currentColor;

                @include theme(br) {
                    fill: revert-layer;
                }
            }
        }

        &--nsw {
            height: 100%;
        }

        &--therocks {
            height: 100%;
        }

        &--darlingharbour {
            height: 100%;
        }

        &--whitebay {
            height: 100%;
        }

        svg,
        img {
            display: inline-block;
            height: 100%;
            width: auto;
            max-width: 100%;
        }
    }
}
