.date-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 40px;
    background: var(--colors_teal);
    color: white;
    @include rem(font-size, 12px);

    @include theme(dh) {
        background: #671980;
        @include facet("dark", 0.4, "auto", 50%);
        text-transform: uppercase;
    }

    @include theme(br) {
        background: var(--colors_highlight);
        text-transform: none;
        letter-spacing: -0.02em;
    }

    @include theme(wb) {
        background: var(--colors_rust);
    }

    &__day-wrapper {
        position: relative;
        flex: 0 1 auto;

        &:first-child {
            padding-bottom: 24px;
        }
    }

    &__title {
        position: absolute !important;
        top: 50px;
        left: 60px;
        font-size: rem(14px);
        line-height: rem(17px);

        @include theme(dh) {
            font-weight: normal;
            font-family: var(--font_family_heading_bold);
            letter-spacing: var(--font_letterspace_wide);
            text-transform: uppercase;
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
            top: 57px;
            font-family: var(--font_family_heading_semibold);
        }

        @include theme(wb) {
            font-size: rem(16px);
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.64px;
        }
    }

    &__divider {
        flex-basis: 30px;
        flex-grow: 0;
        flex-shrink: 0;
        height: 2px;
        background-color: white;
        margin: 0 20px;
    }

    &__label {
        font-family: var(--font_family_heading);
        font-size: rem(18px);
        line-height: rem(28px);
        padding-bottom: 5px;
        margin-top: -6px;

        @include theme(br) {
            font-family: var(--font_family_heading_semibold);
        }

        @include theme(wb) {
            line-height: 26px;
            letter-spacing: -0.24px;
            text-transform: uppercase;
        }
    }

    &__time {
        font-size: rem(14px);
        line-height: rem(22px);
        font-weight: 700;
        padding-bottom: 5px;
    }

    &__location {
        font-size: rem(14px);
        line-height: rem(22px);
    }

    @include breakpoint(sm) {
        flex-direction: row;
        padding: 60px;

        &__day-wrapper:first-child {
            margin-right: 60px;
            padding-bottom: 0;

            &:last-child {
                margin-right: 0;
            }
        }

        &__label {
            font-family: var(--font_family_heading);
            font-size: rem(24px);
            line-height: rem(28px);
            padding-bottom: 0.25em;
            margin: 0;
        }

        &__time {
            font-size: rem(16px);
            line-height: rem(19px);
            font-weight: 700;
            padding-bottom: 12px;
        }

        &__location {
            font-size: rem(16px);
            line-height: rem(26px);
        }
    }
    &--Market {
        .date-block__day-wrapper {
        }
    }
    &--Campaign {
        display: none;
        align-items: center;

        @include breakpoint(md) {
            display: flex;
            padding-top: 90px;
        }
    }
}
