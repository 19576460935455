.rich-text {
    color: $colors_grey_med;
    font-size: rem(16px);
    line-height: rem(26px);

    @include theme(dh) {
        color: var(--colors_heading_black);
    }

    @include theme(br) {
        letter-spacing: -0.02em;
    }

    @include theme(wb) {
        color: var(--colors_heading_black);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: var(--font_family_heading);
        font-weight: normal;
        line-height: $font_lineheight_heading;
        letter-spacing: var(--font_letterspace_heading);
        color: var(--colors_heading_black);
        margin: 0;
        + p {
            margin-top: 0;
        }
    }

    h1 {
        font-size: rem(42px);
        line-height: rem(52px);
        text-transform: uppercase;
        padding-bottom: 40px;

        @include theme(dh) {
            font-size: rem(28px);
            line-height: rem(34px);
        }

        @include theme(br) {
            font-family: var(--font_family_heading_bold);
            text-transform: none;
            letter-spacing: -0.02em;
            font-size: rem(34px);
            line-height: (38px);
        }
    }

    h2 {
        font-size: rem(31px);
        line-height: rem(44px);
        padding-bottom: 18px;

        @include theme(dh) {
            font-size: rem(28px);
            line-height: rem(34px);
            text-transform: uppercase;
        }

        @include theme(br) {
            font-family: var(--font_family_heading_bold);
            text-transform: none;
            letter-spacing: -0.02em;
            font-size: rem(28px);
            line-height: (36px);
        }
    }

    h3 {
        font-size: rem(24px);
        line-height: rem(34px);
        padding-bottom: 15px;

        @include theme(dh) {
            font-size: rem(24px);
            line-height: rem(32px);
            text-transform: uppercase;
        }

        @include theme(br) {
            font-family: var(--font_family_heading_bold);
            text-transform: none;
            letter-spacing: -0.02em;
            font-size: rem(24px);
            line-height: rem(32px);

            @include breakpoint (sm, down) {
                font-family: var(--font_family_heading_semibold);
            }
        }
    }

    h4 {
        font-size: rem(20px);
        line-height: rem(30px);
        padding-bottom: 10px;

        @include theme(dh) {
            font-family: var(--font_family_heading_bold);
        }

        @include theme(br) {
            font-family: var(--font_family_heading_semibold);
            letter-spacing: -0.02em;
            font-size: rem(16px);
            line-height: rem(24px);
        }
    }

    h5 {
        font-size: rem(16px);
        line-height: rem(24px);
        padding-bottom: 10px;

        @include theme(dh) {
            font-family: var(--font_family_heading_semibold);
        }

        @include theme(br) {
            font-family: var(--font_family_heading_semibold);
            letter-spacing: -0.02em;
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }
    a:not(.cta) {
        color: $colors_grey_med;
        text-decoration: none;
        @extend .underline;
        word-wrap: break-word;
    }

    ul,
    ol {
        padding: 0;
        margin: 0;
        margin-bottom: 30px;
        padding-left: 35px;
        list-style: none;
        padding-top: 20px;
        counter-reset: licounter;
    }

    li {
        position: relative;
        margin-bottom: 20px;
        &:before {
            content: "";
            position: absolute;
            left: -35px;
        }
    }

    ol li {
        counter-increment: licounter;
        &:before {
            content: counter(licounter) ". ";
            top: 0;
        }
    }

    ul li {
        &:before {
            content: "";
            top: 10px;
            height: 12px;
            width: 12px;
            background-color: var(--colors_highlight);
        }

        @include theme(dh) {
            &:before {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12px 12px 0 0;
                border-color: var(--colors_highlight) transparent transparent
                    transparent;
                background-color: transparent !important;
            }
        }

        @include theme(wb) {
            &:before {
                background-color: var(--colors_rust);
            }
        }
    }

    img {
        max-width: 100%;
        height: auto !important; //rich text editor inserts height/width as inline style
    }

    .table-wrapper {
        overflow-x: auto;
        margin-right: -30px;
        margin-left: -30px;
        @include breakpoint(sm, "down") {
            table {
                display: inline-block;
                min-width: 100%;
            }
        }

        &__inner {
            display: inline-block;
            margin-left: 30px;
            margin-right: 30px;
        }
    }

    table {
        border-collapse: collapse;
        border: 0;

        td,
        th,
        tr,
        thead,
        tbody {
            border: 0;
        }

        thead {
            th {
                background-color: $colors_grey_dark;
                color: white;
                font-size: rem(20px);
                line-height: rem(32px);
                font-weight: bold;
                border-bottom: 5px solid var(--colors_highlight);
                vertical-align: middle;
                text-align: center;
                padding: 18px 20px;

                @include theme(wb) {
                    border-bottom: 5px solid var(--colors_rust);
                }
            }
        }

        tbody {
            font-size: rem(16px);
            line-height: rem(26px);
            th,
            td {
                min-width: 150px;
            }

            th {
                background-color: $colors_grey_light_dark;
                border-right: 5px solid $colors_bg_beige;
                vertical-align: top;
                text-align: right;
                padding: 20px;
                padding-left: 50px;
                font-weight: normal;
            }

            td {
                background-color: white;
                border-bottom: 1px solid $colors_grey_light_dark;
                vertical-align: top;
                text-align: center;
                padding: 20px;

                &:nth-child(2n + 1) {
                    background-color: $colors_bg_beige;
                }

                &:first-child {
                    border-left: 2px solid $colors_grey_light_dark;
                }

                &:last-child {
                    border-right: 2px solid $colors_grey_light_dark;
                }
            }
            tr:last-child td,
            tr:last-child th {
                border-bottom: 2px solid $colors_grey_light_dark;
            }
        }
    }

    @include breakpoint(sm) {
        font-size: rem(18px);
        line-height: rem(30px);

        h1 {
            font-size: rem(42px);
            line-height: rem(52px);
            padding-bottom: 80px;

            @include theme(dh) {
                font-size: rem(48px);
                line-height: rem(52px);
            }

            @include theme(br) {
                font-size: rem(48px);
                line-height: (52px);
            }

            @include theme(wb) {
                text-transform: uppercase;
            }
        }

        h2 {
            font-size: rem(38px);
            line-height: rem(54px);

            @include theme(dh) {
                font-size: rem(38px);
                line-height: rem(48px);
            }

            @include theme(br) {
                font-size: rem(38px);
                line-height: (48px);
            }

            @include theme(wb) {
                text-transform: uppercase;
            }
        }

        h3 {
            font-size: rem(28px);
            line-height: rem(40px);

            @include theme(dh) {
                font-size: rem(28px);
                line-height: rem(36px);
            }

            @include theme(br) {
                font-size: rem(28px);
                line-height: rem(36px);
            }

            @include theme(wb) {
                text-transform: uppercase;
            }
        }

        h4 {
            font-size: rem(24px);
            line-height: rem(34px);

            @include theme(dh) {
                font-size: rem(24px);
                line-height: rem(32px);
            }

            @include theme(br) {
                font-size: rem(24px);
                line-height: rem(32px);
            }

            @include theme(wb) {
                text-transform: uppercase;
            }
        }

        h5 {
            font-size: rem(20px);
            line-height: rem(28px);

            @include theme(dh) {
                font-size: rem(20px);
                line-height: rem(30px);
            }

            @include theme(br) {
                font-size: rem(20px);
                line-height: rem(28px);
            }

            @include theme(wb) {
                text-transform: uppercase;
            }
        }

        h6 {
            @include theme(wb) {
                text-transform: uppercase;
            }
        }

        ul,
        ol {
            padding: 0;
            margin: 0;
            padding-left: 35px;
            list-style: none;
            padding-top: 20px;
            counter-reset: licounter;
        }

        li {
            position: relative;
            margin-bottom: 20px;
            &:before {
                content: "";
                position: absolute;
                left: -35px;
            }
        }
    }

    & > *:first-child {
        margin-top: 0;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
}

.rich-text-component {
    overflow: hidden;
    margin: 50px 0;

    @include breakpoint(sm) {
        margin: 80px 0;

        @include theme(br) {
            margin: 80px 0 150px;
        }
    }
}
