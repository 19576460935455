.search-page-wrapper {
    position: relative;
    overflow: hidden;
    &:before {
        display: none;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: -1;
        background-color: #f7f7f7;
    }
    @include breakpoint(sm) {
        &:before {
            display: block;
            top: 30px;
            left: 30px;
            width: calc(100vw - 60px);
            height: 99999px;
        }
    }
}

.search-header {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: -1;
        background-color: #f7f7f7;
    }
    &__inner {
        margin: 30px 10px 0 10px;
    }

    &__heading {
        font-size: rem(28px);
        line-height: rem(34px);
        margin: 0;
        margin-bottom: 20px;

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
        }
    }

    &__results-label {
        font-size: rem(16px);
        line-height: rem(19px);
        font-weight: 700;
        margin-bottom: 30px;

        @include theme(dh) {
            font-weight: normal;
        }
    }

    .live-search {
        &__button,
        &__input {
            color: $colors_grey_dark;

            @include theme(dh) {
                &::placeholder {
                    color: rgba($colors_grey_med, 0.55);
                }
            }

            &--clear {
                i {
                    font-size: rem(15px);
                }
            }
        }

        &__input {
            font-size: rem(20px);
            line-height: rem(44px);

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                display: none;
            }
        }

        &__form {
            margin: 0;
            margin-bottom: 30px;
            &:before {
                height: 3px;
                background-color: rgba($colors_grey_dark, 0.2);
            }
        }
    }

    @include breakpoint(sm) {
        &:before {
            display: none;
        }
        &__inner {
            margin: 140px 40px 0 40px;
        }

        &__heading {
            font-size: rem(58px);
            line-height: rem(70px);
            margin-bottom: 50px;
        }

        &__results-label {
            font-size: rem(18px);
            line-height: rem(22px);
            margin-bottom: 50px;
        }

        .live-search {
            &__input {
                font-size: rem(25px);
                line-height: rem(44px);
            }
        }
    }

    @include breakpoint(md) {
        &__inner {
            margin: 140px 40px 0 150px;
        }
    }
}
