.cta-banner {
    position: relative;
    background-color: var(--colors_highlight);
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 55px $layout_page_padding;

    @include theme(dh) {
        background-color: var(--colors_green_light);
        @include facet("light", 0.2, 100%);

        @include breakpoint($breakpoint_desktop_nav) {
            @include facet("light", 0.2, auto, 100%);
        }

        &--pink {
            background-color: var(--colors_highlight);
            @include facet("dark", 0.4, 100%);

            @include breakpoint($breakpoint_desktop_nav) {
                @include facet("dark", 0.4, auto, 100%);
            }
        }

        &--purple {
            background-color: #671980;
            @include facet("dark", 0.4, 100%);

            @include breakpoint($breakpoint_desktop_nav) {
                @include facet("dark", 0.4, auto, 100%);
            }
        }

        &--dark {
            background-color: #1e1e1e;
            @include facet("light", 0.04, 100%);

            @include breakpoint($breakpoint_desktop_nav) {
                @include facet("light", 0.04, auto, 100%);
            }
        }
    }

    @include theme(br) {
        background-color: var(--colors_rust);
    }

    @include theme(wb) {
        background-color: black;

        .cta-banner__eyebrow,
        .cta-banner__heading,
        .cta-banner__description {
            color: white;
        }

        .cta--dark {
            background: var(--colors_highlight);
            border-color: var(--colors_highlight);
            color: var(--colors_body_black);

            &:hover {
                background: #64816B !important;
                border-color: #64816B !important;
                color: black !important;
            }
        }

        .rich-text {
            color: white;
        }

        &--muted,
        &--green {
            background-color: var(--colors_highlight);

            .cta-banner__eyebrow,
            .cta-banner__heading,
            .cta-banner__description {
                color: black;
            }

            .cta--dark {
                background: black;
                border-color: black;
                color: white;

                &:hover {
                    background: #333 !important;
                    border-color: #333 !important;
                    color: white !important;
                }
            }
        }

        &--yellow,
        &--green,
        &--paleblue {
            .cta-banner__eyebrow,
            .cta-banner__heading,
            .cta-banner__description {
                color: black;
            }

            .cta--dark {
                background: black;
                border-color: black;
                color: white;

                &:hover {
                    background: #333 !important;
                    border-color: #333 !important;
                    color: white !important;
                }
            }
        }

        &--green {
            background-color: var(--colors_highlight);
        }

        &--yellow {
            background-color: var(--colors_soft_yellow);
        }

        &--paleblue {
            background-color: var(--colors_pale_blue);
        }
    }

    // dark mode sub styles
    &--teal,
    &--dark,
    &--pink,
    &--purple {
        &,
        .cta-banner__eyebrow,
        .cta-banner__heading,
        .cta-banner__description {
            color: white;

            @include theme(br) {
                color: white;
            }
        }

        .cta {
            @extend .cta--white;

            @include theme(dh) {
                @extend .cta--white;

                &:hover {
                    color: white;
                    border-color: #1e1e1e !important;
                }
            }
        }
    }

    &--grey {
        background-color: #eef2e8;

        .cta {
            @extend .cta--secondary;
        }
    }

    &--teal {
        background-color: #00758d;
    }

    &--dark {
        background-color: #2d2d2d;

        .cta {
            @include theme(dh) {
                &:hover {
                    background-color: var(--colors_teal) !important;
                    border-color: var(--colors_teal) !important;
                    color: black;
                }
            }
        }
        @include theme(br) {
            background-color: #2d2d2d;
        }
    }

    @include breakpoint(sm) {
        padding: 67px 20%;
    }

    @include breakpoint(lg) {
        padding: 67px 25%;
    }

    @include breakpoint(xl) {
        padding: 67px 30%;
    }

    &--inline {
        padding: 32px $layout_page_padding 48px;

        @include breakpoint(sm) {
            padding: 57px 10% 67px;
        }

        @include breakpoint(lg) {
            padding: 57px 15% 67px;
        }

        @include breakpoint(xl) {
            padding: 57px 20% 67px;
        }
    }

    &__eyebrow {
        font-size: rem(10px);
        line-height: rem(12px);
        color: $colors_grey_dark;
        font-weight: bold;
        opacity: 0.65;

        .cta-banner--pink & {
            opacity: 1;
        }

        @include breakpoint(sm) {
            font-size: rem(12px);
            line-height: rem(15px);
        }

        @include theme(br) {
            color: black;
        }

        @include theme(wb) {
            color: white;
            opacity: 1;
        }
    }

    &__heading {
        font-size: rem(24px);
        line-height: rem(34px);
        margin-top: 6px;
        margin-bottom: 10px;

        @include theme(dh) {
            font-size: rem(24px);
            line-height: rem(32px);
        }

        @include theme(br) {
            letter-spacing: -0.02em;
        }

        @include theme(wb) {
            color: white;
        }

        @include breakpoint(sm) {
            font-size: rem(28px);
            line-height: rem(40px);

            @include theme(dh) {
                font-size: rem(28px);
                line-height: rem(36px);
            }
        }
    }

&__description {
        font-size: rem(16px);
        line-height: rem(26px);
        margin-bottom: 10px;
        color: $colors_grey_med;

        @include breakpoint(sm) {
            margin-bottom: 25px;
        }

        @include theme(br) {
            color: black;
        }

        @include theme(wb) {
            margin-bottom: 24px;
        }
    }

    .cta {
        height: 60px;
        padding-left: 70px;
        padding-right: 70px;

        @include theme(dh) {
            background: var(--colors_highlight);
            color: white;
            border-color: var(--colors_highlight);

            &:hover {
                border-color: $colors_black_med;
            }
        }
    }
}
