.info-image-header {
    position: relative;
    width: 100%;

    @include theme(br) {
        margin-bottom: 50px;
        @include breakpoint(sm) {
            margin-bottom: 95px;
        }
    }

    &__hero {
        position: relative;
        padding: 0;
    }

    &__row {
        margin-left: -$layout_page_padding;
    }

    &.info-image-header--type-Event,
    &.info-image-header--type-Offer {
        .info-card__wrapper {
            @include theme(br) {
                background: var(--colors_sandstone);
            }
        }
    }

    &__container {
        padding-bottom: 75px;
    }

    &__gallery-link.cta {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: transparentize($colors_body_black, 0.5);
        border: none;

        &:hover {
            background-color: transparentize(
                $colors_body_black,
                0.5
            ) !important;
            color: var(--colors_highlight);

            @include theme(br) {
                color: var(--colors_rust);
            }
        }

        i {
            font-size: 1.2em;
            margin-left: 0;
            margin-right: 5px;
        }
    }

    h2#{&}__heading {
        padding-top: 40px;
        margin-top: 0;
        font-size: rem(24px);
        line-height: rem(34px);
        color: $colors_grey_med;

        @include theme(dh) {
            font-family: var(--font_family_heading_regular);
            text-transform: none;
            color: var(--colors_heading_black);
        }

        @include theme(br) {
            letter-spacing: -0.02em;
            font-family: var(--font_family_heading_bold);
            font-size: rem(24px);
            line-height: (32px);

            @include breakpoint(lg) {
                font-size: rem(28px);
                line-height: rem(36px);
            }
        }

        @include theme(wb) {
            letter-spacing: -0.24px;
            font-size: rem(24px);
            line-height: (28px);
            text-transform: uppercase;

            @include breakpoint(lg) {
                font-size: rem(28px);
                line-height: rem(32px);
                letter-spacing: -0.28px;
            }
        }
    }

    &__content {
        margin-right: $layout_page_padding;
        margin-left: $layout_page_padding;
        color: $colors_grey_med;
        width: 100%;

        @include theme(br) {
            @include breakpoint(xs, down) {
                width: auto;
            }
        }

        @include theme(wb) {
            @include breakpoint(xs, down) {
                width: auto;
            }
        }
    }

    &__button {
        width: 100%;
        transition: opacity 1000ms;
        display: inline-block;
        letter-spacing: 3.4px;
        text-decoration: none;
        text-transform: uppercase;
        border-radius: 16px;
        font: 700 #{rem(12px)}/#{rem(36px)} var(--font_family_main);
        height: 34px;
        border: none;
        background-color: $colors_grey_light;
        margin-top: 15px;
        overflow: hidden;

        @include theme(dh) {
            font-family: var(--font_family_heading_medium);
            border-radius: 3px;
            color: rgba(#1e1e1e, 0.65);
            letter-spacing: var(--font_letterspace_med_wide);
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
        }
    }

    &__card {
        margin-left: -$layout_page_padding / 2;
        flex-basis: 100%;
        flex-grow: 1;
        flex-shrink: 0;

        @include theme(br) {
            @include breakpoint(sm) {
                padding-bottom: 80px;
            }
        }
    }

    &--type-Event .info-image-header__card {
        .info-card__header-image-wrapper,
        .info-flat-header__gallery-link {
            display: none;
        }
        .cta {
            margin-bottom: 40px;
        }
    }

    @include breakpoint(sm) {
        &__container {
            padding-right: $main_hero_inset;
        }

        &__row {
            display: flex;
            margin: 0;
        }

        &__card {
            flex-basis: 385px;
            width: 385px;

            .info-image-header--type-Venue & {
                margin-top: -240px;

                @media (max-height: 660px) {
                    margin-top: -100px;
                }
            }
        }

        &--type-Stall .info-image-header__card {
            margin-top: -280px;
        }
        &--type-Event .info-image-header__card,
        &--type-Tenant .info-image-header__card,
        &--type-Precinct .info-image-header__card {
            margin-top: -220px;
        }
        &--type-Event .info-image-header__card {
            .cta {
                margin-bottom: 25px;
            }
        }

        &__content {
            font: #{rem(18px)}/#{rem(30px)} var(--font_family_main);
            color: $colors_grey_med;
            padding-right: 30px;
            margin-left: 0;
        }

        h2#{&}__heading {
            padding-top: 80px;
            letter-spacing: -0.6px;
            color: $colors_grey_med;
            font: #{rem(30px)}/#{rem(44px)} var(--font_family_heading);

            @include theme(dh) {
                color: var(--colors_heading_black);
            }
        }

        &__hero {
            padding-top: $main_hero_inset;
            padding-left: $main_hero_inset;
            padding-right: $main_hero_inset;
            overflow: hidden;
            max-height: calc(100vh - 130px);
        }

        &__gallery-link.cta {
            transform: none;
            top: $main_hero_inset + 30px;
            right: $main_hero_inset + 30px;
            left: auto;
            z-index: 10;
        }
    }

    @include breakpoint(md) {
        &__card {
            min-width: 385px;
        }
        &--type-Event .info-image-header__card,
        &--type-Tenant .info-image-header__card,
        &--type-Precicnt .info-image-header__card {
            margin-top: -380px;
        }
        &__content {
            padding-right: 100px;
        }
    }

    @include breakpoint(lg) {
        &__card {
            flex-basis: 385px;
            width: 385px;
        }
    }

    @include breakpoint(xl) {
        &__card {
            flex-basis: 540px;
            width: 540px;
        }
    }
}
