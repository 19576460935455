// fade

[class^="fade-"] {
    transform: translate3d(0, 0, 0);
}

.fade-enter {
    opacity: 0.01;
    transition-delay: 500ms;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms;
}

// modalFade

[class^="modalFade-"] {
    // transform: translate3d(0, 0, 0);
}

.modalFade-enter {
    &:before {
        opacity: 0.01;
        transition-delay: 500ms;
    }

    .modal__content {
        transform: translate3d(-100%, 0, 0);
        transition-delay: 500ms;
    }
}

.modalFade-enter-active {
    &:before {
        opacity: 1;
        transition: opacity 500ms;
    }
    .modal__content {
        transform: translate3d(0, 0, 0);
        transition: transform 500ms;
    }
}

.modalFade-exit {
    &:before {
        opacity: 1;
    }
    .modal__content {
        transform: translate3d(0, 0, 0);
    }
}

.modalFade-exit-active {
    &:before {
        opacity: 0.01;
        transition: opacity 500ms;
    }
    .modal__content {
        transform: translate3d(-100%, 0, 0);
        transition: transform 500ms;
    }
}

// slide in left

[class^="slideInLeft-"] {
    transform: translate3d(0, 0, 0);
}

.slideInLeft-enter {
    //opacity: 0.01;
    transform: translate3d(-100%, 0, 0);
    transition-delay: 500ms;
}

.slideInLeft-enter-active {
    // opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform 500ms;
}

.slideInLeft-exit {
    // opacity: 1;
    transform: translate3d(0, 0, 0);
}

.slideInLeft-exit-active {
    // opacity: 0.01;
    transform: translate3d(-100%, 0, 0);
    transition: transform 500ms;
}

// slide in left

[class^="slideInUp-"] {
    transform: translate3d(0, 0, 0);
}

.slideInUp-enter {
    //opacity: 0.01;
    transform: translate3d(0, 100%, 0);
    transition-delay: 500ms;
}

.slideInUp-enter-active {
    // opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform 500ms;
    position: absolute;
}

.slideInUp-exit {
    // opacity: 1;
    transform: translate3d(0, 0, 0);
}

.slideInUp-exit-active {
    // opacity: 0.01;
    transform: translate3d(0, 100%, 0);
    transition: transform 500ms;
}

// page

[class^="page-transition"],
[class*=" page-transition"] {
    position: relative;
    background: white;
}

.page-transition-enter {
    opacity: 0.01;
    transition-delay: 500ms;
}

.page-transition-enter-active {
    opacity: 1;
    transition: opacity 500ms;
    transition-delay: 500ms;
}

.page-transition-exit {
    position: absolute !important;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
}

.page-transition-exit-active {
    opacity: 0.01;
    transition: opacity 500ms;
}
@keyframes maskIn {
    0% {
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes maskOut {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
    }
}

@keyframes maskInOut {
    0% {
        visibility: visible;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
    49% {
        visibility: hidden;
        clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
    }
    50% {
        visibility: visible;
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes maskInLeft {
    0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}

@keyframes maskOutRight {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    }
}

@keyframes delaySlideOutUp {
    0% {
        transform: translate3d(0, 0, 0);
    }
    50% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        visibility: hidden;
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes fadeInAbsolute {
    from {
        //position: absolute;
        opacity: 0;
    }

    to {
        //position: relative;
        opacity: 1;
    }
}

@keyframes fadeOutAbsolute {
    from {
        position: relative;
        opacity: 1;
    }

    to {
        position: absolute;
        opacity: 0;
    }
}

@keyframes fadeInDownAbsolute {
    from {
        position: absolute;
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    to {
        position: relative;
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeOutDownAbsolute {
    from {
        position: absolute;
        opacity: 1;
        transform: none;
    }

    to {
        position: absolute;
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes fadeInUpAbsolute {
    from {
        position: absolute;
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        position: relative;
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeOutUpAbsolute {
    from {
        position: relative;
        opacity: 1;
        transform: none;
    }

    to {
        position: absolute;
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes drawIn {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes drawOut {
    0% {
        width: 100;
    }
    100% {
        width: 0%;
    }
}

@keyframes fadeInUpSmall {
    from {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeOutUpSmall {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0, -10%, 0);
    }
}

// Fade
@mixin fadeHide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s;
}
@mixin fadeShow {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
}

// FadeUp
@mixin fadeUpHide {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: visibility 0s 0.3s, opacity 0.3s, transform 0.3s;
}
@mixin fadeUpShow {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
}

@keyframes tadaSmall {
    from {
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -1.5deg);
    }

    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, 1.5deg);
    }

    40%,
    60%,
    80% {
        transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, -1.5deg);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}

.tadaSmall {
    animation-name: tadaSmall;
}
@keyframes fadeOutDownSmall {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0, 32px, 0);
    }
}

.fadeOutDownSmall {
    animation-name: fadeOutDownSmall;
}
@keyframes fadeInUpSmall {
    from {
        opacity: 0;
        transform: translate3d(0, 32px, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInUpSmall {
    animation-name: fadeInUpSmall;
}
@keyframes carouselHoverIn {
    0% {
        z-index: 1;
        transform: scale(1);
    }

    1% {
        z-index: 2;
    }

    100% {
        z-index: 2;
        transform: scale(1.1);
    }
}
@keyframes carouselHoverOut {
    100% {
        z-index: 1;
        transform: scale(1);
    }

    1% {
        z-index: 2;
    }

    100% {
        transform: scale(1.1);
    }
}
