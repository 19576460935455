.instagram-feed {
    margin: 50px 0;
    @include breakpoint(md) {
        margin: 80px 0;
    }
    @include breakpoint(xl) {
        margin: 130px 0;
    }

    &__scroller {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        margin-left: -$layout_page_padding;

        & > *:first-child {
            margin-left: $layout_page_padding;
        }
    }

    &__tiles {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-width: 700px;

        &__feature {
            position: relative;
            flex: 0 1 39.025%;
        }

        &__remaining {
            position: relative;
            height: 100%;
            flex: 1 1 auto;
            align-items: center;

            & > * {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                height: 50%;
                overflow: hidden;

                & > * {
                    flex: 0 1 32%;
                }

                &:nth-child(odd) {
                    align-items: flex-end;
                    & > *:nth-child(1) {
                        flex: 0 1 36%;
                    }
                }

                &:nth-child(even) {
                    align-items: flex-start;
                    & > *:nth-child(2) {
                        flex: 0 1 36%;
                    }
                }
            }
        }
    }

    &__tile,
    &__link {
        display: block;
        @include aspect-ratio(1, 1);
        background-size: cover;
    }

    &__handle {
        padding-bottom: 0.25em;

        @include theme(dh) {
            text-transform: none;
            font-size: rem(28px);
            line-height: rem(34px);
            letter-spacing: -0.02105263158em;
        }

        @include breakpoint(md) {
            transform: translateY(1em);

            @include theme(dh) {
                transform: translateY(0.5em);
                font-size: rem(38px);
                line-height: rem(48px);
            }
        }
    }

    &__link {
        position: relative;

        &__inner {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $colors_grey_dark;
            padding: 10px;
            color: white;
            font-family: var(--font_family_heading);
            font-size: rem(14px);
            text-align: center;

            @include theme(dh) {
                background-color: var(--colors_highlight);
                @include facet("dark", 0.4, 100%, 100%);
            }

            @include breakpoint(md) {
                font-size: rem($font_size_base);
            }

            & > * {
                margin: 0.2em 0;
                @include breakpoint(md) {
                    margin: 0.4em 0;
                }
            }

            .icon {
                color: var(--colors_highlight);

                @include theme(dh) {
                    color: white;
                }

                @include breakpoint(md) {
                    font-size: (24 / 16) + em;
                }
            }
        }

        &__handle {
            word-break: break-all;
        }
    }
}
