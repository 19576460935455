.accordion-group {
    margin: 0;

    .content {
        *:first-child {
            margin-top: 0;
        }
        *:last-child {
            margin-bottom: 0;
        }
    }

    .react-form__field.col-xs-6 {
        margin: 9px 0;
    }
}
