.skip-links {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    h2 {
        display: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a {
        color: white;
        background-color: black;
        text-decoration: none;
        font-size: rem(12px);
        line-height: rem(12px);
        padding: 4px;
        left: -999px;
        position: absolute;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
        z-index: -999;
        white-space: nowrap;

        &:focus,
        &:active {
            left: auto;
            top: auto;
            height: auto;
            width: auto;
            overflow: auto;
            z-index: 999;
        }
    }
}
