$header_inset: 30px;

.info-blocks-header {
    position: relative;

    @include breakpoint($breakpoint_desktop_nav) {
        margin-bottom: $layout_page_padding;
        padding: $header_inset $header_inset 0;
    }

    .container-fluid {
        @include breakpoint($breakpoint_desktop_nav) {
            padding-right: $header_inset;
            padding-left: $layout_page_padding +
                $layout_nav_desktop_width_compact - $header_inset;
        }
    }

    &__container {
        display: flex;
        flex-direction: row;
    }

    &__inner {
        flex: 1 1 auto;
    }

    &__main {
        position: relative;

        &__description {
            font-size: rem(14px);
            line-height: rem(19px);

            @include theme(br) {
                color: black;
                margin-bottom: 20px;
            }

            @include theme(wb) {
                font-size: rem(16px);
                font-weight: 400;
                line-height: rem(24px);
                letter-spacing: -0.16px;
                color: black;
            }
        }

        @include breakpoint($breakpoint_desktop_nav) {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            min-height: calc(100vh - #{$header_inset * 2});
            padding-top: $header_inset;
        }

        & > * {
            flex: 1 1 auto;
        }

        &__image {
            @include aspect-ratio(16, 9);
            background-size: cover;
            background-position: center center;
            position: relative;

            &__img {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include breakpoint($breakpoint_desktop_nav) {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }

    &__video {
        @include youtube();

        video {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
        }
    }

    &__gallery-link.cta {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: transparentize($colors_body_black, 0.5);
        border: none;
        z-index: 1;

        &:hover {
            background-color: transparentize(
                $colors_body_black,
                0.5
            ) !important;
            color: var(--colors_highlight);

            @include theme(br) {
                color: var(--colors_rust);
            }
        }

        i {
            font-size: 1.2em;
            margin-left: 0;
            margin-right: 5px;
        }
    }

    &__link {
        color: $colors_grey_light;
        @extend .underline;
        text-decoration: none;
        font-weight: bold;
        margin-top: 20px;

        @include theme(dh) {
            font-size: rem(14px);
            line-height: rem(17px);
            font-weight: normal;
            font-family: var(--font_family_heading);
            letter-spacing: var(--font_letterspace_wide);
            text-transform: uppercase;
            box-shadow: inset 0 -0.15em 0 rgba(white, 0.7);

            &:hover {
                color: $colors_grey_dark;
                box-shadow: inset 0 -2em 0 white;
            }
        }

        @include theme(br) {
            color: black;

            &:hover {
                box-shadow: inset 0 -8px 0 var(--colors_highlight);
            }
        }

        @include theme(wb) {
            color: black;

            &:hover {
                box-shadow: inset 0 -8px 0 var(--colors_body_black);
            }
        }
    }

    &__bottom {
        position: relative;

        @include breakpoint($breakpoint_desktop_nav) {
            margin-top: -100px;
        }

        .info-blocks-header--hide-date-block & {
            @include breakpoint($breakpoint_desktop_nav) {
                margin-top: -200px;
            }
        }
    }

    &__tile-wrapper {
        position: relative;
    }

    &__tile {
        position: relative;
        @include rem(font-size, 17px);

        @include theme(br) {
            &--content {
                overflow-y: hidden;
                &:before {
                    content: "";
                    background-image: url("../assets/media/barangaroo-lines-rust-right.png");
                    display: block;
                    bottom: 20px;
                    right: -10px;
                    width: 100%;
                    height: 90px;
                    position: absolute;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: right bottom;
                }
            }

            @include breakpoint(sm, down) {
                overflow-y: initial;
                &--content {
                    &:before {
                        right: 0;
                        height: 65px;
                    }
                }
            }
        }

        &__date {
            position: absolute;
            top: -50px;
            right: 0;
            @include breakpoint(md) {
                display: none;
            }
        }

        &__inner {
            position: relative;
            padding: 40px;
            padding-bottom: 48px;
        }

        &__title {
            color: $colors_grey_light;
            margin-top: 5px;
            margin-bottom: 15px;

            @include theme(dh) {
                color: white;
                font-size: rem(28px);
                line-height: rem(34px);
                margin-top: 10px;

                @include breakpoint($breakpoint_desktop_nav) {
                    margin-top: 20px;
                    font-size: rem(48px);
                    line-height: rem(52px);
                }
            }

            @include theme(br) {
                color: black;
                font-weight: 700;
                text-transform: none;
                letter-spacing: -0.02em;
                font-size: rem(34px);
                line-height: (38px);

                @include breakpoint(lg) {
                    font-size: rem(48px);
                    line-height: (52px);
                }
            }

            @include theme(wb) {
                color: black;
                font-weight: 500;
                letter-spacing: -0.34px;
                font-size: rem(34px);
                line-height: (36px);

                @include breakpoint(lg) {
                    font-size: rem(48px);
                    line-height: (50px);
                    letter-spacing: -0.48px;
                }
            }
        }

        .eyebrow {
            @include rem(font-size, 12px);
            color: var(--colors_highlight);
            font-weight: bold;
            margin-top: 8px;

            @include theme(dh) {
                color: white;
            }

            @include theme(br) {
                font-size: rem(14px);
                color: black;
            }

            @include theme(wb) {
                font-size: rem(12px);
                color: black;
                line-height: 20px;
                letter-spacing: 0.64px;

                @include breakpoint(md) {
                    font-size: rem(16px);
                }
            }
        }

        .cta {
            margin-top: 30px;
        }

        &--content {
            color: $colors_grey_light;
            padding-bottom: 40px;
            background-color: $colors_black_med;

            @include theme(dh) {
                background: #eb054e;
                @include facet("dark", 0.4, 100%, auto);
            }

            @include theme(br) {
                background: var(--colors_sandstone);
            }

            @include theme(wb) {
                background: var(--colors_soft_yellow);
            }

            @media (max-width: $breakpoint_md) {
                margin: 0 -#{$layout_page_padding};
            }

            @include breakpoint($breakpoint_desktop_nav) {
                @include linear-gradient(
                    to bottom,
                    rgba($colors_black_med, 0.8) 0%,
                    rgba($colors_black_med, 1) 70%
                );

                @include theme(dh) {
                    background: #eb054e;
                    @include facet("dark", 0.4, auto, 100%);
                }
            }
        }

        &--date {
            margin-bottom: $header_inset;
            .info-blocks-header__tile__inner {
                background: var(--colors_maroon);
            }

            .info-blocks-header--hide-date-block & {
                @include breakpoint(md) {
                    position: absolute;
                    left: 40px;
                    top: 100px;
                    z-index: -1;
                }
            }
        }

        &--map {
            margin-top: -50px;
            @include linear-gradient(
                to bottom,
                rgba($colors_grey_med, 0.8) 0%,
                rgba($colors_grey_med, 1) 70%
            );
            @include breakpoint($breakpoint_desktop_nav) {
                margin-top: 200px;
            }
        }
    }

    &__map {
        position: relative;
        display: block;
        height: 100px;
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 140px;
            top: 0;
            left: 0;
            object-fit: cover;
        }
        i {
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 15px);
            color: $colors_grey_dark;
            font-size: rem(40px);
        }
    }

    &__shadow-block {
        @include aspect-ratio(1, 1);
        background-color: transparent;

        @include theme(dh) {
            background: #cdf2ea;
            background-image: none !important;
            @include facet("dark", 0.2, 100%);
        }

        @include theme(br) {
            display: none;
        }

        @include theme(wb) {
            background-image: url("../assets/media/texture-campaign_wb.png") !important;
        }

        &--date {
            @include aspect-ratio(180, 150);
            position: absolute;
            width: 180px;
            bottom: -$header_inset;
            left: -$header_inset;
            z-index: -1;
        }

        &--content {
            @include aspect-ratio(356, 264);
            position: absolute;
            width: 321px;
            left: -20px;
            bottom: -$header_inset;

            @include breakpoint(md) {
                width: 356px;
                left: auto;
                right: -$header_inset;
            }
        }
    }

    &__description {
        margin-top: 40px;
    }

    @include breakpoint(md) {
        min-height: 100vh;
        &__tile__inner {
            padding: 80px;
            padding-bottom: 60px;
        }

        &__tile {
            &--content {
                margin-bottom: 0;
            }
            &--date {
                margin-left: 60px;
            }

            &--map {
                margin-top: 240px;

                .info-blocks-header--hide-date-block & {
                    margin-top: 0;
                }
            }

            .eyebrow {
                @include rem(font-size, 14px);
                margin-top: 5px;
            }
        }

        &__description {
            margin-top: 140px;

            @include theme(dh) {
                font-size: rem(16px);
                line-height: rem(24px);
            }

            @include theme(br) {
                font-size: rem(16px);
                font-weight: 400;
                line-height: rem(24px);
            }
        }

        &__main__description {
            font-size: rem(18px);
            line-height: rem(26px);
        }

        &__map {
            height: 380px;
            img {
                height: 420px;
            }
        }

        &__gallery-link.cta {
            top: 30px;
            right: 30px;
            transform: none;
        }
    }

    &--listing-page {
        .date-block--Market {
            margin-top: -48px;
            @include breakpoint(md) {
                margin-top: 0;
            }
        }
        .info-blocks-header__shadow-block--content {
            display: none;
            @include breakpoint(md) {
                display: block;
            }

            @include theme(br) {
                display: none;
            }
        }
        .info-blocks-header__shadow-block--date {
            display: block;
            left: auto;
            right: -$header_inset;
            @include breakpoint(md) {
                display: none;
            }
        }
    }
}
