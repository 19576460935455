.info-flat-header {
    position: relative;
    margin-bottom: 56px;

    &__container {
        @include theme(br) {
            @include breakpoint(xs, down) {
                overflow: hidden;
            }
        }
    }

    &__content-container {
        position: relative;
        background-color: var(--colors_highlight);

        @include theme(br) {
            background-color: var(--colors_rust);

            &:before {
                content: "";
                position: absolute;
                z-index: 1;
                right: 0;
                display: block;
                width: 55%;
                max-width: 800px;
                height: 90%;
                background-image: url("../assets/media/barangaroo-lines-sandstone.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right bottom;

                @include breakpoint(sm, down) {
                    bottom: 0;
                    height: 100%;
                    width: 60%;
                    background-size: contain;
                }

                @include breakpoint(xs, down) {
                    width: 100%;
                    max-height: 90px;

                    margin-bottom: -10px;
                    right: -100px;
                }
            }
        }

        @include theme(wb) {
            &:after {
                position: absolute;
                bottom: 0;
                content: "";
                height: 144px;
                width: 144px;
                background-image: url("../assets/media/texture-event.png");
                @include breakpoint(xs, down) {
                    display: none;
                }
            }
            &:before {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                right: 0;
                display: block;
                height: 182px;
                background-image: url("../assets/media/texture-event-card.svg");
                background-repeat: repeat-x;
                background-position: right top;
                background-size: contain;
                @include breakpoint(sm, down) {
                    display: none;
                }
            }
        }

        @include breakpoint(xs, down) {
            z-index: 5;
        }
    }

    &:not(.info-flat-header--has-background-image) &__content-container {
        @include theme(dh) {
            @include facet("dark", 0.4, 100%, auto);

            @include breakpoint($breakpoint_desktop_nav) {
                @include facet("dark", 0.4, auto, 100%);
            }
        }
    }

    &--has-background-image {
        .info-flat-header {
            &__content-container {
                background: none;
                min-height: 210px;
                @include breakpoint($breakpoint_desktop_nav) {
                    min-height: 0;
                }

                @include breakpoint(lg) {
                    min-height: calc(700px - #{50px});
                }

                @include breakpoint(xl) {
                    min-height: calc(850px - #{50px});
                }
            }
        }
        .info-card__header-image-wrapper,
        .info-flat-header__gallery-link {
            display: none;
        }
    }

    &__background-image {
        position: absolute;
        z-index: -1;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
    }

    &__description-container {
        position: relative;
        z-index: 1;
    }

    &__col-card {
        position: relative;
        order: 1;
        flex-basis: 100%;
        padding: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }

    &__col-description {
        order: 2;
        flex-basis: 100%;
        padding: 0 $layout_page_padding;
    }

    &__col-copy-block {
        margin-top: 60px;
        margin-bottom: 60px;
        padding-left: 35px;
        padding-right: 35px;
    }

    .container-fluid {
        padding-left: 0;
    }

    .row {
        margin-right: 0;
    }

    &__button {
        width: 100%;
        display: inline-block;
        letter-spacing: 3.4px;
        text-decoration: none;
        text-transform: uppercase;
        border-radius: 16px;
        font: 700 #{rem(12px)}/#{rem(36px)} var(--font_family_main);
        height: 32px;
        border: none;
        outline: none;
        background-color: $colors_grey_light;
        margin-top: 35px;
        margin-bottom: 35px;

        @include theme(dh) {
            font-family: var(--font_family_heading_medium);
            border-radius: 3px;
            color: rgba(#1e1e1e, 0.65);
            letter-spacing: var(--font_letterspace_med_wide);
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
        }
    }

    .info-card__info--divider {
        display: none;
    }

    &__gallery-link.cta {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: transparentize($colors_body_black, 0.5);
        border: none;
        z-index: 1;

        &:hover {
            background-color: transparentize(
                $colors_body_black,
                0.5
            ) !important;
            color: var(--colors_highlight);
        }

        i {
            font-size: 1.2em;
            margin-left: 0;
            margin-right: 5px;
        }
    }

    &__eyebrow {
        color: $colors_grey_dark;
        opacity: 0.65;
        font-weight: 700;
        font-size: rem(10px);
        line-height: rem(12px);
        margin-top: 0;
        margin-bottom: 10px;

        @include theme(dh) {
            color: white;
            opacity: 1;
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
            font-size: rem(14px);
            line-height: rem(16px);
            color: #000;
            font-family: var(--font_family_heading_semibold);
            opacity: 1;
            margin-bottom: 15px;

            @include breakpoint(xs, down) {
                font-size: rem(12px);
                line-height: rem(14px);
            }
        }

        @include theme(wb) {
            color: black;
            opacity: 1;
        }

        &.eyebrow--expired {
            display: inline-block;
            padding: 9px 15px;
            opacity: 1;
            background-color: $colors_red_dark;
            color: white;

            @include theme(dh) {
                background-color: $colors_body_black;
            }

            @include theme(br) {
                background-color: black;
            }

            @include theme(wb) {
                background-color: black;
            }
        }
    }

    &__title {
        font-family: var(--font_family_heading);
        color: $colors_grey_dark;
        font-size: rem(28px);
        line-height: rem(42px);
        margin-top: 0;
        margin-bottom: 22px;

        @include theme(dh) {
            color: white;
        }

        @include theme(br) {
            text-transform: none;
            color: black;
            letter-spacing: -0.02em;
        }
    }

    &__byline {
        font-size: rem(17px);
        vertical-align: middle;
        font-family: var(--font_family_heading);
        color: $colors_grey_dark;
        margin-top: 0;
        margin-bottom: 30px;

        @include theme(dh) {
            color: white;
            font-family: var(--font_family_heading_regular);
            font-size: rem(20px);
            line-height: rem(30px);
            letter-spacing: var(--font_letterspace_heading_regular);
        }

        @include theme(br) {
            color: black;
            font-family: var(--font_family_heading_regular);
            letter-spacing: -0.02em;
        }

        a {
            color: $colors_grey_dark;
            text-decoration: none;
            box-shadow: inset 0 -0.15em 0 transparentize($colors_grey_dark, 0.7);
            @extend .underline;
            a:hover &,
            &:hover {
                box-shadow: inset 0 -2em 0 transparentize($colors_grey_dark, 0.7);
            }

            @include theme(dh) {
                color: white;
                box-shadow: inset 0 -0.15em 0 rgba(white, 0.7);

                a:hover &,
                &:hover {
                    color: $colors_grey_dark;
                    box-shadow: inset 0 -2em 0 rgba(white, 0.7);
                }
            }

            @include theme(br) {
                font-family: var(--font_family_heading_regular);
                box-shadow: inset 0 -0.15em 0 #1e1e1e;
                color: black;
            }

            @include theme(wb) {
                box-shadow: inset 0 -0.125em 0 rgba(black, 0.7);

                a:hover &,
                &:hover {
                    color: $colors_grey_dark;
                    box-shadow: inset 0 -8px 0 rgba(black, 0.5);
                }
            }
        }
    }

    &__description {
        font-size: rem(16px);
        line-height: rem(26px);
        color: $colors_grey_dark;
        margin: 42px 0 0;
        @include theme(wb) {
                letter-spacing: -0.36px;
            }
    }

    &__short-description {
        font-size: rem(18px);
        line-height: rem(30px);
        font-family: var(--font_family_heading);
        color: $colors_grey_med;
        margin-top: 0;
        margin-bottom: 0;

        @include theme(dh) {
            color: white;
            font-family: var(--font_family_heading_regular);
            font-size: rem(20px);
            line-height: rem(30px);
            letter-spacing: var(--font_letterspace_heading_regular);
        }

        @include theme(br) {
            font-family: var(--font_family_heading_regular);
            color: black;
            letter-spacing: -0.02em;

            @include breakpoint(xs, down) {
                margin-bottom: 20px;
            }
        }

        @include theme(wb) {
            font-family: var(--font_family_main);
            color: black;
        }
    }

    &__col-short-description {
        @include theme(dh) {
            color: var(--colors_heading_black);
        }
    }

    &__cta {
        position: relative;
        z-index: 2;
        margin-top: 20px;

        @include breakpoint(sm) {
            margin-top: 36px;
        }

        @include theme(br) {
            @include breakpoint(xs, down) {
                .cta {
                    width: 100%;
                }
            }
        }
    }

    &__terms-conditions {
        padding-top: 20px;
    }

    &__terms-conditions,
    &__terms-conditions p {
        font-size: rem(13px);
        line-height: rem(20px);

        @include theme(dh) {
            font-size: rem(13px);
            line-height: rem(22px);
        }
    }

    @include breakpoint(sm) {
        $cardWidthSm: 335px;

        .container-fluid {
            padding-left: $layout_page_padding +
                $layout_nav_desktop_width_compact;
        }

        .row.no-wrap {
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }

        &__content-container {
            border: $main_hero_inset solid white;
            border-bottom: none;
            &:after {
                right: $cardWidthSm;
            }
            &::before {
                display: none;
            }
        }

        &__col-card {
            flex-basis: $cardWidthSm;
            margin-top: -200px;
            margin-right: $main_hero_inset;
            order: 2;
        }

        &__col-copy-block {
            flex-basis: calc(100% - (#{$cardWidthSm + $main_hero_inset}));
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0 100px;
            margin-top: 125px + $main_hero_inset;
            margin-bottom: 200px;
        }

        &__col-description {
            flex-basis: calc(100% - (#{$cardWidthSm + $main_hero_inset}));
            padding: 0;
            padding-right: 10%;
            order: 1;
        }

        &__gallery-link.cta {
            bottom: auto;
            transform: none;
            top: 30px;
            right: 30px;
        }
    }

    @include breakpoint(sm, only) {
        &__col-copy-block {
            padding-left: 80px;
            padding-right: 25px;
            margin-top: 70px + $main_hero_inset;
            margin-bottom: 70px;
        }
        &__content-container {
            &:after {
                display: none;
            }
            &::before {
                display: none;
            }
        }
        &__col-description {
            // padding-left: 80px;
            padding-left: $layout_page_padding;
            padding-right: 25px;
        }
        .container-fluid {
            padding-left: 0;
        }

        .info-card__inner {
            padding: 0px 20px;
        }

        .info-card:not(.info-card--has-header) .info-card__wrapper {
            padding-top: 40px;
        }

        &__eyebrow {
            font-size: rem(12px);
            line-height: rem(15px);
            margin-top: 0;
            margin-bottom: 10px;
        }

        &__title {
            font-size: rem(28px);
            line-height: rem(42px);
            margin-top: 0;
            margin-bottom: 20px;
        }

        &__byline {
            font-size: rem(17px);
            vertical-align: middle;
            margin-top: 0;
            margin-bottom: 20px;
        }

        &__short-description {
            font-size: rem(18px);
            line-height: rem(30px);
            margin-top: 0;
            margin-bottom: 0;
        }

        &__description {
            font-size: rem(18px);
            line-height: rem(30px);
            padding-top: 40px;
        }

        &__terms-conditions,
        &__terms-conditions p {
            font-size: rem(18px);
            line-height: rem(22px);
        }
    }

    @include breakpoint(md) {
        $cardWidthLg: 335px;
        margin-bottom: 170px;

        &__col-card {
            flex-basis: $cardWidthLg;
        }

        &__col-copy-block {
            flex-basis: calc(100% - (#{$cardWidthLg + $main_hero_inset}));
            padding-left: 100px;
            margin-top: 115px;
            margin-bottom: 100px;
        }

        &__col-description {
            flex-basis: calc(100% - (#{$cardWidthLg + $main_hero_inset}));
            padding-right: 40px;
        }

        &__content-container {
            &:after {
                right: $cardWidthLg;
            }
            &:before {
                width: 300px;
            }
        }
        .info-card__inner {
            padding: 0 20px;
        }

        &__eyebrow {
            font-size: rem(10px);
            line-height: rem(12px);
            margin-top: 0;
            margin-bottom: 10px;
        }

        &__title {
            font-size: rem(28px);
            line-height: rem(42px);
            margin-top: 0;
            margin-bottom: 35px;
        }

        &__byline {
            font-size: rem(17px);
            vertical-align: middle;
            margin-top: 0;
            margin-bottom: 30px;
        }

        &__short-description {
            font-size: rem(18px);
            line-height: rem(30px);
            margin-top: 0;
            margin-bottom: 0;
        }

        &__col-short-description {
            margin-bottom: 40px;
        }

        &__description {
            font-size: rem(18px);
            line-height: rem(30px);
            padding-top: 40px;
        }

        &__terms-conditions,
        &__terms-conditions p {
            font-size: rem(18px);
            line-height: rem(22px);
        }
    }

    @include breakpoint(lg) {
        $cardWidthLg: 385px;

        &__col-card {
            flex-basis: $cardWidthLg;
        }

        &__col-copy-block {
            flex-basis: calc(100% - (#{$cardWidthLg + $main_hero_inset}));
            padding-left: 100px;
            margin-top: 150px;
            margin-bottom: 200px;
        }

        &__col-description {
            flex-basis: calc(100% - (#{$cardWidthLg + $main_hero_inset}));
        }
        &__content-container {
            &:after {
                right: $cardWidthLg;
            }
            &:before {
                width: 340px;
            }
        }

        .info-card__inner {
            padding: 0 30px;
        }

        &__eyebrow {
            font-size: rem(14px);
            line-height: rem(17px);
            margin-bottom: 30px;
        }

        &__title {
            font-size: rem(42px);
            line-height: rem(56px);
            margin-bottom: 20px;
        }

        &__byline {
            font-size: rem(24px);
            vertical-align: middle;
            margin-bottom: 40px;

            @include theme(dh) {
                font-size: rem(24px);
                line-height: rem(36px);
            }

            @include theme(wb) {
                font-family: var(--font_family_main);
                font-size: rem(20px);
                line-height: rem(32px);
            }
        }

        &__short-description {
            font-size: rem(22px);
            line-height: rem(34px);

            @include theme(dh) {
                font-size: rem(24px);
                line-height: rem(36px);
            }
        }

        &__col-short-description {
            font-size: rem(22px);
            line-height: rem(34px);

            @include theme(dh) {
                font-size: rem(24px);
                line-height: rem(36px);
            }
        }

        &__description {
            font-size: rem(18px);
            line-height: rem(30px);
            padding-top: 50px;
        }

        &__terms-conditions,
        &__terms-conditions p {
            font-size: rem(18px);
            line-height: rem(22px);
        }
    }
    @include breakpoint(xl) {
        &__col-copy-block {
            padding-left: 130px;
        }
    }
}
