.youtube-player {
    @include aspect-ratio(16, 9);
    position: relative;
    background: black;

    &__iframe {
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &__poster {
        display: flex !important;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-color: grey;
        cursor: pointer;
        @extend .icon;
        @extend .icon-play;
        @include insetOverlay(0.5, black);

        .youtube-player.has-started & {
            @include fadeHide;
            pointer-events: none;
        }

        &:before {
            //the play icon
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            font-size: 12px;
            border-radius: 100%;
            background: var(--colors_highlight);
            text-indent: 0.1em;

            @include breakpoint(sm) {
                width: 110px;
                height: 110px;
                font-size: 32px;

                .image-row & {
                    width: 78px;
                    height: 78px;
                    font-size: 24px;
                    opacity: 0.8;
                    @include theme(wb) {
                        background: black;
                        color: white;
                    }
                }
            }

            @include theme(dh) {
                color: white;
            }

            @include theme(br) {
                color: white;
            }

            @include theme(wb) {
                background: black;
                color: white;
            }
        }

        &:hover {
            font-size: 14px;
            &:before {
                @include breakpoint(sm) {
                    font-size: 35px;

                    .image-row & {
                        font-size: 28px;
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .youtube-player__iframe {
        transition: opacity 1000ms;
        transition-delay: 1000ms;
        opacity: 0;
    }

    &.has-started {
        .youtube-player__iframe {
            opacity: 1;
        }
    }

    .lightbox & {
        max-height: calc(100vh - 170px);

        @include theme(br) {
            z-index: 3;
        }
    }
}
