.google360 {
    margin: 50px 0;
    @include breakpoint($breakpoint_desktop_nav) {
        margin: 80px 0;
    }

    &__iframe-wrapper {
        position: relative;
        @include aspect-ratio(3, 2);
    }
    &__iframe {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
