/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints-up
/// @require $breakpoints-down

$breakpoints-up: (
    "xs": (
        min-width: 0px
    ),
    "sm": (
        min-width: $breakpoint_sm
    ),
    "md": (
        min-width: $breakpoint_md
    ),
    "lg": (
        min-width: $breakpoint_lg
    ),
    "xl": (
        min-width: $breakpoint_xl
    )
) !default;

$breakpoints-down: (
    "xs": (
        max-width: #{$breakpoint_sm - 1em/16}
    ),
    "sm": (
        max-width: #{$breakpoint_md - 1em/16}
    ),
    "md": (
        max-width: #{$breakpoint_lg - 1em/16}
    ),
    "lg": (
        max-width: #{$breakpoint_xl - 1em/16}
    ),
    "xl": (
        max-width: 9000px
    )
) !default;

$breakpoints-only: (
    "xs": "(max-width: #{$breakpoint_sm - 1em/16})",
    "sm": "(min-width: #{$breakpoint_sm}) and (max-width: #{$breakpoint_md - 1/16})",
    "md": "(min-width: #{$breakpoint_md}) and (max-width: #{$breakpoint_lg - 1/16})",
    "lg": "(min-width: #{$breakpoint_lg}) and (max-width: #{$breakpoint_xl - 1/16})",
    "xl": "(min-width: #{$breakpoint_xl})"
) !default;

@mixin breakpoint($breakpoint, $direction: up) {
    // If the key exists in the map
    @if map-has-key($breakpoints-up, $breakpoint) and $direction == up {
        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints-up, $breakpoint))} {
            @content;
        }
    }

    @else if map-has-key($breakpoints-down, $breakpoint) and $direction == down {
        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints-down, $breakpoint))} {
            @content;
        }
    }

    @else if map-has-key($breakpoints-only, $breakpoint) and $direction == only {
        // Prints a media query based on the value
        @media #{map-get($breakpoints-only, $breakpoint)} {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints-up)}.";
    }
}
