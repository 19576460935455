.simple-header {
    position: relative;
    overflow: hidden;

    @include theme(br) {
        padding-bottom: 45px;
        overflow: initial;

        &:before {
            content: "";
            z-index: 1;
            background-image: url("../assets/media/barangaroo-lines-rust-right.png");
            display: block;
            bottom: 50px;
            right: 0;
            left: auto;
            width: 26%;
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right bottom;

            @include breakpoint(sm, down) {
                bottom: 0;
                width: 60%;
                height: 100px !important;
            }

            @include breakpoint(xs, down) {
                bottom: 14px;
                width: 80%;
            }
        }
    }

    &__background {
        position: absolute;
        z-index: -1;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;

        .content-page .simple-header--dark & {
            @include theme(dh) {
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 150px;
                    z-index: 1;
                    @include linear-gradient(
                        to bottom,
                        rgba($content_page_bg, 0) 0%,
                        rgba($content_page_bg, 1) 100%
                    );
                }
            }
        }

        @include theme(dh) {
            @include facet("dark", 0.2, 100%);

            @include breakpoint($breakpoint_desktop_nav) {
                @include facet("dark", 0.2, auto, 100%);
            }
        }

        &--has-image {
            @include insetOverlay(0.6, black);
            @include breakpoint($breakpoint_desktop_nav) {
                @include insetOverlay(0.45, black);
                &:after {
                    content: "";
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    @include linear-gradient(
                        to right top,
                        rgba(black, 0.67) 0%,
                        rgba(black, 0) 70%
                    );
                }
            }
        }

        @include breakpoint($breakpoint_desktop_nav) {
            top: 30px;
            left: 30px;
            width: calc(100% - 60px);
            height: calc(100% - 60px);

            .content-page .simple-header--dark & {
                height: 100%;
            }
        }
    }

    &--Content #{&}__background {
        background-color: $colors_bg_beige;

        @include theme(dh) {
            background-color: var(--colors_green_light);
        }

        @include theme(br) {
            background-color: var(--colors_sandstone);
        }
    }

    &--Venue #{&}__background {
        background-color: var(--colors_highlight);

        @include theme(dh) {
            background-color: var(--colors_green_light);
        }

        @include theme(br) {
            background-color: var(--colors_sandstone);
        }
    }

    &--FilmingApplication #{&}__background {
        background-color: var(--colors_highlight);

        @include theme(dh) {
            background-color: var(--colors_green_light);
        }

        @include theme(br) {
            background-color: var(--colors_sandstone);
        }
    }

    &--Subscribe {
        @include theme(br) {
            margin-bottom: 150px;

            @include breakpoint(sm, down) {
                margin-bottom: 60px;
            }

            &:before {
                display: none;
            }
        }

        @include theme(wb) {
            .simple-header__eyebrow,
            .simple-header__heading {
                color: white;
            }
        }
    }

    &--Subscribe #{&}__background {
        background-color: var(--colors_highlight);

        @include theme(dh) {
            background-color: var(--colors_green_light);
        }

        @include theme(br) {
            background-color: var(--colors_pale_leaf);
        }

        @include theme(wb) {
            background-color: black;
        }
    }

    &--Editorial {
        @include theme(br) {
            &:before {
                @include breakpoint(sm, down) {
                    bottom: -20px;
                }

                @include breakpoint(xs, down) {
                    bottom: -11px;
                }
            }
        }
    }

    &--Editorial,
    &--Content,
    &--CommunityNoticePost {
        @include theme(wb) {
            .simple-header__eyebrow {
                color: black;
            }

            .simple-header__heading {
                font-size: rem(52px);
                line-height: rem(62px);
                letter-spacing: -1.04px;
                color: black;

                @include breakpoint(sm, down) {
                    font-size: rem(28px);
                    line-height: rem(30px);
                    letter-spacing: -0.28px;
                }
            }

            .simple-header__short-description {
                font-family: var(--font_family_main);
                font-size: rem(22px);
                line-height: rem(34px);
                letter-spacing: -0.22px;
                color: black;

                @include breakpoint(sm, down) {
                    font-size: rem(20px);
                    line-height: rem(30px);
                    letter-spacing: -0.4px;
                }
            }
        }
    }

    &__image {
        img {
            width: 100%;
        }

        margin-top: -50px;
        margin-bottom: 40px;
        position: relative;

        .content-page .simple-header--dark & {
            margin-bottom: 0;
        }

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            background-color: white;
            display: block;
            bottom: -40px;
            left: -20px;
            width: 100vw;
            height: calc(100% - 60px);
        }

        @include theme(br) {
            &:before {
                background-color: #f8f8f8;
            }
        }

        @include theme(wb) {
            padding-bottom: 30px;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                left: auto;
                right: 0;
                bottom: 0;
                display: block;
                width: 200px;
                height: 200px;
                background-image: url("../assets/media/texture-content_wb.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }

            img {
                width: calc(100% - 30px);
            }

            @include breakpoint (sm, down) {
                img {
                    width: calc(100% - 20px);
                }

                &:before {
                    bottom: 7px;
                }
            }
        }
    }

    &--Subscribe #{&}__image {
        display: none;
        @include breakpoint(md) {
            display: block;
        }
    }

    &__content {
        margin-top: 42px;
        margin-bottom: 100px;
        padding-right: 30px;

        .content-page .simple-header--dark & {
            margin-bottom: 80px;
        }
    }

    &__eyebrow {
        font-size: rem(10px);
        line-height: rem(12px);
        color: $colors_grey_med;
        padding-bottom: 15px;

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
            color: black;
            padding-bottom: 15px;

            @include breakpoint(xs, down) {
                font-size: rem(12px);
                line-height: rem(14px);
            }
        }

        @include theme(wb) {
            font-size: rem(12px);
            letter-spacing: 0.64px;
            color: black;
        }
    }

    &__heading {
        font-size: rem(28px);
        line-height: rem(36px);
        margin: 0;
        padding-bottom: 25px;

        @include theme(dh) {
            font-size: rem(28px);
            line-height: rem(34px);
        }

        @include theme(br) {
            text-transform: none;
            letter-spacing: -0.02em;
        }

        @include theme(wb) {
            font-size: rem(34px);
            line-height: rem(36px);
            letter-spacing: -0.34px;
            color: black;
        }
    }

    &__short-description {
        font-family: var(--font_family_heading);
        font-size: rem(20px);
        line-height: rem(30px);
        color: $colors_grey_med;

        @include theme(dh) {
            font-family: var(--font_family_main);
            color: var(--colors_body_black);
        }

        @include theme(br) {
            font-family: var(--font_family_heading_regular);
            color: black;
        }
    }

    &__points {
        margin: 0;
        padding: 0;
    }

    &__point {
        list-style: none;
        padding-left: 45px;
        padding-bottom: 20px;
        position: relative;
        font-size: rem(20px);
        line-height: rem(30px);
        color: $colors_grey_med;
        font-family: var(--font_family_heading_regular);
        letter-spacing: var(--font_letterspace_heading_regular);

        &:before {
            @extend .icon;
            @extend .icon-benefit-bullet;
            position: absolute;
            top: 3px;
            left: 0;
        }

        @include theme(br) {
            color: black;
        }

        @include theme(wb) {
            color: white;
        }
    }

    @include breakpoint(md) {
        &:before {
            top: 30px;
            left: 30px;
            width: calc(100vw - 60px);
            height: calc(100% - 30px);
        }

        &__image {
            margin-top: 110px;
            margin-bottom: 105px;

            &:before {
                display: none;
            }

            .content-page .simple-header--dark & {
                margin-top: 110px;
                margin-bottom: 0;
            }
        }
        &__content {
            margin-top: 120px;
            margin-bottom: 120px;
            padding-left: 100px;
            padding-right: 70px;

            .content-page .simple-header--dark & {
                margin-top: 120px;
                margin-bottom: 0;
            }
        }

        &__eyebrow {
            font-size: rem(14px);
            line-height: rem(17px);
            padding-bottom: 25px;
        }

        &__heading {
            font-size: rem(42px);
            line-height: rem(52px);
            padding-bottom: 30px;

            @include theme(dh) {
                font-size: rem(48px);
                line-height: rem(52px);
            }

            @include theme(wb) {
                font-size: rem(48px);
                line-height: rem(50px);
                letter-spacing: -0.48px;
            }
        }

        &__short-description {
            font-size: rem(22px);
            line-height: rem(34px);
        }
        &__point {
            font-size: rem(22px);
            line-height: rem(34px);
        }
    }
    @include breakpoint(lg) {
        &__content {
            padding-left: 100px;
        }
    }

    @include breakpoint(xl) {
        &__content {
            padding-left: 130px;
            padding-right: 90px;
            margin-top: 140px;
            margin-bottom: 140px;
        }
    }

    &--light * {
        color: white !important;

        @include theme(br) {
            color: black;

            .simple-header__background--has-image {
                color: white !important;
            }
        }
    }
}
