$layout_nav_zindex: 100;
$layout_nav_desktop_width: 240px;
$layout_nav_desktop_width_smaller: 210px;
$layout_nav_desktop_width_compact: 110px;

$layout_container_maxwidth: 1400px;
$layout_container_maxwidth_large: 1600px;

$layout_page_padding: 20px;
$main_hero_inset: 25px;

$mobile_pane_width: 80%;
